import {
  faCheckCircle,
  faExclamationCircle,
  faHourglassHalf
} from '@fortawesome/pro-solid-svg-icons'
import { useSearchQuery } from 'Core/Hooks/useSearchQuery'
import { getResourcePolicyTags } from 'features/resources'
import { createDataSelectorHook } from 'infra/redux'
import _ from 'lodash'
import { Button, Label, ResponsiveTable, SelectDropDown, TabGroup, Typography } from 'procyon-ui'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { Route } from 'react-router-dom/cjs/react-router-dom.min'
import { getAccountImage } from 'Utils/Helpers'
import { SearchInput } from 'V2Components'
import CreateProxyModal from './Components/CreateModal'
import DeleteProxyModal from './Components/DeleteModal'

const useSlices = createDataSelectorHook([
  'projects',
  'medusaNodes',
  'accountList',
  'proxyList',
  'medusaNodeStates'
])

const Gateways = () => {
  const [selectedTag, setSelectedTag] = useState('')
  const [searchKey, setSearchKey] = useState('')

  const history = useHistory()

  const { slices } = useSlices()

  const { applySearchQuery } = useSearchQuery({
    queryKey: searchKey,
    defaultQueryFunction: (row, queryKey) => {
      //row data
      if (!queryKey) return true
      const {
        name: { title, subtitle },
        version,
        type
      } = row

      const all = `${title}${subtitle || ''}${version}${type}`.toLowerCase()
      return all.includes(queryKey)
    }
  })

  const [deleteModalData, setDeleteModalData] = useState(false)

  const isDeleteModalOpen = Boolean(Object.keys(deleteModalData).length > 0)
  const handleDeleteModalClose = () => {
    setDeleteModalData(false)
  }

  const getTagsDropdownOptions = () => {
    const options = new Set()
    slices.medusaNodes.forEach((s) => {
      const tags = getResourcePolicyTags(s)
      if (tags.hasOwnProperty('proxygrp')) {
        const { proxygrp } = tags
        // Split the comma-separated values
        const values = proxygrp.split(',')
        // Add unique values to the set
        values.forEach((value) => {
          options.add(value.trim())
        })
      }
    })
    return [...options].map((value) => ({ label: value, value: value }))
  }

  const handleCreateModalClose = () => {
    history.push('/admin/gateways')
  }

  const getTableData = () => {
    const nodesTableData = []
    slices.medusaNodes.forEach((node) => {
      const nodeState = _.find(slices.medusaNodeStates, {
        ObjectMeta: { Name: node.ObjectMeta.Name }
      })

      if (selectedTag) {
        const { proxygrp } = getResourcePolicyTags(node)
        const splitTags = proxygrp?.split(',')
        const tagMatch = splitTags?.some((tag) => tag.trim() === selectedTag.trim())
        if (tagMatch === '-') return
        if (!tagMatch) return
      }
      const { ClusterID, ProxyURL, VersionString } = node
      const version = VersionString && VersionString.split('\n')[0]

      const isMetricDataAvailable =
        nodeState && !_.isEmpty(nodeState.Spec.StatusMap) && !_.isEmpty(nodeState.Spec.Metrics)

      return nodesTableData.push({
        kind: node.ObjectMeta.Kind,
        type: node.NodeType,
        accountData: false,
        name: { title: ClusterID, subtitle: ProxyURL },
        version,
        status: 'success',
        ID: node.ObjectMeta.ID,
        url: `/admin/gateways/${node.ObjectMeta.Name}`,
        delete: {
          onClick() {}
        },
        telemetry: {
          showTelemetryButton: isMetricDataAvailable,
          onClick() {
            history.push(`/admin/gateways/${node.ObjectMeta.Name}`)
          }
        }
      })
    })
    if (selectedTag) return nodesTableData
    return nodesTableData
  }

  const getRemoteProxies = () => {
    const proxyTableData = slices.proxyList.map((proxy) => {
      /** Get the account associated with this proxy */
      const accountObject = slices.accountList.find(
        (e) => e.ObjectMeta.ID === proxy.Spec.Account.RefID
      )
      let accountData = {}
      if (accountObject) {
        accountData = {
          title: accountObject.ObjectMeta.Name,
          subTitle: accountObject.Spec.AccountID,
          image: getAccountImage(accountObject.Type, true)
        }
      }
      /**
       * Status logic
       * 1. Consider status as pending if Status.instanceName is not Present
       * 2. Consider status as error if Status.Status.Error is not empty
       **/
      const { ProxyKeyPair, ProxyVpcID, Region } = proxy.Spec
      const {
        Status: { Error },
        InstanceName
      } = proxy.Status
      const status = Error ? 'error' : InstanceName ? 'success' : 'pending'
      return {
        kind: proxy.ObjectMeta.Kind,
        type: proxy.ObjectMeta.Kind,
        accountData,
        name: { title: proxy.ObjectMeta.Name },
        ProxyKeyPair,
        ProxyVpcID,
        Region,
        status,
        ID: proxy.ObjectMeta.ID,
        delete: {
          onClick() {
            setDeleteModalData(proxy)
          }
        }
      }
    })

    return proxyTableData
  }

  const handleAdd = () => {
    history.push('/admin/gateways/create')
  }

  return (
    <div>
      <div className='flex items-center justify-between'>
        <Typography variant='h2'>Gateways and Proxies</Typography>
        <Button variant='primary' onClick={handleAdd}>
          Add New
        </Button>
      </div>

      <div className='mt-6'>
        <TabGroup
          tabs={[
            {
              label: 'Default',
              tabContent: (
                <>
                  <div className='flex items-center justify-start gap-2 mb-3'>
                    <SearchInput searchKey={searchKey} onChange={setSearchKey} />
                    <SelectDropDown
                      showHelpText={false}
                      className='ml-6'
                      width='307px'
                      onChange={(e) => setSelectedTag(e.target.value)}
                      menuItems={[
                        {
                          label: 'All',
                          value: ''
                        },
                        {
                          label: <em>Without Tags</em>,
                          value: '-'
                        },
                        ...getTagsDropdownOptions()
                      ]}
                      value={selectedTag}
                    />
                  </div>
                  <ResponsiveTable
                    scrollX='1200px'
                    columns={rColumns()}
                    data={applySearchQuery(getTableData())}
                  />
                </>
              )
            },
            {
              label: 'Remote Proxies',
              tabContent: (
                <>
                  <div className='flex items-center justify-start gap-2 mb-3'>
                    <SearchInput searchKey={searchKey} onChange={setSearchKey} />
                    <SelectDropDown
                      showHelpText={false}
                      className='ml-6'
                      width='307px'
                      onChange={(e) => setSelectedTag(e.target.value)}
                      menuItems={[
                        {
                          label: 'All',
                          value: ''
                        },
                        {
                          label: <em>Without Tags</em>,
                          value: '-'
                        },
                        ...getTagsDropdownOptions()
                      ]}
                      value={selectedTag}
                    />
                  </div>
                  <ResponsiveTable
                    scrollX='1200px'
                    onRow={(data) => {
                      return {
                        onClick() {}
                      }
                    }}
                    columns={rColumns(true)}
                    data={applySearchQuery(getRemoteProxies())}
                  />
                </>
              )
            }
          ]}
        />
      </div>
      {/* Delete Modal */}
      <DeleteProxyModal
        data={deleteModalData}
        open={isDeleteModalOpen}
        handleClose={handleDeleteModalClose}
      />
      <Route exact path={'/admin/gateways/create'}>
        <CreateProxyModal handleClose={handleCreateModalClose} open />
      </Route>
    </div>
  )
}

/**
 @returns {import('procyon-ui').ResponsiveTableProps['columns']}
 */
const rColumns = (includeDeleteRow) => {
  const columns = [
    {
      dataIndex: 'name',
      title: 'Name',
      width: 600,
      align: 'left',
      sorter: (v1, v2) => v1.name.title.toLowerCase().localeCompare(v2.name.title.toLowerCase()),
      render(value) {
        return (
          <Typography variant='body-regular' className='py-1'>
            <span className='!text-blue-500'>{value.title}</span> {value.subtitle && '/'}{' '}
            {value.subtitle}
          </Typography>
        )
      }
    },
    {
      dataIndex: 'version',
      title: 'Version',
      width: 200,
      align: 'left',
      sorter: (v1, v2) => v1.version.toLowerCase().localeCompare(v2.version.toLowerCase())
    },
    {
      dataIndex: 'type',
      title: 'Type',
      width: 200,
      align: 'left',
      sorter: (v1, v2) => v1.type.toLowerCase().localeCompare(v2.type.toLowerCase())
    },
    {
      dataIndex: 'status',
      title: 'Status',
      width: 100,
      align: 'center',
      sorter: (v1, v2) => v1.status.toLowerCase().localeCompare(v2.status.toLowerCase()),
      render(value) {
        if (value === 'success')
          return (
            <Label
              style={{ width: '100px' }}
              variant='success'
              iconButton={faCheckCircle}
              text='Success'
            />
          )
        if (value === 'pending')
          return (
            <Label
              style={{ width: '100px' }}
              variant='gray'
              iconButton={faHourglassHalf}
              text='Pending'
            />
          )
        return (
          <Label
            style={{ width: '100px' }}
            variant='danger'
            iconButton={faExclamationCircle}
            text='Error'
          />
        )
      }
    },
    {
      dataIndex: 'telemetry',
      title: '',
      width: 180,
      fixed: 'right',
      align: 'center',
      render(value) {
        if (!value?.showTelemetryButton) return null
        return (
          <Button className='mx-auto' variant='grayBlue' onClick={value.onClick}>
            View Telemetry Data
          </Button>
        )
      }
    }
  ]

  if (includeDeleteRow) {
    columns.push({
      dataIndex: 'delete',
      title: '',
      width: 80,
      align: 'center',
      sorter() {},
      render(value) {
        return (
          <Button className='mx-auto' onClick={value.onClick} variant='grayRed'>
            Delete
          </Button>
        )
      }
    })
  }
  //@ts-ignore
  return columns
}

export default Gateways
