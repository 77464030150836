import React from 'react'
import { Route } from 'react-router'
import Gateways from '../views/Gateways'
import { GatewaysDetails } from '../views/GatewaysDetails'
import { Switch } from 'react-router-dom/cjs/react-router-dom.min'

const GatewayRoutes = () => {
  return (
    <Switch>
      <Route exact path={['/admin/gateways', '/admin/gateways/create']}>
        <Gateways />
      </Route>
      <Route exact path={['/admin/gateways/:name']}>
        <GatewaysDetails />
      </Route>
    </Switch>
  )
}

export { GatewayRoutes }
