import { Box, makeStyles, Typography } from '@material-ui/core'
import classNames from 'classnames'
import StyledBox from 'Components/StyledBox'
import React from 'react'
const useStyle = makeStyles((theme) => ({
  image: {
    width: theme.spacing(1),
    marginRight: theme.spacing(0.375)
  },
  secondary: { marginLeft: theme.spacing(0.24) },
  primary: {
    whiteSpace: 'nowrap'
  }

}))
const OverviewLabel = ({ image, primary, secondary, className, ...rest }) => {
  const classes = useStyle()

  return (
    <Box display='inline-block' {...rest}>
      <StyledBox globalClassName='labelBox' className={classNames(className, classes.root)} display='flex' alignItems='center' width='auto'>
        {image && <img src={image} className={classes.image} />}
        <Box display='flex' alignItems='start'>
          {primary && <Typography className={classes.primary} variant='body2'>{primary}</Typography>}
          {secondary && <Typography className={primary ? classes.secondary : ''} color='textSecondary' variant='body2'>{secondary}</Typography>}
        </Box>
      </StyledBox>
    </Box>
  )
}

export default OverviewLabel
