import { createRsrcKey } from 'features/resources'
import { createContext, useContext, useRef, useState } from 'react'
import React from 'react'
import { getServerControllerGroupsMap, getServerControllerTags } from '../utils'
import { createDataSelectorHook, useReduxFetch } from 'infra/redux'
import _ from 'lodash'

/**
 * @type {{
 *  controllerName?: string,
 *  selectedAccountKeys?: string[],
 *  selectorType?: 'Accounts' | 'Labels' | 'CTags',
 *  label?: string[],
 *  ctag?: string,
 *  users?: any[],
 *  groups?: string[]
 *  groupsMap?: any
 *  sudoers?: string,
 *  disableAnonymousLogin?: boolean
 *  disableOSLogin?: boolean,
 *  isEditMode?:boolean
 * srvCtrl?: any
 * }}
 */
const INITIAL = {
  controllerName: '',
  selectedAccountKeys: [],
  selectorType: 'Accounts',
  label: ['appgrp'],
  ctag: '',
  users: [],
  groups: [],
  sudoers: '',
  groupsMap: {},
  disableAnonymousLogin: true,
  disableOSLogin: false,
  isEditMode: false,
  srvCtrl: null
}

const ServerControllerWizardContext = createContext({
  ...INITIAL,
  /**
   *
   * @param {{
   *  controllerName?: string,
   *  selectedAccountKeys?: string[],
   *  selectorType?: 'Accounts' | 'Labels' | 'CTags',
   *  label?: string[],
   *  ctag?: string,
   *  users?: any[],
   *  groups?: string[]
   *  groupsMap?: any
   *  sudoers?: string,
   * disableAnonymousLogin?:boolean
   * disableOSLogin?: boolean
   * }} config
   */
  updateConfig(config) {}
})
const useSlices = createDataSelectorHook(['userList'])

export const ServerControllerWizardProvider = ({ children, srvCtrl = null }) => {
  const [config, setConfig] = useState(INITIAL)

  const isConfigLoaded = useRef(false)

  const { slices } = useSlices()

  if (srvCtrl && !isConfigLoaded.current && slices.userList.length) {
    isConfigLoaded.current = true
    //@ts-ignore
    setConfig(getSrvCtrlConfig(srvCtrl, slices.userList))
    console.log('[ctrl](ss):', getSrvCtrlConfig(srvCtrl, slices.userList))
  }

  const updateConfig = (uptdConfig) => {
    setConfig((s) => ({ ...s, ...uptdConfig }))
  }

  return (
    <ServerControllerWizardContext.Provider
      //@ts-ignore
      value={{ ...config, updateConfig, isEditMode: !!srvCtrl, srvCtrl }}
    >
      {children}
    </ServerControllerWizardContext.Provider>
  )
}

const getSrvCtrlConfig = (srvCtl, userList = []) => {
  if (!srvCtl) return INITIAL
  const controllerName = srvCtl.ObjectMeta.Name
  const selectedAccountKeys = srvCtl.Spec.Accounts.ObjectRef.map(createRsrcKey)

  const tagsMap = getServerControllerTags(srvCtl)
  const ctag = tagsMap.ctags.join()

  const labels = tagsMap.labels.length > 0 ? [tagsMap.labels[0].split(':')[0]] : []

  tagsMap.labels.forEach((label) => {
    const [key, value] = label.split(':')

    const values = value.split(',')
    values.forEach((v) => labels.push(`${key}:${v}`))
  })

  const users = srvCtl.Spec.Users.Elems.reduce((prev, usrName) => {
    const usr = _.find(userList, { Spec: { ServerUserName: usrName } })
    if (!usr) return prev
    return [usr, ...prev]
  }, [])

  const groups = srvCtl.Spec.Groups.Elems
  const groupsMap = getServerControllerGroupsMap(srvCtl)

  const gMap = {}

  for (const grpName in groupsMap) {
    const userNames = groupsMap[grpName] || []
    userNames.forEach((usrName) => {
      const usr = _.find(userList, { Spec: { ServerUserName: usrName } })
      if (!usr) return
      gMap[grpName] = gMap[grpName]
        ? [...gMap[grpName], `${usr.ObjectMeta.Name}+${usrName}`]
        : [`${usr.ObjectMeta.Name}+${usrName}`]
    })
  }

  const sudoers = srvCtl.Spec.Sudoers.Elems.join('\n')
  const disableAnonymousLogin = srvCtl.Spec.DisableAnonymousLogin
  const disableOSLogin = srvCtl.Spec.DisableOsLogin

  let selectorType = ''

  if (selectedAccountKeys.length) selectorType = 'Accounts'
  else if (labels.length) selectorType = 'Labels'
  else selectorType = 'CTags'

  return {
    controllerName,
    selectedAccountKeys,
    selectorType,
    label: labels,
    ctag,
    users,
    groups,
    sudoers,
    groupsMap: gMap,
    disableAnonymousLogin,
    disableOSLogin
  }
}

export const useServerControllerWizardContext = () => useContext(ServerControllerWizardContext)
