import { faDocker } from '@fortawesome/free-brands-svg-icons'
import { faChevronRight, faExclamationCircle, faMicrochip } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import { Label, ResponsiveTable, Tooltip, Typography } from 'procyon-ui'
import React from 'react'
import { ProgressBar } from 'V2Components'

const MetricsTab = ({ medusaNodeState }) => {
  if (medusaNodeState.Spec.Metrics === '') return null

  const parsedData = JSON.parse(medusaNodeState.Spec.Metrics)
  const memory = parsedData.memory

  const topDiskUsages = _.orderBy(parsedData.disk_usage, ['total'], 'desc').filter((e) => e.total)

  const [totalStorage, totalFreeStorage] = topDiskUsages.reduce(
    (prev, curr) => {
      return [prev[0] + curr.total, prev[1] + curr.free]
    },
    [0, 0]
  )

  const getStorageTableData = () => {
    return topDiskUsages.map((disk) => {
      return {
        mountpoint: disk.mountpoint,
        free: disk.free,
        total: disk.total,
        bar: {
          availablePercent: Math.floor((disk.free / disk.total) * 100)
        }
      }
    })
  }

  const storageUsedPercent = 100 - Math.floor((totalFreeStorage / totalStorage) * 100)

  console.log('[medusa](nn):', parsedData)

  return (
    <div>
      <div className='flex gap-4 '>
        <div className='w-1/2  rounded p-2'>
          <Typography className='flex justify-between items-center' variant='h4-regular'>
            Memory Usage
            {memory.used_percent > 80 && (
              <Label
                iconButton={faExclamationCircle}
                variant='danger'
                text={`${storageUsedPercent}% of Memory has been used!`}
              />
            )}
          </Typography>
          <div className='mt-4'>
            <ProgressBar usedPercent={memory.used_percent.toFixed(2)} barHeight='0.5rem' />
          </div>
          <div className='mt-4 flex gap-2'>
            <Label text={`Total: ${(memory.total / 1000000).toFixed(2)} MB`} />
            <Label text={`Used: ${((memory.total - memory.available) / 1000000).toFixed(2)} MB`} />
            <Label text={`Available: ${(memory.available / 1000000).toFixed(2)} MB`} />
          </div>
        </div>
        <div className='w-1/2  rounded p-2'>
          <Typography className='flex justify-between items-center' variant='h4-regular'>
            Storage Usage
            {storageUsedPercent > 80 && (
              <Label
                iconButton={faExclamationCircle}
                variant='danger'
                text={`${storageUsedPercent}% of Storage has been used!`}
              />
            )}
          </Typography>
          <div className=' mt-4'>
            <ProgressBar barHeight='0.5rem' usedPercent={storageUsedPercent} />
          </div>
          <div className='mt-4 flex gap-2'>
            <Label text={`Total: ${(totalStorage / 1000000).toFixed(2)} MB`} />
            <Label text={`Used: ${((totalStorage - totalFreeStorage) / 1000000).toFixed(2)} MB`} />
            <Label text={`Available: ${(totalFreeStorage / 1000000).toFixed()} MB`} />
          </div>
        </div>
      </div>
      <div className='mt-6'>
        <Typography variant='h4-regular'>CPU Info</Typography>
        <div className='grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 grid gap-8 mt-4'>
          {parsedData.cpu_info.map((cpu) => {
            return (
              <div className='bg-gray-50 rounded p-4 '>
                <FontAwesomeIcon size='2x' color='#b7b8b8' icon={faMicrochip} />
                <div className='mt-2'>
                  <Typography className='!font-semibold' variant='h4-regular'>
                    {cpu.model_name}
                  </Typography>
                  <div className='mt-2'>
                    <Typography variant='body-regular'>{cpu.cores} CPU Cores</Typography>
                    <Typography className='mt-4' variant='caption-regular'>
                      {cpu.mhz} MHz
                    </Typography>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div className='mt-6'>
        <Typography variant='h4-regular'>Docker Stats</Typography>
        <div className='grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 grid gap-8 mt-4'>
          {parsedData.docker_stats.map((docker) => {
            const usedPercent = Math.floor((docker.mem_usage / docker.mem_limit) * 100)

            return (
              <div className='bg-gray-50 rounded p-4 relative'>
                <FontAwesomeIcon size='2x' color='#b7b8b8' icon={faDocker} />
                <div className='mt-2 mb-8'>
                  <Typography className='!font-semibold' variant='h4-regular'>
                    {docker.name}
                  </Typography>
                  <Typography variant='caption-regular'>{docker.container_id}</Typography>

                  <div className='mt-2'>
                    {docker.ports && (
                      <div>
                        <Typography className='mb-2' variant='body-semiBold'>
                          Ports
                        </Typography>
                        {docker.ports.map((port) => {
                          return (
                            <Typography className='flex items-center gap-1' variant='body-regular'>
                              Container Port: {port.container_port}, Host IP: {port.host_ip}, Host
                              Port: {port.host_port}
                            </Typography>
                          )
                        })}
                      </div>
                    )}
                  </div>
                  {docker.volumes && (
                    <>
                      <Typography className='mt-2' variant='body-semiBold'>
                        Volumes
                      </Typography>
                      <div className='mt-2'>
                        {docker.volumes.map((vol) => {
                          return (
                            <Typography className='flex items-center gap-1' variant='body-regular'>
                              <em>{vol.source}</em>{' '}
                              <FontAwesomeIcon fontSize={10} icon={faChevronRight} />{' '}
                              <em>{vol.target}</em>
                            </Typography>
                          )
                        })}
                      </div>
                    </>
                  )}
                </div>
                <div className='absolute bottom-0 left-0 w-[100%] px-2'>
                  <div>
                    <Typography className='pl-2' variant='caption-regular'>
                      Total Memory: {(docker.mem_limit / 1000000).toFixed(2)} MB, Used:{' '}
                      {(docker.mem_usage / 1000000).toFixed(2)} MB, Available:{' '}
                      {((docker.mem_limit - docker.mem_usage) / 1000000).toFixed(2)} MB
                    </Typography>
                    <div className='rounded p-2'>
                      <ProgressBar usedPercent={usedPercent} />
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div className='mt-6'>
        <Typography variant='h4-regular'>Disk Storage Usage</Typography>
        <div className='mt-6'>
          <ResponsiveTable scrollX='1200px' columns={columns()} data={getStorageTableData()} />
        </div>
      </div>
    </div>
  )
}

/**
 @returns {import('procyon-ui').ResponsiveTableProps['columns']}
 */
const columns = () => [
  {
    dataIndex: 'mountpoint',
    title: 'Mount Point',
    width: 250,
    align: 'left',
    fixed: 'left',
    sorter: (v1, v2) => v1.mountpoint.localeCompare(v2.mountpoint),
    render(value) {
      return (
        <Tooltip arrow title={value}>
          <div>
            <Typography variant='body-regular'>{value.slice(0, 50)}</Typography>
          </div>
        </Tooltip>
      )
    }
  },
  {
    dataIndex: 'free',
    title: 'Free',
    width: 250,
    align: 'left',
    fixed: 'left',
    sorter: (v1, v2) => v1.free - v2.free,
    render(value) {
      return <Typography variant='body-regular'>{(value / 1000000).toFixed(2)} MB</Typography>
    }
  },
  {
    dataIndex: 'total',
    title: 'Total',
    width: 250,
    align: 'left',
    fixed: 'left',
    sorter: (v1, v2) => v1.total - v2.total,
    render(value) {
      return <Typography variant='body-regular'>{(value / 1000000).toFixed(2)} MB</Typography>
    }
  },
  {
    dataIndex: 'bar',
    title: '',
    width: 250,
    align: 'left',
    fixed: 'left',

    render(value) {
      return (
        <div className=' rounded p-2'>
          <ProgressBar usedPercent={100 - value.availablePercent} />
        </div>
      )
    }
  }
]

export { MetricsTab }
