import { useAddAccountWizardContext } from 'features/clouds'
import { TextInput, Typography } from 'procyon-ui'
import React from 'react'
import { LabelContent } from 'V2Components'

const ConfigURLStep = ({}) => {
  const { privateSpec, setPrivateSpec } = useAddAccountWizardContext()

  return (
    <div>
      <TextInput
        value={privateSpec.link}
        onChange={(e) => setPrivateSpec({ link: e.target.value })}
        label='Config URL'
        type='url'
        sx={{ width: '100%' }}
      />
      <Typography className='mt-2' variant='caption-regular'>
        Enter public URL pointing the account config file.
      </Typography>
    </div>
  )
}

const SummaryStep = ({}) => {
  const { privateSpec, description, name } = useAddAccountWizardContext()

  return (
    <div>
      <LabelContent title='Name' content={name} />
      <LabelContent title='Description' content={description} />
      <LabelContent title='Config Link' content={privateSpec.link} />
    </div>
  )
}

export const usePrivateSteps = () => {
  return [
    {
      label: 'Config URL',
      content: <ConfigURLStep />
    },
    {
      label: 'Summary',
      content: <SummaryStep />
    }
  ]
}
