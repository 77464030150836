import { SvgIcon } from '@material-ui/core'
import React, { forwardRef } from 'react'

/**
 *
 * @param {import('@material-ui/core').SvgIconProps} props
 * @returns
 */
const GatewaySharp = forwardRef((props, ref) => {
  return (
    <SvgIcon ref={ref} viewBox='0 0 55 44' {...props}>
      <path
        d='M7.858 15.716a7.856 7.856 0 0 0 7.858-7.858C15.716 3.53 12.186 0 7.858 0A7.856 7.856 0 0 0 0 7.858a7.84 7.84 0 0 0 7.858 7.858zm38.466 0a7.84 7.84 0 0 0 7.858-7.858A7.856 7.856 0 0 0 46.324 0c-4.329 0-7.858 3.53-7.858 7.858a7.856 7.856 0 0 0 7.858 7.858zM7.858 12.599a4.696 4.696 0 0 1-4.74-4.741 4.712 4.712 0 0 1 4.74-4.74 4.696 4.696 0 0 1 4.74 4.74c0 2.654-2.06 4.74-4.74 4.74zm38.466 0a4.712 4.712 0 0 1-4.74-4.741 4.712 4.712 0 0 1 4.74-4.74c2.68 0 4.74 2.086 4.74 4.74 0 2.654-2.06 4.74-4.74 4.74zm-25.816-2.474c1.056 0 1.984-.927 1.984-2.035 0-1.082-.928-1.984-1.984-1.984-1.108 0-2.035.902-2.035 1.984 0 1.108.927 2.035 2.035 2.035zm6.544 0c1.108 0 2.01-.927 2.01-2.035 0-1.082-.902-1.984-2.01-1.984a2.001 2.001 0 0 0-1.984 1.984c0 1.108.902 2.035 1.984 2.035zm6.596 0a2.057 2.057 0 0 0 2.035-2.035c0-1.082-.927-1.984-2.035-1.984-1.057 0-1.984.902-1.984 1.984 0 1.108.927 2.035 1.984 2.035zm6.054 11.465c1.108 0 2.01-.902 2.01-2.01 0-1.081-.902-1.983-2.01-1.983a2.001 2.001 0 0 0-1.984 1.984c0 1.107.902 2.01 1.984 2.01zm-25.248 0c1.082 0 1.983-.902 1.983-2.01a2.001 2.001 0 0 0-1.983-1.983c-1.108 0-2.01.902-2.01 1.984 0 1.107.902 2.01 2.01 2.01zm20.456 5.282c1.108 0 2.01-.902 2.01-2.01 0-1.082-.902-1.984-2.01-1.984a2.001 2.001 0 0 0-1.984 1.984c0 1.108.902 2.01 1.984 2.01zm-15.664 0c1.082 0 1.984-.902 1.984-2.01a2.001 2.001 0 0 0-1.984-1.984c-1.108 0-2.01.902-2.01 1.984 0 1.108.902 2.01 2.01 2.01zm7.858 17.133c4.328 0 7.832-3.53 7.832-7.884 0-4.302-3.504-7.832-7.832-7.832-4.354 0-7.858 3.53-7.858 7.832 0 4.354 3.504 7.884 7.858 7.884zm0-3.118c-2.654 0-4.74-2.086-4.74-4.766 0-2.628 2.086-4.715 4.74-4.715 2.653 0 4.715 2.087 4.715 4.715 0 2.68-2.036 4.766-4.715 4.766z'
        fill-rule='nonzero'
        fill-opacity='.6'
      />
    </SvgIcon>
  )
})

export default GatewaySharp
