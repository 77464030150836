import { faCircleExclamation } from '@fortawesome/pro-duotone-svg-icons'
import { faPersonRunning } from '@fortawesome/pro-solid-svg-icons'
import { getMedusaNodeStateImages } from 'features/gateways/utils'
import moment from 'moment'
import { Label, Typography } from 'procyon-ui'
import React from 'react'

const ImageStatsTab = ({ medusaNodeState }) => {
  const images = getMedusaNodeStateImages(medusaNodeState)

  return (
    <div className='grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 grid gap-8 mt-4'>
      {images.map((image) => {
        return (
          <div className='border rounded p-2'>
            <div>
              <div className='flex justify-between items-center'>
                <span className='bg-green-500 mb-2 rounded text-white w-8 h-8 flex items-center justify-center font-bold '>
                  {image.initial}
                </span>
                {image.StatusMessage === 'running' && (
                  <Label iconButton={faPersonRunning} variant='success' text='Running' />
                )}
                {image.StatusMessage !== 'running' && (
                  <Label
                    iconButton={faCircleExclamation}
                    variant='warning'
                    text={image.StatusMessage}
                  />
                )}
              </div>
              <Typography className='!font-semibold' variant='h4-regular'>
                {image.Name}
              </Typography>
            </div>
            <Typography className='mt-2' variant='body-regular'>{image.CurrentImage}</Typography>
            <Typography className='mt-4' variant='caption-regular'>
              <span className='font-semibold'>Last Started</span>,{' '}
              {moment(image.LastStarted).format('MMMM Do YYYY, h:mm A')}
            </Typography>
          </div>
        )
      })}
    </div>
  )
}

export { ImageStatsTab }
