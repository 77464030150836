import {
  faExclamationTriangle,
  faKey,
  faLock,
  faShieldAlt,
  faTerminal,
  faUser
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { createDataSelectorHook } from 'infra/redux'
import moment from 'moment'
import { Button, DataTable, RiskIndicator, TargetIcon, Tooltip } from 'procyon-ui'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { ShimmerTableRow } from 'Utils/ShimmerEffect'
import _ from 'lodash'

const useslices = createDataSelectorHook(['iamFederatedUsers', 'iamRoles', 'accountList'])
const FederatedUserTable = ({ searchQuery }) => {
  const { slices } = useslices()
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(true)
  const [tableRows, setTableRows] = useState([])
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 15,
    page: 0
  })

  const getAccountType = (id) => {
    const account = slices.accountList.find((account) => account.ObjectMeta.ID === id)
    return account?.Spec?.Type
  }

  useEffect(() => {
    if (!slices || slices.iamFederatedUsers.length === 0) {
      setIsLoading(true)
    }

    const getAccountObj = (accountRefID) => {
      const account = slices?.accountList?.find((account) => account.ObjectMeta.ID === accountRefID)
      return account?.Spec?.DisplayName || account?.Spec?.Description
    }

    if (slices?.iamFederatedUsers?.length > 0 && tableRows.length === 0) {
      const rows = slices.iamFederatedUsers
        .slice()
        .map((obj, index) => {
          const transformedObj = {
            id: index + 1,
            platform: getAccountType(obj.Spec.Account.RefID),
            name: obj.Spec.UserName,
            lastused: '',
            riskResources: obj.Spec.RiskScore,
            account: getAccountObj(obj.Spec.Account.RefID),
            accountRef: obj.ObjectMeta.ID,
            finding: obj
          }

          const findingsLabels = generateFindingsLabels({ row: transformedObj })
          transformedObj.findingsLength = findingsLabels.length

          return transformedObj
        })
        .sort((a, b) => {
          if (a.findingsLength > b.findingsLength) return -1
          if (a.findingsLength < b.findingsLength) return 1

          const dateA = moment(a.createdate, 'MM-DD-YY HH:mm:ss')
          const dateB = moment(b.createdate, 'MM-DD-YY HH:mm:ss')
          if (dateA.isBefore(dateB)) return 1
          if (dateA.isAfter(dateB)) return -1

          return 0
        })

      setTableRows(rows)
      setIsLoading(false)
    }
  }, [slices])

  const handleRedirectRemedyDetails = (id) => {
    history.push(`/admin/idaanalyzer/IamFederatedUser/${encodeURIComponent(id)}`)
  }

  const handleGraphRedirection = (accountRef) => {
    history.push(`/admin/idaanalyzer/idagraph/IamFederatedUser/${encodeURIComponent(accountRef)}`)
  }

  const getRiskLevel = (riskValue) => {
    if (riskValue >= 0 && riskValue <= 3.9) {
      return 'low'
    } else if (riskValue >= 4 && riskValue <= 6.9) {
      return 'medium'
    } else if (riskValue >= 7 && riskValue <= 8.9) {
      return 'high'
    } else if (riskValue >= 9 && riskValue <= 10) {
      return 'critical'
    } else {
      return 'none'
    }
  }

  const filteredRows = tableRows.filter((row) => {
    const search = searchQuery?.toLowerCase()
    if (search) {
      return row.name.toLowerCase().includes(search)
    } else {
      return tableRows
    }
  })

  const generateFindingsLabels = (data) => {
    const assumeRoleMap = data?.row?.finding?.Spec?.AwsSpec?.AssumeRoles?.AssumeRoleMap
    const iamRoleKey = Object.keys(assumeRoleMap)[0]
    const iamRoleRefID = iamRoleKey && assumeRoleMap[iamRoleKey]?.IamRole?.RefID
    // setSelectedRoleRefID(iamRoleRefID)
    const iamRoleFinding =
      iamRoleRefID && _.find(slices.iamRoles, { ObjectMeta: { ID: iamRoleRefID } })

    const userData = iamRoleFinding?.Spec?.AwsRoleSpec
    const labels = []

    // MFA Enabled/Disabled Label
    if (userData?.MFAEnabled === false) {
      labels.push(
        <Tooltip title='Multiple Authenticator Not Enabled'>
          <span className='bg-white shadow-md rounded-full w-[30px] h-[30px] flex items-center justify-center mr-3'>
            <FontAwesomeIcon icon={faShieldAlt} color='red' className='cursor-pointer' />
          </span>
        </Tooltip>
      )
    }

    // Console Access Label
    if (userData?.ConsoleAccess === true) {
      labels.push(
        <Tooltip title='Console Access Enabled'>
          <span className='bg-white shadow-md rounded-full w-[30px] h-[30px] flex items-center justify-center mr-3'>
            <FontAwesomeIcon icon={faTerminal} className='cursor-pointer' />
          </span>
        </Tooltip>
      )
    }

    // Analyzer Findings Labels
    const analyzerFindings = userData?.AnalyzerFindings?.AnalyzerFindingMap || {}
    Object.keys(analyzerFindings).forEach((key) => {
      const finding = analyzerFindings[key]

      // UnusedPermission Label (Clickable)
      if (finding.Type === 'UnusedPermission') {
        labels.push(
          <Tooltip title='Unused Permission'>
            <span
              key={key}
              className='bg-white shadow-md rounded-full w-[30px] h-[30px] flex items-center justify-center mr-3'
            >
              <FontAwesomeIcon icon={faExclamationTriangle} />
            </span>
          </Tooltip>
        )
      }

      if (finding.Type === 'UnusedIAMRole') {
        labels.push(
          <Tooltip title='Unused Role'>
            <span
              key={key}
              className='bg-white shadow-md rounded-full w-[30px] h-[30px] flex items-center justify-center mr-3'
            >
              <img src='img/icons/iam-role.png' alt='' />
            </span>
          </Tooltip>
        )
      }

      // UnusedIAMUserPassword Label (with Tooltip on hover)
      if (finding.Type === 'UnusedIAMUserPassword') {
        labels.push(
          <Tooltip key={key} title={`Unused Password`}>
            <span className='bg-white shadow-md rounded-full w-[30px] h-[30px] flex items-center justify-center mr-3'>
              <FontAwesomeIcon icon={faLock} />
            </span>
          </Tooltip>
        )
      }
    })

    // Find the Most Recent Last Access Date from Access Keys
    const accessKeys = userData?.AccessKeys?.AccessKeys || []
    let mostRecentLastAccessed = 'Unknown'

    accessKeys.forEach((accessKey) => {
      const analyzerMap = accessKey?.AnalyzerFindings?.AnalyzerFindingMap || {}

      Object.keys(analyzerMap).forEach((key) => {
        const finding = analyzerMap[key]
        const details = JSON.parse(finding.Details || '[]')

        // Extract LastAccessed dates from details and find the most recent one
        const lastAccessedDates = details
          .map((item) => item.Value.LastAccessed)
          .filter((date) => !!date) // Filter out any null or undefined values
          .sort((a, b) => new Date(String(b)).getTime() - new Date(String(a)).getTime()) // Ensure dates are parsed as numbers

        // Update the most recent LastAccessed if found
        if (lastAccessedDates.length > 0) {
          const latestDate = lastAccessedDates[0]
          if (
            mostRecentLastAccessed === 'Unknown' ||
            new Date(latestDate) > new Date(mostRecentLastAccessed)
          ) {
            mostRecentLastAccessed = latestDate
          }
        }
      })
    })

    // Only push the label with the most recent last access date for Access Keys
    if (mostRecentLastAccessed !== 'Unknown') {
      labels.push(
        <Tooltip
          title={`Access Key Last Accessed: ${moment(mostRecentLastAccessed).format(
            'MM-DD-YY HH:mm:ss'
          )}`}
        >
          <span className='bg-white shadow-md rounded-full w-[30px] h-[30px] flex items-center justify-center mr-3'>
            <FontAwesomeIcon icon={faKey} />
          </span>
        </Tooltip>
      )
    }

    return labels
  }

  return (
    <>
      <DataTable
        autoHeight={true}
        pageSizeOptions={[5, 10, 25, 50, 100]}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        columns={[
          {
            field: 'name',
            headerName: 'Name',
            renderCell: (param) =>
              isLoading ? (
                <ShimmerTableRow />
              ) : (
                <>
                  <span className='mr-2'>
                    <FontAwesomeIcon icon={faUser} />
                  </span>
                  <p className='font-medium'>{param.value}</p>
                </>
              ),
            width: 300
          },
          {
            field: 'account',
            headerName: 'Account',
            renderCell: (param) =>
              isLoading ? <ShimmerTableRow /> : <p className='font-medium'>{param.value}</p>,
            width: 220
          },
          {
            field: 'platform',
            headerName: 'Platforms',
            renderCell: (param) =>
              isLoading ? <ShimmerTableRow /> : <TargetIcon width='30px' type={param.value} />,
            width: 250
          },
          {
            field: 'riskResources',
            headerName: 'Risk Resources',
            renderCell: (params) =>
              isLoading ? (
                <ShimmerTableRow />
              ) : (
                <RiskIndicator variant={getRiskLevel(params.value)} />
              ),
            width: 359
          },
          {
            field: 'graph',
            headerName: 'Graph',
            renderCell: (param) =>
              isLoading ? (
                <ShimmerTableRow />
              ) : (
                <div className='bg-white shadow-md rounded-full w-[35px] h-[35px] flex items-center justify-center'>
                  <img
                    onClick={() => handleGraphRedirection(param.row.accountRef)}
                    src='img/icons/organization-chart.png'
                    alt=''
                    className='w-5 cursor-pointer'
                  />
                </div>
              ),
            width: 150
          },
          {
            field: 'finding',
            headerName: 'Findings',
            renderCell: (param) =>
              isLoading ? <ShimmerTableRow /> : <>{generateFindingsLabels(param)}</>,
            width: 350
          },
          {
            field: 'accountRef',
            headerName: 'Recommended Action',
            renderCell: (param) =>
              isLoading ? (
                <ShimmerTableRow />
              ) : (
                <>
                  {generateFindingsLabels(param).length > 0 && (
                    <span onClick={() => handleRedirectRemedyDetails(param.value)}>
                      <Button variant='secondary'>Action</Button>
                    </span>
                  )}
                </>
              ),
            width: 220
          }
        ]}
        rows={
          isLoading
            ? Array(1).fill({
                id: '',
                name: '',
                createdate: '',
                platforms: '',
                riskResources: ''
              })
            : filteredRows
        }
      />
    </>
  )
}

export { FederatedUserTable }
