import { faClock } from '@fortawesome/pro-duotone-svg-icons'
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons'
import { getResourceName } from 'features/resources'
import { createDataSelectorHook } from 'infra/redux'
import _ from 'lodash'
import moment from 'moment'
import { Button, Label, TabGroup, Typography } from 'procyon-ui'
import { DataDetailsProvider, useDataDetailsContext } from 'providers/DataDetailsProvider'
import React, { useState } from 'react'
import { FullScreenContentModal, JSONPrettyView, SummaryHeader } from 'V2Components'
import { ImageStatsTab } from './components/ImageStatsTab'
import { MetricsTab } from './components/MetricsTab'
import { HostInfoTab } from './components/HostInfoTab'

const useSlices = createDataSelectorHook(['medusaNodeStates'])

const GatewaysDetails = () => {
  const [showRawJSONView, setShowRawJSONView] = useState(false)
  const medusaNode = useDataDetailsContext()

  const { slices } = useSlices()

  const getMedusaNodeState = () => {
    const obj = _.find(slices.medusaNodeStates, {
      ObjectMeta: { Name: medusaNode.ObjectMeta.Name }
    })
    return obj
  }

  const medusaNodeState = getMedusaNodeState()

  const parsedData = JSON.parse(medusaNodeState?.Spec.Metrics || '{}')

  const clockDifferenceInSec =
    moment(medusaNodeState?.ObjectMeta.UpdatedAt).diff(parsedData.clock_time) / 1000

  return (
    <div className='mr-4'>
      <SummaryHeader
        actions={
          <>
            {clockDifferenceInSec >= 60 ? (
              <Label
                iconButton={faCircleExclamation}
                size='lg'
                variant='danger'
                text={`System Clock Out Of Sync by: ${Math.floor(clockDifferenceInSec)} Seconds`}
              />
            ) : (
              <Label
                iconButton={faClock}
                size='lg'
                variant='success'
                text={'System Clock in Sync!'}
              />
            )}
            <Button
              disabled={!medusaNodeState}
              onClick={() => setShowRawJSONView(true)}
              variant='grayBlue'
            >
              View Raw JSON
            </Button>
          </>
        }
        breadCrumbsItems={[
          {
            label: 'Gateways',
            link: `/admin/gateways`
          },
          {
            label: getResourceName(medusaNode)
          }
        ]}
        rsrcName={getResourceName(medusaNode)}
      />
      <div className='pt-4'>
        {medusaNodeState && (
          <TabGroup
            tabs={[
              {
                label: 'Host Info',
                tabContent: <HostInfoTab medusaNodeState={medusaNodeState} />
              },
              {
                label: 'Containers Status',
                tabContent: <ImageStatsTab medusaNodeState={medusaNodeState} />
              },
              {
                label: 'Metrics',
                tabContent: <MetricsTab medusaNodeState={medusaNodeState} />
              }
            ]}
          />
        )}
        {showRawJSONView && (
          <FullScreenContentModal fullHeight width='98vw'>
            <div className=' -mx-5'>
              <div className='top-0 right-0 flex justify-between items-center mb-4 px-4'>
                <Typography variant='h4-regular'>RAW JSON Metrics</Typography>
                <Button
                  variant='grayBlue'
                  className='ml-auto '
                  onClick={() => setShowRawJSONView(false)}
                >
                  Cancel
                </Button>
              </div>
              <div>
                <JSONPrettyView data={JSON.parse(medusaNodeState.Spec.Metrics)} height='100%' />
              </div>
            </div>
          </FullScreenContentModal>
        )}
      </div>
    </div>
  )
}

const Wrapped = () => (
  <DataDetailsProvider routeKey='name' sliceName='medusaNodes'>
    <GatewaysDetails />
  </DataDetailsProvider>
)

export { Wrapped as GatewaysDetails }
