import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import { CustomTypography } from 'Components/CustomTypography'
import ThemeInput from 'Components/ThemeInput'
import _ from 'lodash'
import React from 'react'
import ErrorBox from '../ErrorBox/ErrorBox'

const useStyles = makeStyles((theme) => ({
  label: {
    marginBottom: theme.spacing(0.5)
  }
}))

const PlainInput = ({ touched, errored, label, showLabel = true, labelClassname = '', inputHeight = '', ...rest }) => {
  const fieldId = _.uniqueId('field-')
  const classes = useStyles()
  return (
    <>
      {showLabel && (
        <CustomTypography.Body className={classNames([classes.label, labelClassname])}>{label}</CustomTypography.Body>
      )}
      <ThemeInput
        id={fieldId}
        variant='outlined'
        fullWidth
        color='primary'
        hiddenLabel
        inputProps={{
          style: { height: inputHeight }
        }}
        {...rest}
      />
      <ErrorBox error={errored} touched={touched} />
    </>
  )
}

export default PlainInput

PlainInput.propTypes = {
  // touched: PropTypes.bool
}

PlainInput.defaultProps = {
  showLabel: false,
  touched: false
}
