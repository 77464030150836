import { getResourceName } from 'features/resources'
import { reduxApiClient } from 'infra'
import _ from 'lodash'
import React from 'react'
import { enqueueNotification } from 'Utils/Helpers'
import { addSpaces } from 'Utils/PureHelperFuctions'
import { FullScreenAlertModal } from 'V2Components'

export const ApproveUserCredentials = ({ userCredential, credKeyToApprove, onCancel }) => {
  const handleApprove = async () => {
    const data = structuredClone(userCredential)
    const map = data.PublicKeyCredentialMap.PublicKeyCredentialMap

    // approve status
    map[credKeyToApprove] = { ...map[credKeyToApprove], Status: 'APPROVED' }
    data.PublicKeyCredentialMap.PublicKeyCredentialMap = map

    await reduxApiClient('user-credentials').update(data)
    enqueueNotification('Credential has been approved successfully!', 'info')
    onCancel()
  }

  const name = getResourceName(userCredential)

  return (
    <FullScreenAlertModal
      actionButtonText='Approve'
      actionButtonVariant='primary'
      loadingMessage={`Approving ${addSpaces(userCredential.ObjectMeta.Kind)}`}
      showModal
      onActionClick={handleApprove}
      onCancel={onCancel}
      alertMessage={
        <>
          Are you sure you want to approve pass key of
          <strong className='mx-1' title={name}>
            {name.substring(0, 21)}
            {name.length > 21 && '...'}
          </strong>
          ?
        </>
      }
    />
  )
}
