import React from 'react'
import { makeStyles, Box, Typography } from '@material-ui/core'
import { getAccountIcon } from 'Utils/PureHelperFuctions'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  accountIcon: {
    height: theme.spacing(),
    width: theme.spacing(),
    marginLeft: theme.spacing(0.375)
  },
  name: {
    marginLeft: theme.spacing(0.375)
  },
  id: {
    marginLeft: theme.spacing(0.375),
    color: theme.palette.text.secondary
  }
}))

function AccountInfoDisplay ({ account = null }) {
  const classes = useStyles()
  if (!account) return null
  const Icon = getAccountIcon(account.Spec.Type)
  return (
    <Box className={classes.root}>
      <Icon className={classes.accountIcon} />
      <Typography className={classes.name} variant='body2'>
        {account?.ObjectMeta?.Name}
      </Typography>
      <Typography className={classes.id} variant='body2'>
        {account?.Spec?.AccountID}
      </Typography>
    </Box>
  )
}

export default AccountInfoDisplay
