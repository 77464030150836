import { isValidEmail } from 'Utils/PureHelperFuctions'
import { Button, TextInput, Typography, RadioInput, Label } from 'procyon-ui'
import React, { useState } from 'react'

function UserInfoForm({
  onSubmit,
  firstName,
  lastName,
  emailID,
  isAdminUser,
  isEditMode = false,
  alreadyTakenEmailsObj = {}
}) {
  const [fName, setFName] = useState(firstName || '')
  const [lName, setLName] = useState(lastName || '')
  const [email, setEmail] = useState(emailID || '')
  const [isAdmin, setIsAdmin] = useState(isAdminUser || false)

  const getErrors = () => {
    if (!fName && !lName && !email) return 'All fields are required'
    if (fName.length < 3) return `First Name cannot be less than 3 characters`
    if (lName.length < 3) return `Last Name cannot be less than 3 characters`
    if (!email) return 'Email is required'
    if (alreadyTakenEmailsObj[email.toLowerCase()] && !isEditMode) {
      // Checking if the email is in queue or already taken by user.
      return `The email is already ${
        alreadyTakenEmailsObj[email.toLowerCase()] === 'queue' ? 'added to queue' : 'taken'
      }`
    }

    if (email.includes('+') || !isValidEmail(email)) return `Invalid email entered`
  }

  const handleSubmitClick = () => {
    onSubmit?.({ fName, lName, email, isAdmin })
    // Clear fields
    setLName('')
    setFName('')
    setEmail('')
    setIsAdmin(false)
  }

  const errors = getErrors()

  return (
    <div className='mt-6'>
      <div className='flex gap-6'>
        <TextInput
          id='add-users-fname-input'
          value={fName}
          onChange={(e) => setFName(e.target.value)}
          label='First Name*'
          sx={{ width: '50%' }}
        />
        <TextInput
          id='add-users-lname-input'
          value={lName}
          onChange={(e) => setLName(e.target.value)}
          label='Last Name*'
          sx={{ width: '50%' }}
        />
      </div>
      <div className='mt-8 mb-8'>
        <TextInput
          id='add-users-email-input'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          label='Email Address*'
          type='email'
          sx={{ width: '100%' }}
        />
      </div>
      <hr />
      <div className='mt-8 py-2 flex justify-between items-center'>
        <Typography variant='h4-regular'>User Type</Typography>
        <div className='flex gap-4'>
          <RadioInput id='add-users-user-radio' checked={!isAdmin} label='User' onChange={() => setIsAdmin(false)} />
          <RadioInput id='add-users-admin-radio' checked={isAdmin} label='Admin' onChange={() => setIsAdmin(true)} />
        </div>
      </div>
      <div className='flex justify-end items-center mt-4 gap-4'>
        {errors && <Label variant='warning' text={errors} />}
        <Button id='add-user-save-btn' disabled={!!errors} onClick={handleSubmitClick} variant='primary'>
          {isEditMode ? 'Save User' : 'Add To Queue'}
        </Button>
      </div>
    </div>
  )
}

export { UserInfoForm }
