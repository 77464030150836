import { getServerControllerTags } from 'features/serverControllers/utils'
import { Label, Typography } from 'procyon-ui'
import { DataDetailsProvider, useDataDetailsContext } from 'providers/DataDetailsProvider'
import React from 'react'
import { useHistory } from 'react-router'
import { Route } from 'react-router-dom/cjs/react-router-dom'
import { FullScreenContentModal, LabelContent } from 'V2Components'
import { CreateProxyControllerWizard } from '../CreateProxyControllerWizard'
import { Header } from './components/Header'
import { createDataSelectorHook } from 'infra/redux'
import _ from 'lodash'

const useSlices = createDataSelectorHook(['medusaNodes'])

const ProxyControllerDetails = () => {
  const proxyCtrl = useDataDetailsContext()
  const { slices } = useSlices()
  const { labels } = getServerControllerTags(proxyCtrl)

  const nodes = proxyCtrl.Spec.MedusaNodes.Elems
  const nodeTypes = proxyCtrl.Spec.NodeTypes.Elems

  const targetProxyImages = proxyCtrl.Spec.TargetImageInfo.Map

  const getMedusaNodes = () => {
    const list = []
    nodes.forEach((nodeID) => {
      const mNode = _.find(slices.medusaNodes, { NodeID: nodeID })
      if (mNode) list.push(mNode.ClusterID)
      else list.push(nodeID)
    })
    return list
  }

  const getTargetImageItems = () => {
    const items = []
    for (const key in targetProxyImages) {
      const image = targetProxyImages[key]

      items.push({ name: key, url: image })
    }
    return items
  }

  const history = useHistory()

  return (
    <div>
      <Header />
      <div className='flex gap-4'>
        <div className='w-1/2 pr-2 border-r'>
          <Typography className='mt-4' variant='h4-regular'>
            Information
          </Typography>

          {getMedusaNodes().length > 0 && (
            <LabelContent
              title='Nodes'
              fullWidth
              content={
                <div className='flex gap-1 flex-wrap'>
                  {getMedusaNodes().map((node) => {
                    return <Label variant='grayBlue' text={node} />
                  })}
                </div>
              }
            />
          )}
          {nodeTypes.length > 0 && (
            <LabelContent
              title='Node Types'
              fullWidth
              content={
                <div className='flex gap-1 flex-wrap'>
                  {nodeTypes.map((node) => {
                    return <Label variant='grayBlue' text={node} />
                  })}
                </div>
              }
            />
          )}
          <LabelContent
            title='Labels'
            fullWidth
            content={
              <div className='flex gap-1 flex-wrap'>
                {!labels.length && <Label text='None' />}
                {labels.map((l) => (
                  <Label key={l} text={l} variant='grayBlue' />
                ))}
              </div>
            }
          />
          <Typography className='mt-4' variant='h4-regular'>
            Proxy Images
          </Typography>
          {getTargetImageItems().map((e) => (
            <LabelContent title={e.name} fullWidth content={e.url} key={e.name} />
          ))}
        </div>
      </div>
      {/* @ts-ignore */}
      <Route exact path={[`/admin/proxy-controllers/:name/edit`]}>
        <FullScreenContentModal>
          <CreateProxyControllerWizard proxyCtrl={proxyCtrl} onCancel={() => history.goBack()} />
        </FullScreenContentModal>
      </Route>
    </div>
  )
}

const Wrapped = () => (
  <DataDetailsProvider routeKey='name' sliceName='proxyControllers'>
    <ProxyControllerDetails />
  </DataDetailsProvider>
)

export { Wrapped as ProxyControllerDetails }
