import { faSpinner } from '@fortawesome/pro-duotone-svg-icons'
import { faAngleDown, faAngleRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Handle, Position } from '@xyflow/react'
import { SignalBars, TargetIcon, Tooltip, Typography } from 'procyon-ui'
import React from 'react'

const CustomNode = ({ data, isConnectable }) => {
  const {
    label,
    targetIconType,
    showTargetHandle,
    showSourceHandle,
    isCollapsible,
    onExpand,
    properties,
    description,
    isLoading,
    customRender,
    Icon: CustomIcon,
    meta
  } = data

  const { collapsed, highlight } = meta

  const Icon = (
    <TargetIcon className='rounded !border-none' type={targetIconType || 'All_Resource'} />
  )

  const getRiskLevel = (riskValue) => {
    if (riskValue >= 0 && riskValue <= 3.9) {
      return 'low'
    } else if (riskValue >= 4 && riskValue <= 6.9) {
      return 'medium'
    } else if (riskValue >= 7 && riskValue <= 8.9) {
      return 'high'
    } else if (riskValue >= 9 && riskValue <= 10) {
      return 'critical'
    } else {
      return 'none'
    }
  }

  if (customRender) {
    return customRender({
      TargetHandle: showTargetHandle && (
        <Handle
          type='target'
          position={Position.Left}
          style={{ background: '#7aa1db' }}
          onConnect={(params) => console.log('handle onConnect', params)}
          isConnectable={isConnectable}
        />
      ),
      SourceHandle: showSourceHandle && (
        <Handle
          type='source'
          position={Position.Right}
          id='a'
          style={{ background: '#7aa1db' }}
          isConnectable={isConnectable}
        />
      )
    })
  }

  return (
    <>
      {showTargetHandle && (
        <Handle
          type='target'
          position={Position.Left}
          style={{ background: '#7aa1db' }}
          onConnect={(params) => console.log('handle onConnect', params)}
          isConnectable={isConnectable}
        />
      )}
      <div
        className={`relative w-[250px] border rounded break-words  p-2 z-[100] ${
          highlight ? 'border-red-500 bg-[#ffd5d565]' : 'border-[#7aa1db] bg-[#e9eff865]'
        }`}
      >
        <div className='flex gap-2 items-center justify-start'>
          {CustomIcon ? CustomIcon : Icon}
          <div className='flex items-center'>
            <Typography
              className='!text-center !font-semibold whitespace-nowrap'
              variant='body-regular'
            >
              {label}
            </Typography>
            <div className='cursor-pointer flex items-center'>
              {properties?.MfaEnabled && (
                <Tooltip
                  title={`MFA ${properties?.MfaEnabled === 'true' ? 'Enabled' : 'Not Enabled'}`}
                >
                  <span
                    className={`mx-1 ${
                      properties?.MfaEnabled === 'true' ? 'opacity-100' : 'opacity-40'
                    }`}
                  >
                    <img className='w-4' src='img/icons/mfa-icon.png' alt='' />
                  </span>
                </Tooltip>
              )}

              {properties?.ConsoleAccess && (
                <Tooltip
                  title={`${
                    properties?.ConsoleAccess === 'true'
                      ? 'Has console access'
                      : 'Does not have console access'
                  }`}
                >
                  <span
                    className={`mx-1 ${
                      properties?.ConsoleAccess === 'true' ? 'opacity-100' : 'opacity-40'
                    }`}
                  >
                    <img className='w-[20px]' src='img/icons/aws_console.png' alt='' />
                  </span>
                </Tooltip>
              )}

              {properties?.RiskScore && (
                <Tooltip title={`Severity Level ${getRiskLevel(properties?.RiskScore)} `}>
                  <span className='ml-1'>
                    <SignalBars variant={getRiskLevel(properties?.RiskScore)} />
                  </span>
                </Tooltip>
              )}
            </div>
          </div>
        </div>

        <Typography className='mt-2 !text-sm whitespace-nowrap' variant='body-regular'>
          {description}
        </Typography>
        {isCollapsible && (
          <button
            onClick={onExpand}
            disabled={isLoading}
            className={`absolute top-2 right-2 p-2 rounded-sm ${
              highlight ? 'bg-[#f2000024]' : 'bg-gray-100'
            }`}
          >
            {!isLoading && (
              <FontAwesomeIcon
                icon={collapsed ? faAngleDown : faAngleRight}
                color={highlight ? 'red' : undefined}
              />
            )}
            {isLoading && <FontAwesomeIcon className='animate-spin' icon={faSpinner} />}
          </button>
        )}
      </div>
      {showSourceHandle && (
        <Handle
          type='source'
          position={Position.Right}
          id='a'
          style={{ background: '#7aa1db' }}
          isConnectable={isConnectable}
        />
      )}
    </>
  )
}

export { CustomNode }
