import { faCheckCircle, faXmarkCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useWebAuthnLogin } from 'features/webauthn'
import { Spinner, Typography } from 'procyon-ui'
import React from 'react'
import { cn } from 'Utils/Helpers'
import './main.css'

const WebAuthn = () => {
  const { progressMessage, progressStatus } = useWebAuthnLogin()

  return (
    <div className='h-[100vh] w-[100vw] flex justify-center items-center gradient-background'>
      <div className='flex-col bg-gray-800 rounded-md h-[250px] w-[400px] flex justify-center items-center'>
        <Typography className='!text-white !font-bold !mb-6' variant='h2'>
          Procyon.ai WebAuthn Login
        </Typography>
        {progressStatus === 'running' && <Spinner />}
        {progressStatus === 'success' && (
          <FontAwesomeIcon color='#3cdf74' icon={faCheckCircle} size='3x' />
        )}
        {progressStatus === 'error' && (
          <FontAwesomeIcon color='#f7564a' icon={faXmarkCircle} size='3x' />
        )}

        <Typography
          key={progressMessage}
					className={cn('animate-fadeIn !text-white pt-10', {
						'!text-[#f7564a]': progressStatus === 'error',
						'!text-[#3cdf74]': progressStatus === 'success'

					})}
          variant='body-regular'
        >
          {progressMessage}
        </Typography>
      </div>
    </div>
  )
}

export { WebAuthn }
