export const getUserCredentialsPublicKeys = (usrCreds) => {
  if (!usrCreds) return []
  const map = usrCreds.PublicKeyCredentialMap.PublicKeyCredentialMap
  const items = []

  for (const key in map) {
    // add key in the obj
    items.push({ ...map[key], key })
  }

  return items
}
