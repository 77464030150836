// @ts-nocheck
import { ApiProvider, useDispatch } from 'Core'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { serverListSlice } from 'infra/redux/reducers'
import * as Yup from 'yup'
import { confirmUniqueness, enqueueNotification, getUserInfo } from 'Utils/Helpers'
import { useFormik } from 'formik'
import { Box, CircularProgress, FormControl, FormControlLabel, Grid, Radio, RadioGroup } from '@material-ui/core'
import Help from './Help'
import ThemeButton from 'Components/ThemeButton'
import PlainInput from 'Components/PlainInput/PlainInput'
import CommandViewer from './CommandViewer'
import ProxyTokenHelp from './ProxyTokenHelp'
const CommandForm = ({ handleClose, filterComponent }) => {
  const [command, setCommand] = useState(false)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    dispatch(serverListSlice.thunk({ flags: { skipLoader: true } }))
  }, [])
  const InitialValues = useMemo(() => {
    return { ClusterID: '', Hostname: '', _ProxyType: 'PrivateProxy' }
  }, [])
  const VALIDATION_RULES = useMemo(() => {
    return Yup.object({
      ClusterID: Yup.string()
        .test('confirmUniqueness', '*This Cluster ID is already taken', function (value) {
          return confirmUniqueness(value, 'proxyList', (e) => e.Status.ClusterID)
        })
        .required('*Cluster ID is required'),
        Hostname: Yup.string()
        .when('_ProxyType', {
          is: 'Proxy Token',
          otherwise: Yup.string().required('*Hostname is required')
        }),
    })
  }, [])

  const onSubmit = useCallback(
    async (fields) => {
      try {
        setLoading(true)
        let ProxyType 
        if (['PrivateProxy', 'Proxy Token'].includes(fields._ProxyType)) 
        { ProxyType = 'PrivateProxy'}
        else { ProxyType = 'Relay' }

        const formPayload = {
          ObjectMeta: {
            Tenant: getUserInfo().tenants,
            Namespace: getUserInfo().org
          },
          ClusterID: fields.ClusterID,
          ProxyType
        }
        console.log('formPayload: ', formPayload)
        const response = await new ApiProvider('proxytokens').setInstance(formPayload).post()
        if (response) {
          let command = `docker run -itd -e ENV_PROXY_TOKEN=${response.data.ProxyToken} --net=host --name procyon-proxy -v /var/log/procyon:/var/log/procyon -v /var/lib/procyon/ssl:/procyon/ssl -v /var/lib/procyon/recordings:/procyon/recordings public.ecr.aws/v2s2h8i9/procyon-proxy /procyon/bin/cyonproxy -hostname ${fields.Hostname}`
          if (fields._ProxyType === 'TargetRelay') {
            command = `docker run -itd -e ENV_PROXY_TOKEN=${response.data.ProxyToken} --net=host --name procyon-target -v /var/log/procyon:/var/log/procyon -v /var/lib/procyon/ssl:/procyon/ssl -v /var/lib/procyon:/var/lib/procyon -v /etc/ssh/sshd_config:/etc/ssh/sshd_config public.ecr.aws/v2s2h8i9/procyon-target  /procyon/bin/cyontarget -hostname ${fields.Hostname}`
          }
          if (fields._ProxyType === 'Proxy Token') {
            command = `${response.data.ProxyToken}`
          }
          setCommand({command,type:fields._ProxyType})
        }
        console.log('response', response)
        enqueueNotification('Docker command generated', 'info')
        formik.resetForm(InitialValues)
      } catch (error) {
        console.error('error in proxytokens onSubmit', error)
        enqueueNotification('Operation failed', 'error')
      } finally {
        setLoading(false)
      }
    },
    [handleClose, InitialValues]
  )

  const formik = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    onSubmit,
    initialValues: InitialValues,
    validationSchema: VALIDATION_RULES
  })

  const onCancel = () => {
    formik.handleReset(InitialValues)
    handleClose()
  }

  const handleProxyRadioChange = (e) => {
    formik.setFieldValue('_ProxyType', e.target?.value)
  }

  return (
    <Box minHeight='20rem' display='flex' flexDirection='column' justifyContent='space-between'>
      <Grid container spacing={3} direction='row'>
        <Grid item xs={4}>
          {(formik.values._ProxyType === 'Proxy Token' || command?.type === 'Proxy Token') ? <ProxyTokenHelp /> : <Help /> }
        </Grid>
        <Grid item xs={8} style={{ paddingLeft: '1rem' }}>
          {filterComponent}
          {command && <CommandViewer 
          actionLabel={command?.type==='Proxy Token'? 'Copy Proxy Token' : 'Copy docker command'} 
          copyMessage={command?.type==='Proxy Token'? 'Proxy Token copied to clipboard!' : 'Docker command copied to clipboard!'} 
          command={command?.command} />}
          {!command && (
            <>
              <Box mb={1}>
                <FormControl>
                  <RadioGroup
                    value={formik.values._ProxyType}
                    onChange={handleProxyRadioChange}
                    row
                    aria-labelledby='row-radio-buttons-group-label-proxy'
                    name='row-radio-buttons-group-proxy'
                  >
                    <FormControlLabel value='PrivateProxy' control={<Radio color='primary' />} label='Private Proxy' />
                    <FormControlLabel value='Relay' control={<Radio color='primary' />} label='Relay' />
                    <FormControlLabel value='TargetRelay' control={<Radio color='primary' />} label='Target Relay' />
                    <FormControlLabel value='Proxy Token' control={<Radio color='primary' />} label='Proxy Token' />
                  </RadioGroup>
                </FormControl>
              </Box>
              <PlainInput
                mt={0}
                mb={0}
                {...formik.getFieldProps('ClusterID')}
                height={0.8}
                label='Cluster ID'
                showLabel
                errored={formik.errors.ClusterID}
                touched={formik.touched.ClusterID}
              />
              {formik.values._ProxyType !== 'Proxy Token'&&
              <Box mt={1}>
                <PlainInput
                  mt={0}
                  mb={0}
                  {...formik.getFieldProps('Hostname')}
                  height={0.8}
                  label='Hostname '
                  showLabel
                  errored={formik.errors.Hostname}
                  touched={formik.touched.Hostname}
                />
              </Box>}
            </>
          )}
        </Grid>
      </Grid>
      <Box mt='auto' display='flex' alignItems='center' justifyContent='flex-end'>
        <ThemeButton size='small' onClick={onCancel}>
          Cancel
        </ThemeButton>
        <Box ml={1}>
          {!command && (
            <ThemeButton theme='primary' size='small' onClick={formik.handleSubmit}>
              {loading && <CircularProgress size={24} />}
              <Box ml={1}> {formik.values._ProxyType === 'Proxy Token'?'Generate Token':'Get Docker Command'}</Box>
            </ThemeButton>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default CommandForm
