import useMultiDispatch from 'Core/Hooks/useMultiDispatch'
import { useRouteParams } from 'Core/Hooks/useRouteParams'
import {
  CloudDetailsProvider,
  CloudTypeRouteProvider,
  RescanProvider,
  isOrgAccount,
  useCloudDetailsProvider
} from 'features/clouds'
import { TargetsSliceNames } from 'features/targets'
import { TabGroup } from 'procyon-ui'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { ChildAccountsTab } from './components/ChildAccountsTab'
import { Header } from './components/Header'
import { OverviewTab } from './components/OverviewTab'
import { ProjectsAndFoldersTab } from './components/ProjectsAndFoldersTab'
import { ResourceTab } from './components/ResourcesTab'

function AccountDetails() {
  const { dispatchThunks } = useMultiDispatch([
    ...TargetsSliceNames,
    'slackChannelList',
    'jiraProjectList',
    'notificationSubscriptions',
    'servicenowtables'
  ])
  const [activeTab, setActiveTab] = useState('overview')
  const { cloud: account, isPrivate } = useCloudDetailsProvider()
  const history = useHistory()

  // @ts-ignore
  const { tabName, cloudType } = useParams()

  useRouteParams({
    syncUsingParamValue: true,
    key: 'tabName',
    defaultValue: 'overview',
    controlValue: activeTab.toLowerCase(),
    possibleValues: ['overview', 'resources', 'child-accounts'],
    onControlValueChange(value) {
      setActiveTab(value.toLowerCase())
    }
  })

  const getTabs = () => {
    const tabs = [
      {
        label: 'Overview',
        tabContent: <OverviewTab />
      }
    ]
    if (!isOrgAccount(account) && !isPrivate) {
      tabs.push({
        label: 'Resources',
        tabContent: <ResourceTab />
      })
    }
    if (isOrgAccount(account)) {
      tabs.push({
        label: 'Child Accounts',
        tabContent: <ChildAccountsTab />
      })
    }
    if (account.Spec?.Type === 'GCP') {
      tabs.push({
        label: 'Projects and Folders',
        tabContent: <ProjectsAndFoldersTab />
      })
    }
    return tabs
  }

  if (!tabName) {
    history.replace(
      `/admin/clouds/${cloudType?.toLowerCase()}/${encodeURIComponent(
        account.ObjectMeta.Name
      )}/overview`
    )
  }

  useEffect(() => {
    dispatchThunks()
  }, [])

  return (
    <RescanProvider account={account}>
      <div>
        <div className='bg-[#F9FBFC] px-12 py-4 -ml-8 -mr-5 -mt-4'>
          <Header />
        </div>
        <div className='mt-6'>
          <TabGroup
            controllable
            activeIndex={TAB_INDEX_MAP[activeTab]}
            onChangeTab={(_, v) => {
              history.replace(
                `/admin/clouds/${cloudType.toLowerCase()}/${encodeURIComponent(
                  account.ObjectMeta.Name
                )}/${TAB_NAME_MAP[v]}`
              )
              setActiveTab(TAB_NAME_MAP[v])
            }}
            tabs={getTabs()}
          />
        </div>
      </div>
    </RescanProvider>
  )
}

const TAB_INDEX_MAP = {
  overview: 0,
  resources: 1,
  'child-accounts': 2
}
const TAB_NAME_MAP = {
  0: 'overview',
  1: 'resources',
  2: 'child-accounts'
}

const Wrapped = () => (
  <CloudDetailsProvider>
    <CloudTypeRouteProvider routeKey='cloudType'>
      <AccountDetails />
    </CloudTypeRouteProvider>
  </CloudDetailsProvider>
)

export { Wrapped as AccountDetails }
