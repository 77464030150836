import moment from 'moment'
import { Label, ResponsiveTable, Typography } from 'procyon-ui'
import React from 'react'
import { LabelContent } from 'V2Components'

const HostInfoTab = ({ medusaNodeState }) => {
  if (medusaNodeState.Spec.Metrics === '') return null

  const parsedData = JSON.parse(medusaNodeState.Spec.Metrics)
  const { kernel_version, hostname, os, platform } = parsedData.host_info

  const getNetworkTableData = () => {
    return parsedData.network.map((net) => {
      return {
        interface: net.name,
        mac: net.mac_address,
        addresses: net.addresses
      }
    })
  }

  return (
    <div>
      <div className='w-1/2'>
        <LabelContent title='Host Name' content={hostname} />
        <LabelContent title='Kernel Version' content={kernel_version} />
        <LabelContent title='OS' content={os} />
        <LabelContent title='Platform' content={platform} />
        <LabelContent
          title='Boot Time'
          content={moment(parsedData.boot_time).format('MMMM Do YYYY, h:mm A')}
        />
      </div>
      <div className='mt-6'>
        <Typography variant='h4-regular'>Network Interface Information</Typography>
        <div className='mt-6'>
          <ResponsiveTable scrollX='1200px' columns={columns()} data={getNetworkTableData()} />
        </div>
      </div>
    </div>
  )
}

/**
 @returns {import('procyon-ui').ResponsiveTableProps['columns']}
 */
const columns = () => [
  {
    dataIndex: 'interface',
    title: 'Interface Name',
    width: 250,
    align: 'left',
    fixed: 'left',
    sorter: (v1, v2) => v1.interface.localeCompare(v2.interface)
  },
  {
    dataIndex: 'mac',
    title: 'MAC Address',
    width: 250,
    align: 'left',
    fixed: 'left',
    sorter: (v1, v2) => v1.mac.localeCompare(v2.mac)
  },
  {
    dataIndex: 'addresses',
    title: 'Addresses',
    width: 250,
    align: 'left',
    fixed: 'left',
    render(value) {
      return (
        <div className='flex gap-1'>
          {value.map((e) => (
            <Label text={e} key={e} />
          ))}
        </div>
      )
    }
  }
]

export { HostInfoTab }
