import { Box, makeStyles } from '@material-ui/core'
import GatewaySharp from 'Components/CustomIcons/GatewaySharp'
import { CustomTypography } from 'Components/CustomTypography'
import React from 'react'
const useStyle = makeStyles((theme) => ({
  iconBackground: {
    borderRadius: theme.spacing(0.25),
    height: theme.spacing(5.938),
    background: theme.palette.secondary.pale.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  icon: {
    color: theme.palette.primary.light,
    height: theme.spacing(3.375),
    width: theme.spacing(3.375)
  }
}))
const Help = () => {
  const classes = useStyle()
  return (
    <Box width='15rem'>
      <Box className={classes.iconBackground}>
        <GatewaySharp className={classes.icon} />
      </Box>
      <Box mt={1.375}>
        <CustomTypography.Body>Add Gateway to Procyon</CustomTypography.Body>
        <Box mt={0.625}>
          <CustomTypography.Body color='textSecondary'>
            Install Procyon Gateway in your account. Procyon Authenticator app will connect to this gateway to provide managed resource access to users.
          </CustomTypography.Body>
        </Box>
      </Box>
    </Box>
  )
}

export default Help
