export const AwsRsrcType = {
  AWS_Glue_Workflow: 'AWS_Glue_Workflow',
  AWS_Cloudwatch_Dashboard: 'AWS_Cloudwatch_Dashboard',
  AWS_Cloudwatch_Metri: 'AWS_Cloudwatch_Metri',
  AWS_SNS_Topic: 'AWS_SNS_Topic',
  AWS_Athena_Data_Cata: 'AWS_Athena_Data_Cata',
  AWS_Cognito_Identity: 'AWS_Cognito_Identity',
  AWS_Glue_Job: 'AWS_Glue_Job',
  AWS_Redshift_Cluster: 'AWS_Redshift_Cluster',
  AWS_GuardDuty_Detect: 'AWS_GuardDuty_Detect',
  AWS_DYNAMO_DB: 'AWS_DYNAMO_DB',
  AWS_Athena_Data_Catalog: 'AWS_Athena_Data_Catalog',
  AWS_Cognito_Identity_Pool: 'AWS_Cognito_Identity_Pool',
  AWS_Cloudwatch_Metric_Stream: 'AWS_Cloudwatch_Metric_Stream',
  AWS_GuardDuty_Detector: 'AWS_GuardDuty_Detector'
}

export const getNodeName = (node) => {
  if (node?.Properties?.Label === 'Project') {
    return node?.Properties?.ResourceName
  } else if (node?.RefKind === 'GcpResource' || node?.RefKind === 'IamAction') {
    return node?.Properties?.ResourceName || node?.Name
  } else if (node?.RefKind === 'IamServiceAccount') {
    return node?.Properties?.DisplayName
  } else if (node?.RefKind === 'AccessKey') {
    const match = node?.Name?.match(/serviceAccounts\/(.*?)\/keys/)
    const result = match ? match[1] : node.Name
    return result
  } else {
    return node.Name || node.RefKind
  }
}

export const getAWSResourceIcon = (name, type) => {
  if (AwsRsrcType[name]) {
    return 'All_Resource'
  } else {
    return type
  }
}

export const getGCPResourceIcon = (node) => {
  switch (node?.Properties?.Label) {
    case 'Project':
      return 'GCP_PROJECT'
    case 'Compute Instance':
      return 'GCP_COMPUTE_INSTANCE'
    case 'Folder|0':
    case 'Folder|1':
    case 'Folder|2':
    case 'Folder|3':
    case 'Folder|4':
    case 'Folder|5':
    case 'Folder|6':
      return 'GCP_GOOGLE_FOLDER'
    case 'Organization':
      return 'GCP_ORG'
    case 'GcpResource':
      return 'GCP'
    default:
      return 'GCP'
  }
}

export const getGCPResourceName = (node) => {
  debugger
  switch (node?.Properties?.Label) {
    case 'Project':
      return 'Project'
    case 'Compute Instance':
      return 'Compute Instance'
    case 'Folder|0':
    case 'Folder|1':
    case 'Folder|2':
    case 'Folder|3':
    case 'Folder|4':
    case 'Folder|5':
    case 'Folder|6':
      return 'Folder'
    case 'Organization':
      return 'Organization'
    default:
      break
  }
}

export const getNodeType = (node, type) => {
  switch (node.RefKind) {
    case 'Account':
      return type
    case 'IamUser':
      return 'USER'
    case 'IamGroup':
      return 'USERGROUPS'
    case 'IamRole':
      return 'ROLE'
    case 'IamFederatedUser':
      return 'FEDERATED_USER'
    case 'AccessKey':
      return 'ACCESS_KEY'
    case 'IamPolicy':
      return 'POLICY'
    case 'ExpandableNode':
      return getAWSResourceIcon(node.Name, type)
    case 'AwsResource':
      return type
    case 'IamAction':
      return 'GCP'
    case 'GcpResource':
      return getGCPResourceIcon(node)
    case 'IamServiceAccount':
      return 'SERVICEACCOUNT'
    default:
      return 'All_Resource'
  }
}

export const getNodeTypeName = (node, kind) => {
  switch (node.RefKind) {
    case 'DummyUser':
      return 'User'
    case 'DummyCloud':
      return 'Account'
    case 'Account':
      return 'Account'
    case 'IamUser':
      return 'User'
    case 'IamGroup':
      return 'Group'
    case 'IamFederatedUser':
      return 'Federated User'
    case 'IamRole':
      return 'Role'
    case 'IamServiceAccount':
      return 'Service Account'
    case 'IamAction':
      return 'Role'
    case 'IamPolicy':
      return 'Policy'
    case 'AccessKey':
      if (kind === 'IamServiceAccount') {
        return 'Service Account Key'
      } else {
        return 'Access Key'
      }
    case 'Resources':
      return 'Resources'
    case 'ExpandableNode':
      return 'Expandable Node'
    case 'AwsResource':
      return 'Aws Resource'
    case 'GcpResource':
      return getGCPResourceName(node)
    case 'Organization':
      return 'Organization'
    case 'Folder|0':
      return 'Folder'
    case 'Project':
      return 'Project'
    case 'Compute Instance':
      return 'Compute Instance'
    default:
      return null
  }
}

export const generateUniqueKey = (frefID, frefKind, trefID, trefKind) =>
  `${frefID}-${frefKind}-${trefID}-${trefKind}`

// Generate a unique key based on RefID and RefKind
export const getNodeKey = (refID, refKind) => `${refID}-${refKind}`
