import { useUser } from 'Core/Hooks/useUser'
import { enqueueNotification } from 'Utils/Helpers'
import {
  EditableLabelContent,
  LabelContent,
  LoadingFeedback,
  SwitchBoxLabelContent
} from 'V2Components'
import { RDPStorageConfigModal } from 'features/sessions'
import { updateTenantProfile } from 'features/settings'
import { createDataSelectorHook, useReduxFetch } from 'infra/redux'
import { updateSliceData } from 'infra/redux/sliceHandlers'
import { Button, PasswordInput, SelectDropDown, TextArea, Typography } from 'procyon-ui'
import React, { useState } from 'react'
import { ApiKeys } from './components/ApiKeys'

const useSlices = createDataSelectorHook(['tenantprofiles'])

function TenantInformation({}) {
  const [editMode, setEditMode] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [showRDPConfigModal, setShowRDPConfigModal] = useState(false)

  const { user } = useUser()

  const { slices } = useSlices()

  const tenantProfile = slices.tenantprofiles.find(
    (e) => e.ObjectMeta.Name === user.ObjectMeta.Tenant
  )

  const config = getConfig(tenantProfile)

  const [iDPType, setIDPType] = useState('')
  const [tenantForm, setTenantForm] = useState({
    InlineMode: false,
    NoDnsProxyMode: false,
    PrivateProxyOnlyMode: false,
    TunnelLocalAddr: '',
    TunnelRemoteAddr: '',
    TunnelSubnet: '',
    TunnelListenerCidr: '',
    Mandatory: false,
    AuthCheckInterval: '',
    AllowedFailedAttempts: '',
    FailedAttemptsWindow: '',
    AuthType: '1',
    EnableMFA: false,
    EnableSessionLogging: false,
    EnableSessionOutputLogging: false,
    loaded: false,
    AgentLess: false,
    IdpType: '',
    ClientID: '',
    ClientSecret: '',
    OidcDomain: '',
    SSOURL: '',
    IssuerURL: '',
    Metadata: '',
    EntityID: '',
    LoginMode: '' // mix || agentless
  })

  const handleTenantFormChange = (v = {}) => {
    setIDPType(v.IdpType)
    setTenantForm((s) => ({ ...s, ...v }))
  }

  if (config && !tenantForm.loaded) {
    setTenantForm({ ...config, loaded: true })
  }

  const handleCancel = () => {
    setEditMode((s) => !s)
    setTenantForm({ ...config, loaded: true })
  }

  const handleSubmit = async () => {
    setIsLoading(true)
    const tp = await handleTenantProfileUpdate(tenantForm, tenantProfile)
    enqueueNotification('Tenant Information updated successfully!', 'info')
    setEditMode(false)
    setIsLoading(false)
    updateSliceData(tp)
  }
  useReduxFetch(['tenantprofiles'])
  return (
    <div>
      <div className='flex justify-between items-center mb-8'>
        <Typography variant='h4-regular'>Tenant Information</Typography>
        {!editMode && (
          <Button
            disabled={!tenantProfile} // dont enable edit when tenant profile is not loaded
            onClick={() => setEditMode((s) => !s)}
            variant='gray'
          >
            Edit
          </Button>
        )}
        {editMode && (
          <div className='flex gap-4'>
            <Button onClick={handleCancel} variant='grayRed'>
              Cancel
            </Button>
            <Button onClick={handleSubmit} variant='primary'>
              Save Changes
            </Button>
          </div>
        )}
      </div>
      <LabelContent content={user.ObjectMeta.Tenant} title='Tenant' />
      <SwitchBoxLabelContent
        editMode={editMode}
        label='Session Logging'
        onChange={(v) => handleTenantFormChange({ EnableSessionLogging: v })}
        value={tenantForm.EnableSessionLogging}
      />
      {tenantForm.EnableSessionLogging && (
        <SwitchBoxLabelContent
          editMode={editMode}
          label='Server Output Logging'
          onChange={(v) => handleTenantFormChange({ EnableSessionOutputLogging: v })}
          value={tenantForm.EnableSessionOutputLogging}
        />
      )}
      <SwitchBoxLabelContent
        editMode={editMode}
        label='Private Proxy Only Mode'
        onChange={(v) => handleTenantFormChange({ PrivateProxyOnlyMode: v })}
        value={tenantForm.PrivateProxyOnlyMode}
      />
      <SwitchBoxLabelContent
        editMode={editMode}
        label='AgentLess'
        onChange={(v) => handleTenantFormChange({ AgentLess: v })}
        value={tenantForm.AgentLess}
      />
      <SwitchBoxLabelContent
        editMode={editMode}
        label='MFA'
        onChange={(v) => handleTenantFormChange({ EnableMFA: v })}
        value={tenantForm.EnableMFA}
      />

      {tenantForm.EnableMFA && (
        <div>
          <SwitchBoxLabelContent
            editMode={editMode}
            label='MFA Mandatory'
            onChange={(v) => handleTenantFormChange({ Mandatory: v })}
            value={tenantForm.Mandatory}
          />
          {/* <EditableLabelContent
            content={tenantForm.AllowedFailedAttempts}
            editMode={editMode}
            onChange={(e) => handleTenantFormChange({ AllowedFailedAttempts: e.target.value })}
            title='MFA Allowed Failed Attempts'
            value={tenantForm.AllowedFailedAttempts}
          />
          <EditableLabelContent
            content={tenantForm.AuthCheckInterval}
            editMode={editMode}
            onChange={(e) => handleTenantFormChange({ AuthCheckInterval: e.target.value })}
            title='MFA Authentication Interval'
            value={tenantForm.AuthCheckInterval}
          />
          <EditableLabelContent
            content={tenantForm.FailedAttemptsWindow}
            editMode={editMode}
            onChange={(e) => handleTenantFormChange({ FailedAttemptsWindow: e.target.value })}
            title='MFA Failed Attempts Window'
            value={tenantForm.FailedAttemptsWindow}
          /> */}
          {/* <LabelContent
            title='MFA Auth Type'
            content={
              <SelectDropDown
                showHelpText={false}
                disabled={!editMode}
                width='280px'
                menuItems={[
                  {
                    label: 'Default Mode',
                    value: '0'
                  },
                  {
                    label: 'Proximity Mode',
                    value: '1'
                  }
                ]}
                onChange={(e) => handleTenantFormChange({ AuthType: e.target.value })}
                value={tenantForm.AuthType}
              />
            }
          /> */}
        </div>
      )}

      {tenantForm.AgentLess && (
        <div>
          {editMode && (
            <>
              <LabelContent
                title='Login Mode'
                content={
                  <SelectDropDown
                    showHelpText={false}
                    disabled={!editMode}
                    width='280px'
                    menuItems={[
                      {
                        label: 'Restricted',
                        value: 'restricted'
                      },
                      {
                        label: 'AllPermitted',
                        value: 'allpermitted'
                      }
                    ]}
                    onChange={(e) => handleTenantFormChange({ LoginMode: e.target.value })}
                    value={tenantForm.LoginMode}
                  />
                }
              />

              <LabelContent
                title='IdpType'
                content={
                  <SelectDropDown
                    showHelpText={false}
                    disabled={!editMode}
                    width='280px'
                    menuItems={[
                      {
                        label: 'OIDC',
                        value: 'OIDC'
                      },
                      {
                        label: 'SAML',
                        value: 'SAML'
                      },
                      {
                        label: 'OKTA',
                        value: 'OKTA'
                      },
                      {
                        label: 'Google',
                        value: 'Google'
                      },
                      {
                        label: 'Entra',
                        value: 'Entra'
                      },
                      {
                        label: 'OneLogin',
                        value: 'OneLogin'
                      },
                      {
                        label: 'Ping',
                        value: 'Ping'
                      }
                    ]}
                    onChange={(e) => handleTenantFormChange({ IdpType: e.target.value })}
                    value={tenantForm.IdpType}
                  />
                }
              />

              {['OIDC', 'OKTA', 'Google', 'Entra', 'OneLogin'].includes(
                iDPType || tenantForm.IdpType
              ) ? (
                <>
                  <EditableLabelContent
                    content={tenantForm.ClientID}
                    editMode={editMode}
                    onChange={(e) => handleTenantFormChange({ ClientID: e.target.value })}
                    title='ClientID'
                    value={tenantForm.ClientID}
                  />
                  <LabelContent
                    title={'ClientSecret'}
                    content={
                      <>
                        <PasswordInput
                          iconPosition='start'
                          onChange={(e) => handleTenantFormChange({ ClientSecret: e.target.value })}
                          placeholder='*******************'
                          value={tenantForm.ClientSecret}
                          sx={{
                            width: '280px'
                          }}
                        />
                      </>
                    }
                  />

                  <EditableLabelContent
                    content={tenantForm.OidcDomain}
                    editMode={editMode}
                    onChange={(e) => handleTenantFormChange({ OidcDomain: e.target.value })}
                    title='OidcDomain'
                    value={tenantForm.OidcDomain}
                  />
                </>
              ) : (
                <>
                  <EditableLabelContent
                    content={tenantForm.SSOURL}
                    editMode={editMode}
                    onChange={(e) => handleTenantFormChange({ SSOURL: e.target.value })}
                    title='SSO URL'
                    value={tenantForm.SSOURL}
                  />
                  <EditableLabelContent
                    content={tenantForm.IssuerURL}
                    editMode={editMode}
                    onChange={(e) => handleTenantFormChange({ IssuerURL: e.target.value })}
                    title='Issuer URL'
                    value={tenantForm.IssuerURL}
                  />

                  <EditableLabelContent
                    content={tenantForm.EntityID}
                    editMode={editMode}
                    onChange={(e) => handleTenantFormChange({ EntityID: e.target.value })}
                    title='Entity Id'
                    value={tenantForm.EntityID}
                  />

                  <LabelContent
                    title={'Meta Data'}
                    content={
                      <>
                        <TextArea
                          cols={33}
                          rows={7}
                          onChange={(e) => handleTenantFormChange({ Metadata: e.target.value })}
                        />
                      </>
                    }
                  />
                </>
              )}
            </>
          )}
        </div>
      )}

      <EditableLabelContent
        content={tenantForm.TunnelSubnet}
        editMode={editMode}
        onChange={(e) => handleTenantFormChange({ TunnelSubnet: e.target.value })}
        title='Tunnel Subnet'
        value={tenantForm.TunnelSubnet}
      />
      <EditableLabelContent
        content={tenantForm.TunnelListenerCidr}
        editMode={editMode}
        onChange={(e) => handleTenantFormChange({ TunnelListenerCidr: e.target.value })}
        title='Tunnel Listner CIDR'
        value={tenantForm.TunnelListenerCidr}
      />
      <EditableLabelContent
        content={tenantForm.TunnelLocalAddr}
        editMode={editMode}
        onChange={(e) => handleTenantFormChange({ TunnelLocalAddr: e.target.value })}
        title='Tunnel Local Address'
        value={tenantForm.TunnelLocalAddr}
      />
      <EditableLabelContent
        content={tenantForm.TunnelRemoteAddr}
        editMode={editMode}
        onChange={(e) => handleTenantFormChange({ TunnelRemoteAddr: e.target.value })}
        title='Tunnel Remote Address'
        value={tenantForm.TunnelRemoteAddr}
      />

      <Typography className='my-8' variant='h4-regular'>
        For Developers
      </Typography>
      <ApiKeys />
      {showRDPConfigModal && (
        <RDPStorageConfigModal onCancel={() => setShowRDPConfigModal(false)} />
      )}
      <LoadingFeedback message='Updating Tenant Info' caption='Please wait..' loading={isLoading} />
    </div>
  )
}

const getConfig = (tenantProfiles) => {
  if (!tenantProfiles?.Spec) return null
  const {
    TunnelConfig,
    MFAttributes,
    EnableMFA,
    EnableSessionLogging,
    EnableSessionOutputLogging,
    AgentLess,
    IdpType,
    OidcAttributes,
    SamlAttributes,
    LoginMode
  } = tenantProfiles.Spec
  const {
    InlineMode,
    NoDnsProxyMode,
    PrivateProxyOnlyMode,
    TunnelLocalAddr,
    TunnelRemoteAddr,
    TunnelSubnet,
    TunnelListenerCidr
  } = TunnelConfig
  const { Mandatory, AuthCheckInterval, AllowedFailedAttempts, FailedAttemptsWindow, AuthType } =
    MFAttributes
  const { ClientID, ClientSecret, OidcDomain } = OidcAttributes
  const { SSOURL, IssuerURL, Metadata, EntityID } = SamlAttributes

  return {
    InlineMode,
    NoDnsProxyMode,
    PrivateProxyOnlyMode,
    TunnelLocalAddr,
    TunnelRemoteAddr,
    TunnelSubnet,
    TunnelListenerCidr,
    Mandatory,
    AuthCheckInterval,
    AllowedFailedAttempts,
    FailedAttemptsWindow,
    AuthType,
    EnableMFA,
    EnableSessionLogging,
    EnableSessionOutputLogging,
    AgentLess,
    IdpType,
    ClientID,
    ClientSecret,
    OidcDomain,
    SSOURL,
    IssuerURL,
    Metadata,
    EntityID,
    LoginMode
  }
}

const handleTenantProfileUpdate = async (data, obj) => {
  const {
    InlineMode,
    NoDnsProxyMode,
    PrivateProxyOnlyMode,
    TunnelLocalAddr,
    TunnelRemoteAddr,
    TunnelSubnet,
    TunnelListenerCidr,
    Mandatory,
    AuthCheckInterval,
    AllowedFailedAttempts,
    FailedAttemptsWindow,
    AuthType,
    EnableMFA,
    EnableSessionLogging,
    EnableSessionOutputLogging,
    AgentLess,
    IdpType,
    ClientID,
    ClientSecret,
    OidcDomain,
    SSOURL,
    IssuerURL,
    Metadata,
    EntityID,
    LoginMode
  } = data

  const MFAttributes = {
    Mandatory,
    AuthCheckInterval,
    AllowedFailedAttempts,
    FailedAttemptsWindow,
    AuthType
  }

  const OidcAttributes = {
    ClientID,
    ClientSecret,
    OidcDomain
  }

  const SamlAttributes = {
    SSOURL,
    IssuerURL,
    Metadata,
    EntityID
  }

  const TunnelConfig = {
    InlineMode,
    NoDnsProxyMode,
    PrivateProxyOnlyMode,
    TunnelLocalAddr,
    TunnelRemoteAddr,
    TunnelSubnet,
    TunnelListenerCidr
  }

  const Spec = {
    ...obj.Spec,
    TunnelConfig,
    MFAttributes,
    EnableMFA,
    EnableSessionLogging,
    EnableSessionOutputLogging,
    AgentLess,
    IdpType,
    OidcAttributes,
    SamlAttributes,
    LoginMode
  }
  const payload = structuredClone(obj)
  payload.Spec = Spec

  return await updateTenantProfile(payload)
}

export { TenantInformation }
