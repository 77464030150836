import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { LoadingFeedback } from 'V2Components'
import { reduxApiClient } from 'infra'
import { createDataSelectorHook } from 'infra/redux'
import _ from 'lodash'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router'
const CloudDetailsContext = createContext({ cloud: null, isPrivate: false })

const useSlices = createDataSelectorHook(['accountList', 'privateAccounts'])

export const CloudDetailsProvider = ({ children }) => {
  const { slices } = useSlices()

  //@ts-ignore
  const { cloudName, cloudType: ct } = useParams()

  const cloudType = ct?.toLowerCase() || ''

  const list = cloudType === 'private' ? slices.privateAccounts : slices.accountList

  const [cloudObject, setCloudObject] = useState(() => {
    const c = _.find(list, { ObjectMeta: { Name: cloudName } })
    return c || null
  })

  const c = _.find(list, { ObjectMeta: { Name: cloudName } })

  if (c && c !== cloudObject) setCloudObject(c)
  console.log('[cloud](c):', cloudType, cloudObject)
  useEffect(() => {
    if (cloudType === 'private')
      reduxApiClient('accounts').getByDataParams({ 'ObjectMeta.Name': cloudName })
  }, [])

  return (
    <CloudDetailsContext.Provider
      value={{ cloud: cloudObject, isPrivate: cloudType === 'private' }}
    >
      {!cloudObject && (
        <LoadingFeedback shrinked shadow={false} loading message='Fetching Cloud Object' />
      )}
      {cloudObject && children}
    </CloudDetailsContext.Provider>
  )
}

export const useCloudDetailsProvider = () => useContext(CloudDetailsContext)
