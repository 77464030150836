import { useProxyControllerWizardContext } from 'features/proxyControllers'
import { createRsrcKey } from 'features/resources'
import { createDataSelectorHook } from 'infra/redux'
import { SelectDropDown, TextInput, Typography } from 'procyon-ui'
import React from 'react'

const useSlices = createDataSelectorHook([
  'tagPolicys',
  'accountList',
  'serverControllers',
  'medusaNodes'
])

const ConfigStep = () => {
  const {
    controllerName,
    updateConfig,
    selectorType,
    selectedNodeIDs,
    label,
    isEditMode,
    nodeTypes
  } = useProxyControllerWizardContext()

  const { slices } = useSlices()

  const getLabelsOptions = () => {
    const menuItems = [{ label: 'Select an option', value: '' }]
    const labelKey = label[0]

    slices.tagPolicys.forEach((tag) => {
      const labelValue = tag.Tags.Map[labelKey] || ''
      if (!labelValue) return
      const splits = labelValue.split(',')
      splits.map((s) => {
        const str = `${labelKey}:${s}`
        if (menuItems.find((e) => e.value == str)) return
        menuItems.push({
          label: s,
          value: str
        })
      })
    })

    return menuItems
  }

  const getNodeTypeOptions = () => {
    const menuItems = [{ label: 'Select an option', value: '' }]

    slices.medusaNodes.forEach((node) => {
      const type = node.NodeType

      if (menuItems.find((e) => e.value == type)) return

      menuItems.push({
        label: type,
        value: type
      })
    })

    return menuItems
  }

  return (
    <div className=''>
      <TextInput
        disabled={isEditMode}
        sx={{ width: '100%' }}
        label='Controller Name'
        value={controllerName}
        onChange={(e) => updateConfig({ controllerName: e.target.value })}
      />
      <div className='mt-4'>
        <Typography variant='buttonLabel-regular' className='mb-1'>
          Selector Type
        </Typography>
        <div className='flex gap-2 items-center'>
          <SelectDropDown
            sx={{ width: '146px' }}
            menuItems={[
              {
                label: 'Nodes',
                selected: true,
                value: 'Nodes'
              },
              {
                label: 'Node Types',
                value: 'NodeTypes'
              },
              {
                label: 'Labels',
                value: 'Labels'
              }
            ]}
            onChange={(e) => updateConfig({ selectorType: e.target.value })}
            value={selectorType}
          />
          {selectorType === 'Nodes' && (
            <SelectDropDown
              multiple
              sx={{ minWidth: '146px' }}
              //@ts-ignore
              menuItems={slices.medusaNodes.map((node) => {
                const key = createRsrcKey(node)
                return {
                  label: node.ClusterID,
                  value: node.NodeID
                }
              })}
              onChange={(e) => {
                updateConfig({ selectedNodeIDs: e.target.value })
              }}
              value={selectedNodeIDs}
            />
          )}
          {selectorType === 'NodeTypes' && (
            <>
              <SelectDropDown
                multiple
                sx={{ width: '150px' }}
                menuItems={getNodeTypeOptions()}
                onChange={(e) => {
                  if (e.target.value.includes('')) return
                  updateConfig({ nodeTypes: e.target.value })
                }}
                value={nodeTypes}
              />
            </>
          )}
          {selectorType === 'Labels' && (
            <>
              <SelectDropDown
                sx={{ width: '150px' }}
                menuItems={TAG_KEYS}
                onChange={(e) => {
                  updateConfig({ label: [e.target.value] })
                }}
                value={label[0]}
              />
              <SelectDropDown
                multiple
                sx={{ width: '150px' }}
                menuItems={getLabelsOptions()}
                onChange={(e) => {
                  updateConfig({ label: e.target.value })
                }}
                value={label}
              />
            </>
          )}
        </div>
      </div>
    </div>
  )
}

const TAG_KEYS = [
  {
    label: 'Proxy Group',
    value: 'proxygrp'
  }
]

export { ConfigStep }
