import { faHammer } from '@fortawesome/pro-duotone-svg-icons'
import { faWarning } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isValidAWSARN, useAddAccountWizardContext } from 'features/clouds'
import _ from 'lodash'
import { Button, Label, Tooltip, Typography } from 'procyon-ui'
import React from 'react'

const FooterComponent = ({
  errors = '',
  activeStep, //from wizard
  setActiveStep, //from wizard
  totalSteps, //from wizard
  onVerifyAndAddBtnClick = null,
  cloudType = 'AWS',
  ...props
}) => {
  const errs = props.errors || errors

  const { awsSpec, gcpSpec, azureSpec, isEditMode, privateSpec } = useAddAccountWizardContext()

  const getAWSFormErrors = () => {
    const indexErrors = []
    const { arn, externalID, orgAccount, ouID } = awsSpec
    if (orgAccount && !ouID.trim()) indexErrors[3] = 'OUID is required.'
    else if (!isValidAWSARN(arn)) indexErrors[3] = 'Invalid ARN'
    else if (!arn.trim()) indexErrors[3] = 'ARN is required.'
    if (!externalID.trim()) indexErrors[2] = 'Invalid External ID'
    return indexErrors
  }

  const getGCPErrors = () => {
    const indexErrors = []
    const isWFFedAccount = gcpSpec.cloudIDType === 'workforce-fed'

    if (!gcpSpec.jsonCredentials.trim()) {
      const i = isWFFedAccount ? 4 : 3
      indexErrors[isEditMode ? i - 1 : i] = 'GCP JSON Credentials is required.'
    }
    if (isWFFedAccount && !gcpSpec.primaryDomain.trim()) {
      indexErrors[isEditMode ? 1 : 2] = 'Provider ID is required.'
    }
    if (!indexErrors[4]) {
      try {
        const obj = JSON.parse(gcpSpec.jsonCredentials)
        if (_.isEmpty(obj)) {
          indexErrors[isWFFedAccount ? 4 : 3] = 'GCP JSON Credentials is required.'
        }
      } catch (error) {
        indexErrors[isWFFedAccount ? 4 : 3] = 'Invalid JSON Format.'
      }
    }
    return indexErrors
  }

  const getAZUREErrors = () => {
    const indexErrors = []
    const CredentialsIndex = azureSpec.isSAMLConfigurationAllowed ? 3 : 4
    const { tenantID, appID, appKey } = azureSpec
    if (!tenantID.trim()) indexErrors[CredentialsIndex] = 'Tenant ID is required.'
    else if (!appID.trim()) indexErrors[CredentialsIndex] = 'Procyon App ID is required.'
    else if (!appKey.trim()) indexErrors[CredentialsIndex] = 'Procyon App Key is required.'
    return indexErrors
  }

  const getPrivateErrors = () => {
    try {
      const validURL = new URL(privateSpec.link)
      return []
    } catch (error) {
      return [,'Invalid URL']
    }
  }

  /**
   * The function returns array of errors, such that the index of the array is the error of the step
   * Example, returns: ['Name not found', 'Invalid input']
   * This means, at step 1. the error is 'Name not Found' and so on.
   * @returns {string[]}
   */
  const getIndexedErrors = () => {
    let errors = []

    if (cloudType === 'AWS') errors = getAWSFormErrors()
    if (cloudType === 'GCP') errors = getGCPErrors()
    if (cloudType === 'AZURE') errors = getAZUREErrors()
    if (cloudType === 'PRIVATE') errors = getPrivateErrors()

    return errors
  }

  const getFooterErrorMessage = () => {
    const isLastStep = activeStep === totalSteps - 1
    if (errs) return errs
    const indexedErrors = getIndexedErrors()
    // The Summary step
    if (isLastStep) {
      // Return first error that exists
      return indexedErrors.find((e) => e?.length)
    }

    // return the error of that step
    return indexedErrors[activeStep]
  }

  const errorMessage = getFooterErrorMessage()
  const erroredStep = errors ? 0 : getIndexedErrors().findIndex((e) => e?.length)

  const showFixErrorBtn = erroredStep !== activeStep
  const isLastStep = activeStep === totalSteps - 1

  const handleFixErrorClick = () => {
    setActiveStep(erroredStep)
  }

  return (
    <div className='flex justify-end gap-4 items-center mt-4'>
      {errorMessage && (
        <Label
          text={
            <div className='flex gap-2 items-center'>
              <Typography
                className='!text-yellow-500 flex gap-2 items-center'
                variant='caption-regular'
              >
                <FontAwesomeIcon icon={faWarning} />
                {errorMessage}
              </Typography>
              {showFixErrorBtn && (
                <Tooltip title='Go and Fix' arrow placement='top'>
                  <button
                    onClick={handleFixErrorClick}
                    className='rounded px-[6px] py-1 text-xs hover:bg-yellow-500 bg-yellow-400 text-white'
                  >
                    <FontAwesomeIcon icon={faHammer} />
                  </button>
                </Tooltip>
              )}
            </div>
          }
          variant='warning'
        />
      )}
      {activeStep > 0 && (
        <Button onClick={() => setActiveStep((s) => s - 1)} variant='grayBlue'>
          Previous
        </Button>
      )}
      {isLastStep && (
        <Button
          disabled={errorMessage}
          onClick={() => onVerifyAndAddBtnClick?.()}
          variant='primary'
        >
          Verify and {isEditMode ? 'Update' : 'Add'}
        </Button>
      )}
      {!isLastStep && (
        <Button
          disabled={errorMessage}
          onClick={() => setActiveStep((s) => s + 1)}
          variant='primary'
        >
          Next
        </Button>
      )}
    </div>
  )
}

export { FooterComponent }
