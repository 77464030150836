import { TabGroup, TextInput, ToggleCloudControl } from 'procyon-ui'
import React, { useEffect, useState } from 'react'
import { UsersTable, GroupsTable, RolesTable, ServiceAccountTable } from './Components/Tabs'
import { FederatedUserTable } from './Components/Tabs/FederatedUserTable'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

const Remediations = () => {
  const [searchQuery, setSearchQuery] = useState('')
  const IsAws = true
  const [activeTab, setActiveTab] = useState(parseInt(localStorage.getItem('activeTab')) || 0)
  const [activeCloudType, setActiveCloudType] = useState('AWS')

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value)
  }

  const handleCloudToggle = (v) => setActiveCloudType(v)

  useEffect(() => {
    const storedTabIndex = parseInt(localStorage.getItem('activeTab'))
    if (!isNaN(storedTabIndex)) {
      setActiveTab(storedTabIndex)
    }
  }, [])

  const handleTabChange = (v) => {
    setSearchQuery('')
    setActiveTab(v)
    localStorage.setItem('activeTab', v)
  }

  return (
    <>
      <div className=''>
        {/* <div className='mb-3'>
          <ToggleCloudControl
            ariaLabel='cloud-select'
            onChange={(_, v) => handleCloudToggle(v)}
            options={['AWS', 'GCP']}
            value={activeCloudType}
          />
        </div> */}
        <div className='absolute right-[20px] w-[280px] z-10'>
          <TextInput
            style={{
              width: '280px'
            }}
            icon={faSearch}
            iconPosition='start'
            value={searchQuery}
            onChange={handleSearchChange}
            onClickEndIcon={function noRefCheck() {}}
            placeholder='Search'
          />
        </div>

        <TabGroup
          tabs={[
            {
              label: 'Users',
              tabContent: <UsersTable searchQuery={searchQuery} />
            },
            {
              label: 'Federated User',
              tabContent: <FederatedUserTable searchQuery={searchQuery} />
            },
            {
              label: 'Roles',
              tabContent: <RolesTable searchQuery={searchQuery} />
            },
            {
              label: 'Groups',
              tabContent: <GroupsTable searchQuery={searchQuery} />
            },
            {
              label: 'Service Account',
              tabContent: <ServiceAccountTable searchQuery={searchQuery} />
            }
          ]}
          controllable={true}
          activeIndex={activeTab}
          onChangeTab={(_, v) => {
            handleTabChange(v)
          }}
        />
      </div>
    </>
  )
}

export { Remediations }
