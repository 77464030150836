import { Tooltip } from 'procyon-ui'
import React from 'react'

const COLORS_MAP = {
  normal: '#81c8f4',
  warning: '#f4ce81',
  danger: '#f48181',
  bg: '#d2d3d4'
}

const ProgressBar = ({ usedPercent = 0, barHeight = '0.25rem' }) => {

	const getBGColor = () => {
		if (usedPercent <= 60) return COLORS_MAP.normal
		if (usedPercent > 60 && usedPercent <= 80) return COLORS_MAP.warning
		return COLORS_MAP.danger
	}

  return (
    <Tooltip arrow title={`Used: ${usedPercent}%, Available: ${100 - usedPercent}%`}>
      <div className='rounded'>
        <div className='flex'>
          <div
						style={{
							backgroundColor: getBGColor(),
              height: barHeight,
              width: `${usedPercent}%`,
              borderTopLeftRadius: '4px',
              borderBottomLeftRadius: '4px'
            }}
          />
          <div
            className=' bg-[#d2d3d4]'
            style={{
              height: barHeight,
              width: `${100 - usedPercent}%`,
              borderTopRightRadius: '4px',
              borderBottomRightRadius: '4px'
            }}
          />
        </div>
      </div>
    </Tooltip>
  )
}

export { ProgressBar }
