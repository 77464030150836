import { getResourceName, getRsrcError, getRsrcIcon } from 'features/resources'
import { createDataSelectorHook } from 'infra/redux'
import { CloudCard } from 'procyon-ui'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { useClouds } from '../../Clouds.utils'
import { ErrorModal } from 'features/policy'

const useSlices = createDataSelectorHook(['privateAccounts'])

const PrivateAccounts = ({ searchKey }) => {
  const [errorMessage, setErrorMessage] = useState({ message: '', url: '' })

  const { cloudType } = useClouds()

  const { slices } = useSlices()

  const getFilteredAccounts = () => {
    return slices.privateAccounts.filter((e) => {
      return e.ObjectMeta.Name.toLowerCase().includes(searchKey)
    })
  }

  const history = useHistory()

  const filteredAccounts = getFilteredAccounts()

  return (
    <div>
      <div className='grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 gap-8 mt-4'>
        {filteredAccounts.map((a) => {
          const namespace = a.ObjectMeta.Namespace
          const Icon = getRsrcIcon(a)

          const error = getRsrcError(a)
          const url = `/admin/clouds/${cloudType.toLowerCase()}/${encodeURIComponent(
            a.ObjectMeta.Name
          )}`

          return (
            <CloudCard
              topRightLabel={namespace}
              topRightLabelVariant={namespace === 'default' ? 'grayBlue' : 'gray'}
              width='100%'
              // @ts-ignore
              caption={''}
              icon={<Icon size='2x' />}
              menuItems={[]}
              showResourcesButton={false}
              name={getResourceName(a)}
              onClickMoreInfo={() => history.push(url)}
              onClickResources={function noRefCheck() {}}
              // resourcesAmount={_meta.count}
              showErrorButton={!!error}
              onErrorClick={() =>
                setErrorMessage({
                  message: error,
                  url
                })
              }
            />
          )
        })}
      </div>
      {errorMessage.message && (
        <ErrorModal
          message={errorMessage.message}
          onClose={() => setErrorMessage({ message: '', url: '' })}
          showModal
          title='Account Error'
          onViewDetailsClick={() => history.push(errorMessage.url)}
        />
      )}
    </div>
  )
}

export { PrivateAccounts }
