import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import { SearchInput } from 'V2Components'
import { Button, Typography } from 'procyon-ui'
import React from 'react'

function Header({ searchKey, setSearchKey, onAddClick }) {
  return (
    <div className='flex justify-between items-center'>
      <Typography variant='h2'>User Groups</Typography>
      <div className='flex gap-4 mt-3'>
        <SearchInput sx={{ width: '303px' }} searchKey={searchKey} onChange={setSearchKey} />
        <Button id='groups-add-btn' onClick={onAddClick} icon={faPlus} size='md' variant='primary'>
          Add
        </Button>
      </div>
    </div>
  )
}

export { Header }
