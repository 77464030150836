import { Box, Tooltip } from '@material-ui/core'
import { Edit } from '@material-ui/icons'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import ActionModal from 'Components/ActionModal/ActionModal'
import DockerIcon from 'Components/CustomIcons/Docker'
import React, { useState } from 'react'
import CommandForm from './Command'
import UIForm from './UI'

const FilterButtons = ({ filters, handleActiveButtonsChange, active }) => {
  return (
    <ToggleButtonGroup onChange={handleActiveButtonsChange} value={active} exclusive size='small'>
      {filters.map(({ name, label, Icon }) => (
        <ToggleButton key={name} value={name}>
          <Tooltip title={label}>{Icon && <Icon />}</Tooltip>
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  )
}
const CreateProxyModal = ({ open, handleClose }) => {
  const [formType, setFormType] = useState('ui')
  const filterUI = [
    { name: 'ui', label: 'UI Form', Icon: Edit },
    { name: 'command', label: 'Docker Command', Icon: DockerIcon }
  ]

  const toggleForm = (_, type) => {
    type && setFormType(type)
  }

  const filterComponent = (
    <Box display='flex' justifyContent='flex-end' mb={1}>
      <FilterButtons handleActiveButtonsChange={toggleForm} filters={filterUI} active={formType} />
    </Box>
  )

  const forms = {
    ui: <UIForm handleClose={handleClose} filterComponent={filterComponent} />,
    command: <CommandForm handleClose={handleClose} filterComponent={filterComponent} />
  }

  return (
    <ActionModal
      headerBorder
      height='37.375'
      width='50.688rem'
      Icon={false}
      showCloseButton
      headerPadding={{ pb: 0.1, pt: 0.2, pl: 1.5 }}
      Title='Gateway Settings'
      bodyPadding='1rem 1.5rem 1.5rem 1.5rem  '
      isOpen={open}
      closeLabel='CLOSE'
      handleClose={handleClose}
      //   customRender={<FilterButtons handleActiveButtonsChange={toggleForm} filters={filterUI} active={formType} />}
    >
      {forms[formType]}
    </ActionModal>
  )
}

export default CreateProxyModal
