import { faPen, faTrash } from '@fortawesome/pro-solid-svg-icons'
import { EntityInfoBar } from 'V2Components'
import _ from 'lodash'
import { IconButton, RadioInput, SimpleDropDown, Typography } from 'procyon-ui'
import React, { useState } from 'react'

function AddUsersQueue({
  users = [],
  onEditClick,
  onDeleteClick,
  editingUserID = null,
  onIsAdminChange,
  searchKey
}) {
  const [sortFilter, setSortFilter] = useState('firstname')

  const handleDeleteClick = (e) => onDeleteClick?.(e)
  const handleEditClick = (e) => onEditClick?.(e)

  const getFilteredItems = () => {
    const sortedItems = _.sortBy(users, (e) => e[SortByKeysMap[sortFilter]].toLowerCase())
    return sortedItems.filter((u) => {
      if (searchKey) {
        const searchString = `${u.email}${u.fName}${u.lName}`
        if (searchString.includes(searchKey)) return true
        return false
      } else return true
    })
  }

  return (
    <div>
      <div className='mt-5 flex justify-between items-center'>
        <Typography variant='body-regular'>{users.length} Users On Queue</Typography>
        <SimpleDropDown
          menuItems={SortMenuItems}
          onChange={(e) => setSortFilter(e.target.value)}
          value={sortFilter}
        />
      </div>
      <div className='mt-4'>
        {getFilteredItems().map((e) => (
          <div key={e.id} className='flex justify-between'>
            <EntityInfoBar
              endButton={false}
              kind='User'
              name={`${e.fName} ${e.lName}`}
              caption={e.email}
            />
            <div className='flex items-center gap-4'>
              <RadioInput
                id={`add-users-${e.id}-user-btn`}
                checked={!e.isAdmin}
                label='User'
                onChange={() => onIsAdminChange?.(e, false)}
              />
              <RadioInput
                id={`add-users-${e.id}-admin-btn`}
                checked={e.isAdmin}
                label='Admin'
                onChange={() => onIsAdminChange?.(e, true)}
              />
              <IconButton
                id={`add-users-${e.id}-edit-btn`}
                disabled={editingUserID === e.id} //* Disable edit for user who is being edited
                onClick={() => handleEditClick(e)}
                variant='grayBlue'
                icon={faPen}
              />
              <IconButton
                id={`add-users-${e.id}-remove-btn`}
                onClick={() => handleDeleteClick(e)}
                variant='grayRed'
                icon={faTrash}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

/**
 * Constants goes here
 */

const SortByKeysMap = {
  firstname: 'fName',
  lastname: 'lName'
}

const SortMenuItems = [
  {
    label: 'Sort: by First Name',
    value: 'firstname'
  },
  {
    label: 'Sort: by Last Name',
    value: 'lastname'
  }
]

export { AddUsersQueue }
