import { getUserInfo } from 'Utils/Helpers'
import store from 'infra/redux/store'
import _ from 'lodash'
const getKubeType = (type) => {
  if (type === 'POD') {
    return `KUBE_POD`
  }
  if (type === 'STS') {
    return `KUBE_STATEFULLSET`
  }
  if (type === 'DEPLOY') {
    return `KUBE_DEPLOYMENT`
  }
  if (type === 'SVC') {
    return `KUBE_SERVICES`
  }
}
/**
 * Returns true for server which are private
 * @param {object} server
 * @returns
 */
export const isPrivateServer = (server) =>
  _.get(server, 'Spec.Account.RefID', '0') === '0' &&
  _.get(server, 'Spec.Project.RefID', '0') === '0' &&
  _.get(server, 'ObjectMeta.Kind') === 'Server'

export const isPrivateResource = (rsrc) => {
  if (isPrivateServer(rsrc)) return true
  return rsrc.Spec.Type === 'Private'
}

/**
 * Returns true for dynamically created approles
 * Dynamic approles are approles created when a IAM Resource's policy is created,
 * either by approval request or directly policy
 * @param {object} approle
 * @returns
 */
export const isDynamicAppRole = (approle) => approle?.Spec.IAMCreated

export const getServerName = (server) => {
  if (!server) return ['']
  if (server.Spec.DisplayName) return server.Spec.DisplayName
  const tagName = server.ObjectMeta.CTags.Map?.Name || ''
  const name = tagName === 'aws:cloudformation:stack-name' ? 'ProcyonProxy' : tagName
  const separater = name ? '/' : ''
  const firstName = `${name || ''}${!name ? separater + server?.Spec?.InstanceId : ''}`
  return firstName
}

export const getAppRoleSignInURL = (approle) => {
  const user = getUserInfo()
  const url = approle?.Status.RoleParams?.Map?.['aws-role-signin-url']
  const { awsurl } = user

  if (awsurl && url) return url.replace('signin.aws.procyon.ai', `${awsurl}`)

  return url || ''
}

export const getRDPServerUsers = (rdp) => Object.keys(rdp?.Spec.Credentials.Map || {})

/**
 * Get access links of the rdp server with prefix
 * @param {any} rdp
 * @returns
 */

export const getRDPServerAccessLinksMap = (rdp) => {
  if (!rdp || rdp.ObjectMeta.Kind !== 'RDPServer') return {}
  const user = getUserInfo()
  const tenantProfiles = Object.values(store.getState().tenantprofiles.map)
  const isAgentlessMode = user.awsurl !== 'signin.aws.procyon.ai'
  const profile = tenantProfiles.find((e) => e.ObjectMeta.Name === user.ObjectMeta.Tenant)
  const isInterOpModeEnabled = profile?.Spec.TunnelConfig.NoDnsProxyMode
  const userMap = rdp?.Spec?.UserMap?.Map || {}
  const credentialsMap = rdp?.Spec?.Credentials?.Map || {}
  const matchedUser = Object.entries(userMap).filter(
    ([key, value]) => value.RefID === user.ObjectMeta.ID
  )
  const matchedKeys = new Set(matchedUser.map((item) => item[0]))
  const associatedCreds = Object.keys(credentialsMap).filter((key) => userMap.hasOwnProperty(key))
  const nonAssociatedCreds = Object.keys(credentialsMap).filter(
    (key) => !userMap.hasOwnProperty(key)
  )
  const matchedCredentials = associatedCreds.filter((key) => matchedKeys.has(key))
  const combinedArray = [...matchedCredentials, ...nonAssociatedCreds].filter(
    (value) => !value.startsWith('placeholder')
  )
  const org = user.org
  const controller = user.controller

  return Object.keys(rdp?.Spec?.Credentials?.Map).reduce((prev, key) => {
    const dnsName = rdp?.Spec?.DNSNames.Elems[0]
    if (key.includes('placeholder')) return prev
    const port = isInterOpModeEnabled || isAgentlessMode ? ':8643' : ''
    let updatedDnsName
    // multi org level url for agentless
    if (isAgentlessMode) {
      if (user.org !== 'default') {
        updatedDnsName = dnsName.replace(
          '.rdp.procyon.ai',
          `-rdp-${org}.applications.${controller}`
        )
      } else {
        updatedDnsName = dnsName.replace('.rdp.procyon.ai', '-rdp.applications.' + controller)
      }
    } else {
      updatedDnsName = dnsName
    }

    const modifiedLink = `https://${updatedDnsName}${port}/user/${key.toLowerCase()}`
    const links = { ...prev, [key]: modifiedLink }

    const filteredObject = Object.keys(links)
      .filter((key) => combinedArray.includes(key))
      .reduce((obj, key) => {
        obj[key] = links[key]
        return obj
      }, {})
    return filteredObject
  }, {})
}

/**
 * Get all access rules from policy issued to Targets
 * @param {*} policy
 * @returns All rules of targets in this policy
 */
export const getTargetsPolicyRules = (policy) => {
  const AssumeRole = policy.Spec.ActionMap.AssumeRole?.PolicyRule || []
  const SSH = policy.Spec.ActionMap.SSH?.PolicyRule || []
  const DBAccess = policy.Spec.ActionMap.DBAccess?.PolicyRule || []
  const RDPAccess = policy.Spec.ActionMap.RDPAccess?.PolicyRule || []
  const KafkaAccess = policy.Spec.ActionMap.KafkaAccess?.PolicyRule || []
  const KubeAccess = policy.Spec.ActionMap.KubeAccess?.PolicyRule || []
  return {
    AssumeRole,
    SSH,
    DBAccess,
    RDPAccess,
    KafkaAccess,
    KubeAccess
  }
}

export const getTargetsApprovalReqRules = (approvalReq) => {
  const map = {
    AssumeRole: [],
    SSH: [],
    DBAccess: [],
    RDPAccess: [],
    KafkaAccess: [],
    KubeAccess: []
  }

  if (!approvalReq) return map

  const rsrcs = approvalReq.Spec.Resources.Resource

  rsrcs.forEach((rsrcRule) => {
    const { Action } = rsrcRule
    const rules = map[Action] || []
    rules.push(rsrcRule)
  })

  return map
}

/**
 *
 * @param {*} cluster
 * @returns approles object with { [AppRole ID]: <Approle Name> }
 */
export const getK8ClusterAppRoleWithID = (cluster) => {
  const o = {}
  if (cluster.ObjectMeta.Kind !== 'KubeCluster') return
  const approles = cluster.Spec.IAMRoles.Map
  Object.entries(approles).forEach(([name, id]) => {
    o[id] = name
  })
  return o
}

/**
 * Returns if the RDP Server supports ADDC by checking username with user@domain format
 * @param {*} rdpserver
 * @returns {boolean}
 */
export const isRDPServerADDCSupport = (rdpserver) => {
  const usernames = Object.keys(rdpserver.Spec.Credentials.Map)
  return usernames.some((name) => name.includes('@'))
}

/**
 * Get all k8 namespace resources
 * @param {*} kns
 * @returns
 */
export const getKubeNamespaceRsrcs = (kns) => {
  const {
    Deployments: { Deployments },
    Pods: { Pods },
    ReplicaSets: { ReplicaSets },
    StatefulSets: { StatefulSets }
  } = kns.Spec

  return { Deployments, Pods, ReplicaSets, StatefulSets }
}

/**
 *
 * @param {string} principal
 * @returns {{
 * name: string
 * type: string
 * key: string
 * id: string
 * iconType: string
 * }[]}
 */
export const getK8RsrcsPropertiesFromPrincipal = (principal) => {
  if (!principal) return []
  const items = []

  const rsrcsStrs = principal.split(',')
  rsrcsStrs.forEach((rsrcStr) => {
    let [type, name] = rsrcStr.split(':')
    type = type.toUpperCase()

    const rsrcType = getKubeType(type)

    items.push({
      name,
      iconType: rsrcType,
      type: type,
      key: `${type}:${name}`,
      id: `${type}:${name}`
    })
  })

  return items
}
export * from './constants'
