import React, { useState } from 'react'
import { makeStyles, Box } from '@material-ui/core'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { getAccountImage } from 'Utils/PureHelperFuctions'

const useStyles = makeStyles((theme) => ({
  root: {}
}))

function BrandSelect ({ exclude = [], activeBrand = 'AWS', onChange }) {
  const classes = useStyles()
  const BUTTONS = ['AWS', 'GCP', 'AZURE'].filter((item) => !exclude.includes(item))
  const [activeButtons, setActiveButtons] = useState(activeBrand)

  const handleActiveButtonsChange = (event, buttons) => {
    if (!buttons) return
    setActiveButtons(buttons)
    onChange && onChange(buttons)
  }

  return (
    <Box className={classes.root}>
      <ToggleButtonGroup exclusive onChange={handleActiveButtonsChange} value={activeButtons} size='small'>
        {BUTTONS.map((button) => (
          <ToggleButton key={button} value={button}>
            <img src={getAccountImage(button, true)} />
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Box>
  )
}

export default BrandSelect
