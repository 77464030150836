import { Box, CircularProgress, makeStyles, Typography } from '@material-ui/core'
import AccountInfoDisplay from 'Components/AccountInfoDisplay'
import ActionModal from 'Components/ActionModal/ActionModal'
import OverviewLabel from 'Components/Overview/OverviewLabel'
import ResourceDetailsListItem from 'Components/ResoureDetails/ListItem'
import ThemeButton from 'Components/ThemeButton'
import { ApiProvider } from 'Core'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import React, { useMemo, useState } from 'react'

const useStyles = makeStyles((theme) => ({
  deleteText: {
    color: theme.palette.error.main
  }
}))

const DeleteProxyModal = ({ open, handleClose, data }) => {
  const classes = useStyles()
  const { getObjectRef } = useMultiSlice(['accountList', 'projects'])
  const [loading, setLoading] = useState(false)

  const account = useMemo(() => {
    if (!data) return null
    const _account = getObjectRef(data?.Spec?.Account)
    return _account || null
  }, [getObjectRef, data])
  const project = useMemo(() => {
    if (!data) return null
    const _project = getObjectRef(data?.Spec?.GcpSpec?.Project)
    return _project || null
  }, [getObjectRef, data])

  const handleDelete = async () => {
    setLoading(true)
    const api = new ApiProvider('proxies').setInstance(data)
    await api.delete()
    setLoading(false)
    handleClose()
  }

  return (
    <ActionModal
      headerBorder
      height='auto'
      width='40.5rem'
      Icon={false}
      showCloseButton
      headerPadding={{ pb: 0.1, pt: 0.2, pl: 1.5 }}
      Title='Delete Gateway'
      bodyPadding='1.5rem 1.5rem 1.5rem 1.5rem  '
      isOpen={open}
      closeLabel=''
      handleClose={handleClose}
    >
      <ResourceDetailsListItem label='Gateway Name' body={data?.ObjectMeta?.Name} />
      <ResourceDetailsListItem label='Account' body={<AccountInfoDisplay account={account} />} />
      {data?.Spec?.Type === 'AWS' && <ResourceDetailsListItem label='Region' body={data.Spec?.Region} />}
      {data?.Spec?.Type === 'GCP' && <ResourceDetailsListItem label='Project' body={project?.Spec?.Name} />}
      {data?.Spec?.Type === 'GCP' && <ResourceDetailsListItem label='Deployment Name' body={data.Spec?.GcpSpec?.DeploymentName} />}
      {data?.Spec?.Type === 'GCP' && <ResourceDetailsListItem label='Deployment Description' body={data.Spec?.GcpSpec?.DeploymentDesc} />}
      <ResourceDetailsListItem label='VPC ID' body={data.Spec?.ProxyVpcID} />
      {data?.Spec?.Type === 'AWS' && <ResourceDetailsListItem label='Key Pair' body={<OverviewLabel secondary={data.Spec?.ProxyKeyPair} />} />}
      <ResourceDetailsListItem
        hideBorder
        label=''
        body={
          <Typography variant='body2' className={classes.deleteText}>
            Are you sure you want to delete the gateway?
          </Typography>
        }
      />
      <Box mt={1} display='flex' alignItems='center' justifyContent='flex-end'>
        <ThemeButton onClick={handleDelete} startIcon={loading ? <CircularProgress size='15px' /> : null}>
          Delete Gateway
        </ThemeButton>
      </Box>
    </ActionModal>
  )
}

export default DeleteProxyModal
