import { Box, FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import BrandSelect from 'Components/BrandSelect'
import ErrorBox from 'Components/ErrorBox/ErrorBox'
import PlainInput from 'Components/PlainInput/PlainInput'
import ThemeDropDown from 'Components/ThemeDropDown'
import { Typography } from 'procyon-ui'

import React, { useCallback } from 'react'

const Form = ({
  vpcs,
  regions,
  subnets,
  accountOptions,
  zoneOptions,
  keyPairOptions,
  projectOptions,
  formik
}) => {
  const handleAccountChange = useCallback(
    (e) => {
      formik.setFieldValue('Account', e.target.value)
      formik.setFieldValue('Region', '')
      formik.setFieldValue('ProxyVpcID', '')
      formik.setFieldValue('ProxyKeyPair', '')
      formik.setFieldValue('Project', '')
    },
    [formik.setFieldValue, formik.values]
  )

  const handleRegionChange = useCallback(
    (e) => {
      formik.setFieldValue('Region', e.target.value)
      formik.setFieldValue('ProxyVpcID', '')
      formik.setFieldValue('ProxyKeyPair', '')
    },
    [formik.setFieldValue, formik.values]
  )

  const handleVpcIdChange = (e) => {
    formik.setFieldValue('ProxyVpcID', e.target.value)
    // formik.setFieldValue('ProxyKeyPair', '')
    formik.setFieldValue('ProxySubnetId', '')
  }

  const setAccountType = (value) => {
    formik.setFieldValue('AccountType', value)
    formik.setFieldValue('Account', '')
    formik.setFieldValue('Zone', '')
    formik.setFieldValue('Region', '')
    formik.setFieldValue('ProxyVpcID', '')
    formik.setFieldValue('ProxyKeyPair', '')
    formik.setFieldValue('ProxySubnetId', '')
    formik.setFieldValue('NetworkMode', '')
    formik.setFieldValue('Project', '')
    formik.setFieldValue('DeploymentName', '')
    formik.setFieldValue('DeploymentDesc', '')
  }

  const onClose = (name) => {
    setTimeout(() => formik.setFieldTouched(name), 100)
  }

  return (
    <Box mt={0.1}>
      <Box mb={0.5}>
        <BrandSelect
          activeBrand={formik.values.AccountType}
          onChange={setAccountType}
          exclude={['AZURE']}
        />
      </Box>
      <PlainInput
        mt={0.5}
        mb={0}
        {...formik.getFieldProps('Name')}
        height={0.8}
        label='Name'
        showLabel
        errored={formik.errors.Name}
        touched={formik.touched.Name}
      />
      <Box mt={0.5}>
        <ThemeDropDown
          options={accountOptions}
          valueExtractor={(option) => option.ID}
          labelExtractor={(option) => option.Name}
          value={formik.values.Account}
          onChange={handleAccountChange}
          label='Account'
          onClose={() => onClose('Account')}
          errored={formik.errors.Account}
          touched={formik.touched.Account}
        />
      </Box>
      {formik.values.AccountType === 'GCP' && (
        <Box mt={0.5}>
          <ThemeDropDown
            options={projectOptions}
            disabledHelp='Please select a Account first'
            disabled={!formik.values.Account}
            value={formik.values.Project}
            onChange={(e) => formik.setFieldValue('Project', e.target.value)}
            label='Project'
            onClose={() => onClose('Project')}
            errored={formik.errors.Project}
            touched={formik.touched.Project}
            labelExtractor={(e) => e.label}
            valueExtractor={(e) => e.value}
          />
        </Box>
      )}
      {formik.values.AccountType === 'GCP' && (
        <Box mt={0.5}>
          <ThemeDropDown
            options={zoneOptions}
            disabledHelp='Please select a Account first'
            disabled={!formik.values.Account}
            value={formik.values.Zone}
            onChange={(e) => formik.setFieldValue('Zone', e.target.value)}
            label='Zone'
            onClose={() => onClose('Zone')}
            errored={formik.errors.Zone}
            touched={formik.touched.Zone}
          />
        </Box>
      )}

      {formik.values.AccountType === 'AWS' && (
        <Box mt={0.5}>
          <ThemeDropDown
            options={regions}
            disabledHelp='Please select an account first'
            disabled={!formik.values.Account}
            value={formik.values.Region}
            onChange={handleRegionChange}
            label='Region'
            onClose={() => onClose('Region')}
            errored={formik.errors.Region}
            touched={formik.touched.Region}
          />
        </Box>
      )}

      <Box mt={0.5}>
        <ThemeDropDown
          options={vpcs}
          disabledHelp='Please select a region first'
          disabled={
            formik.values.AccountType === 'AWS' ? !formik.values.Region : !formik.values.Zone
          }
          value={formik.values.ProxyVpcID}
          onChange={handleVpcIdChange}
          label='VPC ID'
          onClose={() => onClose('ProxyVpcID')}
          errored={formik.errors.ProxyVpcID}
          touched={formik.touched.ProxyVpcID}
        />
      </Box>
      {formik.values.AccountType === 'GCP' && (
        <Box mt={0.5}>
          <PlainInput
            mt={0.5}
            mb={0}
            {...formik.getFieldProps('DeploymentName')}
            height={0.8}
            label='Deployment Name'
            showLabel
            errored={formik.errors.DeploymentName}
            touched={formik.touched.DeploymentName}
          />
        </Box>
      )}
      {formik.values.AccountType === 'GCP' && (
        <Box mt={0.5}>
          <PlainInput
            mt={0.5}
            mb={0}
            {...formik.getFieldProps('DeploymentDesc')}
            height={0.8}
            label='Deployment Description'
            showLabel
            errored={formik.errors.DeploymentDesc}
            touched={formik.touched.DeploymentDesc}
          />
        </Box>
      )}
      {formik.values.AccountType === 'AWS' && (
        <Box mt={0.5}>
          <ThemeDropDown
            options={keyPairOptions}
            disabledHelp='Please select a VPC first'
            disabled={!formik.values.Region}
            value={formik.values.ProxyKeyPair}
            onChange={(e) => formik.setFieldValue('ProxyKeyPair', e.target.value)}
            label='Key Pair'
            onClose={() => onClose('ProxyKeyPair')}
            errored={formik.errors.ProxyKeyPair}
            touched={formik.touched.ProxyKeyPair}
          />
        </Box>
      )}
      {formik.values.AccountType === 'AWS' && (
        <Box mt={0.5}>
          <ThemeDropDown
            options={subnets}
            disabledHelp='Please select a VPC first'
            disabled={!formik.values.Region}
            value={formik.values.ProxySubnetId}
            onChange={(e) => formik.setFieldValue('ProxySubnetId', e.target.value)}
            label='Subnet ID'
            onClose={() => onClose('ProxySubnetId')}
            errored={formik.errors.ProxySubnetId}
            touched={formik.touched.ProxySubnetId}
            labelExtractor={(e) => e.label}
            valueExtractor={(e) => e.value}
          />
        </Box>
      )}
      <Box mt={0.5}>
        <Typography variant='body-regular'>Network Mode?</Typography>
        <FormControl error={formik.errors.NetworkMode}>
          <RadioGroup
            value={formik.values.NetworkMode}
            onChange={(e) => formik.setFieldValue('NetworkMode', e.target?.value)}
            row
            aria-labelledby='row-radio-buttons-group-label-proxy'
            name='row-radio-buttons-group-proxy'
          >
            <FormControlLabel value='PRIVATE' control={<Radio color='primary' />} label='Private' />
            <FormControlLabel value='PUBLIC' control={<Radio color='primary' />} label='Public' />
          </RadioGroup>
        </FormControl>
        <ErrorBox error={formik.errors.NetworkMode} touched={formik.touched.NetworkMode} />
      </Box>
    </Box>
  )
}

export default Form
