// // check whether current browser supports WebAuthn
// if (!window.PublicKeyCredential) {
//   alert('Error: this browser does not support WebAuthn')
//   return
// }

// // Base64 to ArrayBuffer
// function bufferDecode(value) {
//   return Uint8Array.from(atob(value), (c) => c.charCodeAt(0))
// }
// // ArrayBuffer to URLBase64
// function bufferEncode(value) {
//   return btoa(String.fromCharCode.apply(null, new Uint8Array(value)))
//     .replace(/\+/g, '-')
//     .replace(/\//g, '_')
//     .replace(/=/g, '')
// }
// const name = 'Name'
// const tenant = 'Tenant'
// const namespace = 'Namespace'

// function loginUser() {
//   return new Promise((resolve, reject) => {
//     $.get(
//       'http://localhost:8008/login/begin',
//       { name: name, tenant: tenant, namespace: namespace },
//       function (data) {
//         return data
//       },
//       'json'
//     )
//       .then((credentialRequestOptions) => {
//         console.log('log in begin', credentialRequestOptions)
//         credentialRequestOptions.publicKey.challenge = bufferDecode(
//           credentialRequestOptions.publicKey.challenge
//         )
//         credentialRequestOptions.publicKey.allowCredentials.forEach(function (listItem) {
//           listItem.id = bufferDecode(listItem.id)
//         })
//         return navigator.credentials.get({
//           publicKey: credentialRequestOptions.publicKey
//         })
//       })
//       .then((assertion) => {
//         console.log(assertion)
//         let authData = assertion.response.authenticatorData
//         let clientDataJSON = assertion.response.clientDataJSON
//         let rawId = assertion.rawId
//         let sig = assertion.response.signature
//         let userHandle = assertion.response.userHandle
//         $.post(
//           'http://localhost:8008/login/finish',
//           JSON.stringify({
//             id: assertion.id,
//             username: name,
//             tenant: tenant,
//             namespace: namespace,
//             rawId: bufferEncode(rawId),
//             type: assertion.type,
//             response: {
//               authenticatorData: bufferEncode(authData),
//               clientDataJSON: bufferEncode(clientDataJSON),
//               signature: bufferEncode(sig),
//               userHandle: bufferEncode(userHandle)
//             }
//           }),
//           function (data) {
//             console.log('log in finish ', data)
//             return data
//           },
//           'json'
//         )
//       })
//       .then((success) => {
//         alert('successfully logged in ' + name + '! Please close the browser')
//         resolve(success)
//       })
//       .catch((error) => {
//         console.log('error :: ', error)
//         if (error.responseText) {
//           alert(error.responseText)
//           reject(error)
//         }
//         if (error.name === 'NotAllowedError') {
//           alert('Action was not allowed. Ensure the page is focused and reload the browser.')
//           reject(error)
//         }
//       })
//   })
// }
// function registerUser() {
//   return new Promise((resolve, reject) => {
//     $.get(
//       'http://localhost:8008/register/begin',
//       { name: name, tenant: tenant, namespace: namespace },
//       function (data) {
//         return data
//       },
//       'json'
//     )
//       .then((credentialCreationOptions) => {
//         console.log('registration', credentialCreationOptions)
//         credentialCreationOptions.publicKey.challenge = bufferDecode(
//           credentialCreationOptions.publicKey.challenge
//         )
//         credentialCreationOptions.publicKey.user.id = bufferDecode(
//           credentialCreationOptions.publicKey.user.id
//         )
//         if (credentialCreationOptions.publicKey.excludeCredentials) {
//           for (
//             const i = 0;
//             i < credentialCreationOptions.publicKey.excludeCredentials.length;
//             i++
//           ) {
//             credentialCreationOptions.publicKey.excludeCredentials[i].id = bufferDecode(
//               credentialCreationOptions.publicKey.excludeCredentials[i].id
//             )
//           }
//         }
//         return new Promise((resolve, reject) =>
//           navigator.credentials
//             .create({
//               publicKey: credentialCreationOptions.publicKey
//             })
//             .then((cred) => resolve(cred))
//             .catch((err) => reject(err))
//         )
//       })
//       .then((credential) => {
//         console.log('creds', credential)
//         let attestationObject = credential.response.attestationObject
//         let clientDataJSON = credential.response.clientDataJSON
//         let rawId = credential.rawId
//         $.post(
//           'http://localhost:8008/register/finish',
//           JSON.stringify({
//             username: name,
//             id: credential.id,
//             tenant: tenant,
//             namespace: namespace,
//             rawId: bufferEncode(rawId),
//             type: credential.type,
//             response: {
//               attestationObject: bufferEncode(attestationObject),
//               clientDataJSON: bufferEncode(clientDataJSON)
//             }
//           }),
//           function (data) {
//             return data
//           },
//           'json'
//         )
//       })
//       .then((success) => {
//         alert('successfully registered ' + name + '! Please proceed ahead for login')
//         resolve(success)
//       })
//       .catch((error) => {
//         console.log('error while registering :: ', error)
//         if (error.name === 'NotAllowedError') {
//           alert('Please make sure the document is focused.')
//         } else {
//           alert('failed to register ' + name + '!')
//           reject(error)
//         }
//       })
//   })
// }

// loginUser().catch((err) => {
//   if (err.name !== 'NotAllowedError') {
//     registerUser().then(() => loginUser())
//   }
// })

export const initWebAuthn = () => {
  /**
   * webauthn route manage
   */

  const enableWebAuthn = () => {
    localStorage.setItem('webauthn', 'true')
  }

  const disableWebAuthn = () => {
    localStorage.setItem('webauthn', 'false')
  }

  const isWebAuthnAllowed = () => {
    return localStorage.getItem('webauthn') === 'true'
  }

  window.webauthn = {
    enable: enableWebAuthn,
    disable: disableWebAuthn,
    isAllowed: isWebAuthnAllowed
  }
}
