import { faMagnifyingGlass, faXmarkCircle } from '@fortawesome/free-solid-svg-icons'
import useTimeoutCall from 'Core/Hooks/useTimeoutCall'
import { Button, TextInput } from 'procyon-ui'
import React, { useState } from 'react'

function SearchInput({
  onChange,
  searchKey,
  className = '',
  sx = {},
  placeholder = 'Search',
  suggestions = [],
  width = '303px'
}) {
  const [searchKeyDisplay, setSearchKeyDisplay] = useState('')

  const { updateSeed: updateSearchKey } = useTimeoutCall({
    callback: (value) => onChange && onChange(value.toLowerCase()),
    delay: 500
  })

  if (searchKey !== searchKeyDisplay) updateSearchKey(searchKeyDisplay)

  return (
    <div className='relative'>
      <TextInput
        id='search-input'
        value={searchKeyDisplay}
        className={className}
        rows={1}
        icon={searchKeyDisplay ? faXmarkCircle : faMagnifyingGlass}
        iconStyle={{ cursor: searchKeyDisplay ? 'pointer' : 'initial' }}
        onClickEndIcon={(e) => setSearchKeyDisplay('')}
        iconPosition={searchKeyDisplay ? 'end' : 'start'}
        onChange={(e) => {
          setSearchKeyDisplay(e.target.value)
        }}
        placeholder={placeholder}
        sx={{ width, ...sx }}
      />
      {suggestions.length > 0 && (
        <div className='p-1 z-50 bg-white border shadow rounded absolute top-[39px] w-[100%]'>
          {suggestions.map((e) => (
            <Button key={e.title} className='!w-[100%]' variant='clear'>
              {e.title}
            </Button>
          ))}
        </div>
      )}
    </div>
  )
}

export { SearchInput }
