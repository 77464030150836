import { Box, makeStyles } from '@material-ui/core'
import { FileCopy } from '@material-ui/icons'
import ThemeButton from 'Components/ThemeButton'
import React from 'react'
import { enqueueNotification } from 'Utils/Helpers'
const useStyle = makeStyles(theme => ({
  root: {

  },
  //   button: {
  //     position: 'absolute',
  //     top: -3,
  //     right: -3
  //   },
  text: {
    borderRadius: theme.spacing(0.25),
    padding: theme.spacing(1),
    height: theme.spacing(14),
    overflowY: 'auto',
    overflowX: 'hidden',
    background: theme.palette.info.main,
    color: 'white',
    wordBreak: 'break-word'
  }
}))
const CommandViewer = ({ command, actionLabel,copyMessage }) => {
  const classes = useStyle()
  const copyCommand = () => {
    try {
      navigator.clipboard.writeText(command)
      enqueueNotification(copyMessage, 'info')
    } catch (error) {
      console.error('error in handleCopy: ', error)
    }
  }
  return (
    <Box className={classes.root}>
      <Box mb={1} className={classes.text}>{command} </Box>
      <Box mb={1} display='flex' justifyContent='flex-end' pr={1}>
        <ThemeButton disabled={!command} size='small' className={classes.button} onClick={copyCommand} startIcon={<FileCopy />}>{actionLabel}</ThemeButton>
      </Box>
    </Box>
  )
}

export default CommandViewer
