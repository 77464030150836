import {
  ProxyControllerWizardProvider,
  useProxyControllerWizardContext
} from 'features/proxyControllers'
import { isAlphanumeric } from 'features/resources'
import { reduxApiClient } from 'infra'
import { createDataSelectorHook } from 'infra/redux'
import _ from 'lodash'
import React from 'react'
import { enqueueNotification } from 'Utils/Helpers'
import { Wizard } from 'V2Components'
import { ConfigStep } from './components/ConfigStep'
import { FooterComponent } from './components/FooterComponent'
import { SummaryStep } from './components/SummaryStep'
import { TargetProxyImage } from './components/TargetProxyImage'

const useSlices = createDataSelectorHook(['proxyControllers'])

const CreateProxyControllerWizard = ({ onCancel }) => {
  const contextData = useProxyControllerWizardContext()
  const { slices } = useSlices()

  const getSteps = () => {
    return [
      {
        label: 'Config',
        content: <ConfigStep />,
        getErrors() {
          const { controllerName, selectorType, label, selectedNodeIDs, nodeTypes } = contextData
          if (controllerName.trim().length <= 3)
            return 'Controller Name must be greater than 3 characters.'
          if (
            !contextData.isEditMode &&
            _.find(slices.proxyControllers, { ObjectMeta: { Name: controllerName.trim() } })
          ) {
            return 'Controller with the name already exists.'
          }
          if (/\s/g.test(controllerName)) return 'Name cannot have spaces.'
          if (!isAlphanumeric(controllerName)) return 'Only Letters and number are allowed in name.'
          switch (selectorType) {
            case 'Nodes':
              if (selectedNodeIDs.length === 0) return 'Select at least one node.'
              break
            case 'NodeTypes':
              if (nodeTypes.length === 0) return 'Select at least one node type.'
              break
            case 'Labels':
              if (label.length === 1) return 'Tag key and value is required.'
              break
          }
        }
      },
      {
        label: 'Select Proxy Image',
        content: <TargetProxyImage />,
        getErrors() {
          const { targetProxyImages, rdpEnabled } = contextData

          if (!targetProxyImages['procyon-proxy'].split(':')[1]?.trim())
            return 'Procyon Proxy Image invalid tag name.'
          if (!rdpEnabled) return ''

          if (!targetProxyImages['guacamole-client'].split(':')[1]?.trim())
            return 'Guacamole Client Image invalid tag name.'
          if (!targetProxyImages['guacamole-server'].split(':')[1]?.trim())
            return 'Guacamole Server Image invalid tag name.'
        }
      },
      {
        label: 'Summary',
        content: <SummaryStep />
      }
    ]
  }

  const steps = getSteps()

  const getIndexErrors = () => {
    const indexedErrors = []

    steps.forEach((step, i) => {
      const { getErrors } = step
      let errors = ''
      if (getErrors) {
        errors = getErrors()
      }
      indexedErrors[i] = errors
    })

    return indexedErrors
  }

  const handleProxyControllerCreate = async () => {
    try {
      //@ts-ignore
      await handleCreate(contextData)
      enqueueNotification(
        `Proxy Controller Successfully ${contextData.isEditMode ? 'Updated!' : 'Created!'}.`,
        'info'
      )
      onCancel()
    } catch (error) {
      enqueueNotification(
        `Error ${contextData.isEditMode ? 'Updating' : 'Creating'} proxy controller!`,
        'error'
      )
    }
  }

  return (
    <Wizard
      header={
        <div className='flex gap-2 items-center'>
          {contextData.isEditMode ? 'Edit' : 'Create'} Proxy Controller
        </div>
      }
      onCancel={onCancel}
      steps={steps}
      //@ts-ignore
      FooterComponent={(props) => (
        <FooterComponent
          isEditMode={contextData.isEditMode}
          indexedErrors={getIndexErrors()}
          onSubmit={handleProxyControllerCreate}
          {...props}
        />
      )}
    />
  )
}

const Wrapped = ({ onCancel, proxyCtrl = null }) => (
  <ProxyControllerWizardProvider proxyCtrl={proxyCtrl}>
    <CreateProxyControllerWizard onCancel={onCancel} />
  </ProxyControllerWizardProvider>
)

const handleCreate = async ({
  proxyCtrl,
  controllerName,
  selectorType,
  label,
  selectedNodeIDs,
  targetProxyImages,
  rdpEnabled,
  nodeTypes
}) => {
  try {
    const payload = {
      ObjectMeta: {
        Name: controllerName
      },
      Spec: {
        NodeTypes: {
          Elems: []
        },
        MedusaNodes: {
          Elems: []
        },
        TargetImageInfo: {
          Map: {}
        },
        Labels: {
          Map: {}
        }
      }
    }

    if (proxyCtrl) payload.ObjectMeta = { ...proxyCtrl.ObjectMeta }

    switch (selectorType) {
      case 'Nodes':
        payload.Spec.MedusaNodes.Elems = selectedNodeIDs
        break
      case 'Labels':
        const labelKey = label[0]

        const labelValueArr = label.slice(1, label.length)

        const labelValue = labelValueArr.reduce((prev, curr, i) => {
          const [, value] = curr.split(':')
          if (i === labelValueArr.length - 1) return prev + value
          return prev + `${value},`
        }, '')

        payload.Spec.Labels.Map[labelKey] = labelValue
        break
      case 'NodeTypes':
        payload.Spec.NodeTypes.Elems = nodeTypes
        break
    }

    payload.Spec.TargetImageInfo.Map['procyon-proxy'] = targetProxyImages['procyon-proxy']

    if (rdpEnabled) {
      payload.Spec.TargetImageInfo.Map = { ...targetProxyImages }
    }

    if (proxyCtrl) await reduxApiClient('proxycontrollers').update(payload)
    else await reduxApiClient('proxycontrollers').create(payload)
  } catch (error) {}
}

export { Wrapped as CreateProxyControllerWizard }
