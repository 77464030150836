// @ts-nocheck
import { Box, CircularProgress, Grid } from '@material-ui/core'
import ThemeButton from 'Components/ThemeButton'
import { ApiProvider, _, useDispatch } from 'Core'
import useMemoSelector from 'Core/Hooks/useMemoSelector'
import { awsResourcesSlice, projectsSlice, serverListSlice, vpcListSlice } from 'infra/redux/reducers'
import { confirmUniqueness, enqueueNotification, getUserInfo } from 'Utils/Helpers'
import { getResourceName } from 'features/resources'
import { useFormik } from 'formik'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import * as Yup from 'yup'
import Form from './Form'
import Help from './Help'
import { regionList } from './RegionList'
import { zonelist } from './ZoneList'
import useMultiDispatch from 'Core/Hooks/useMultiDispatch'

const UIForm = ({ handleClose, filterComponent }) => {
  const { data: accountList } = useMemoSelector('accountList')
  const { data: proxyList } = useMemoSelector('proxyList')
  const { data: vpcList } = useMemoSelector('vpcList')
  const { data: projects } = useMemoSelector('projects')
  const { data: awsResources } = useMemoSelector('awsResources')
  const { data: gcpResources } = useMemoSelector('gcpResources')

  const dispatch = useDispatch()
  const { selectDispatch } = useMultiDispatch([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    dispatch(serverListSlice.thunk({ flags: { skipLoader: true } }))
    dispatch(vpcListSlice.thunk({ flags: { skipLoader: true } }))
    dispatch(awsResourcesSlice.thunk({ flags: { skipLoader: true } }))
    dispatch(projectsSlice.thunk({ flags: { skipLoader: true } }))
    selectDispatch(['gcpResources'], { skipLoader: true })
  }, [])

  const InitialValues = useMemo(() => {
    return {
      AccountType: 'AWS',
      Name: '',
      Account: '',
      Zone: '',
      Region: '',
      ProxyVpcID: '',
      ProxyKeyPair: '',
      ProxySubnetId: '',
      NetworkMode: '',
      Project: '',
      DeploymentName: '',
      DeploymentDesc: ''
    }
  }, [])
  const VALIDATION_RULES = useMemo(() => {
    return Yup.object({
      Name: Yup.string()
        .test('confirmUniqueness', 'This name is already taken', function (value) {
          return confirmUniqueness(value, 'proxyList')
        })
        .required('*Name is required'),

      Account: Yup.number().required('*Account is required'),
      Region: Yup.string().when('AccountType', {
        is: 'AWS',
        then: Yup.string().required('*Region is required.')
      }),
      ProxyVpcID: Yup.string().required('*Proxy VpcID is required'),
      ProxySubnetId: Yup.string().when('AccountType', {
        is: 'AWS',
        then: Yup.string().required('*Proxy Subnet Id is required.')
      }),
      ProxyKeyPair: Yup.string().when('AccountType', {
        is: 'AWS',
        then: Yup.string().required('*Proxy KeyPair is required.')
      }),
      Project: Yup.string().when('AccountType', {
        is: 'GCP',
        then: Yup.string().required('*Project is required.')
      }),
      DeploymentName: Yup.string().when('AccountType', {
        is: 'GCP',
        then: Yup.string()
          .test(
            'special character',
            `Only numbers, lowercase alphabets and - are allowed. Must start with an alphabet and must end with a number or alphabet`,
            function (value) {
              return new RegExp('^[a-z](?:[-a-z0-9]{0,61}[a-z0-9])?$').test(value)
            }
          )
          .required('*Deployment Name is required.')
      }),
      DeploymentDesc: Yup.string().when('AccountType', {
        is: 'GCP',
        then: Yup.string().required('*DeploymentDesc is required.')
      }),
      Zone: Yup.string().when('AccountType', {
        is: 'GCP',
        then: Yup.string().required('*Zone is required.')
      }),
      NetworkMode: Yup.string().required('*Network Mode is required')
    })
  }, [proxyList])

  const onSubmit = useCallback(
    async (fields) => {
      try {
        setLoading(true)
        const formPayload = {
          ObjectMeta: {
            Kind: 'RemoteProxy',
            Tenant: getUserInfo().tenants,
            Namespace: getUserInfo().org,
            Name: fields.Name
          },
          Spec: {
            Type: fields.AccountType,
            Account: { RefID: fields.Account, RefKind: 'Account' },
            ProxyVpcID: fields.ProxyVpcID,
            ProxyType: 'PrivateProxy',
            NetworkMode: fields.NetworkMode
          }
        }
        if (fields.AccountType === 'AWS') {
          formPayload.Spec.Region = fields.Region
          formPayload.Spec.ProxyKeyPair = fields.ProxyKeyPair
          formPayload.Spec.ProxySubnetId = fields.ProxySubnetId
        }
        if (fields.AccountType === 'GCP') {
          formPayload.Spec.GcpSpec = {
            Project: {
              RefKind: 'Project',
              RefID: fields.Project
            },
            DeploymentName: fields.DeploymentName,
            DeploymentDesc: fields.DeploymentDesc,
            Zone: fields.Zone
          }
        }
        console.log('formPayload: ', formPayload)
        await new ApiProvider('proxies').setInstance(formPayload).post()
        enqueueNotification('Proxy created successfuly', 'info')
        formik.resetForm(InitialValues)
      } catch (error) {
        enqueueNotification('Operation failed', 'error')
      } finally {
        setLoading(false)
        handleClose && handleClose()
      }
    },
    [handleClose, InitialValues]
  )

  const formik = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    onSubmit,
    initialValues: InitialValues,
    validationSchema: VALIDATION_RULES
  })

  const regionOptions = regionList
  const zoneOptions = zonelist
  const keyPairOptions = useMemo(() => {
    const AccountID = formik.values.Account
    const Region = formik.values.Region
    const keynames = awsResources.reduce((list, resource) => {
      if (resource.Spec.Type === 'AWS_EC2_Key_Pair') {
        if (resource.Spec.Account.RefID === AccountID && resource.Spec.Region === Region) {
          list.push(resource.Spec.Name)
        }
      }
      return list
    }, [])
    return keynames
  }, [formik, awsResources])

  const projectOptions = useMemo(() => {
    try {
      const options = []
      projects.forEach((project) => {
        if (project.Spec.Account.RefID === formik.values.Account) {
          options.push({
            label: getResourceName(project),
            value: project.ObjectMeta.ID
          })
        }
      })
      return options
    } catch (error) {
      console.error('error in preparing vpc options', error)
      return []
    }
  }, [formik.values.Account, projects, accountList])

  const vpcOptions = useMemo(() => {
    try {
      const options = []
      const account = accountList.find((e) => e.ObjectMeta.ID === formik.values.Account)
      const AccountID = account.Spec.AccountID
      const Region = formik.values.Region
      if (formik.values.AccountType === 'AWS') {
        vpcList.forEach((vpc) => {
          if (vpc.AccountID === AccountID && vpc.Region === Region) {
            options.push(vpc.VpcId)
          }
        })
      }
      if (formik.values.AccountType === 'GCP') {
        // Find the selected Project
        const prjt = _.find(projects, { ObjectMeta: { ID: formik.values.Project } })
        if (!prjt) return []
        gcpResources.forEach((r) => {
          // Find the vpc type resources
          if (r.Spec.Type === 'network.vpc' && r.Spec.Parent?.RefID) {
            // Find the parent of the vpc. Parents of VPCs are always Projects
            const rsrcParent = _.find(gcpResources, { ObjectMeta: { ID: r.Spec.Parent?.RefID } })
            if (!rsrcParent) return
            // Comparte the Project's displayname with the GcpResource Project's DisplayName
            // If it matches then this is the vpc which belongs to the selected project - `prjt`
            if (rsrcParent.Spec.DisplayName === prjt.Spec.DisplayName)
              options.push(r.Spec.DisplayName)
          }
        })
      }
      return options
    } catch (error) {
      console.error('error in preparing vpc options', error)
      return []
    }
  }, [
    formik.values.Account,
    formik.values.Project,
    formik.values.AccountType,
    formik.values.Region,
    vpcList,
    accountList,
    gcpResources,
    projects
  ])

  const subnetOption = useMemo(() => {
    if (!formik.values.ProxyVpcID) return []
    const vpc = vpcList.find((e) => e.VpcId === formik.values.ProxyVpcID)
    if (!vpc || !vpc?.SubnetInfos?.SubnetMap) return []
    return Object.values(vpc.SubnetInfos.SubnetMap).map(({ SubnetID, Cidr, TagName }) => {
      const label = `${SubnetID}-${Cidr}-${TagName}`
      const value = SubnetID
      return { label, value }
    })
  }, [vpcList, formik.values])

  const accountOptions = useMemo(
    () =>
      accountList
        .filter((e) => e.Spec.Type === formik.values.AccountType)
        .map((account) => ({
          Name: getResourceName(account),
          ID: account.ObjectMeta.ID
        })),
    [accountList, formik.values.AccountType]
  )
  const onCancel = () => {
    formik.handleReset(InitialValues)
    handleClose()
  }
  return (
    <>
      <Grid container spacing={3} direction='row'>
        <Grid item xs={5}>
          <Help />
        </Grid>
        <Grid item xs={7} style={{ paddingLeft: '0' }}>
          {filterComponent}
          <Form
            zoneOptions={zoneOptions}
            projectOptions={projectOptions}
            keyPairOptions={keyPairOptions}
            accountOptions={accountOptions}
            regions={regionOptions}
            vpcs={vpcOptions}
            subnets={subnetOption}
            formik={formik}
          />
        </Grid>
      </Grid>
      <Box mt={2.2} display='flex' alignItems='center' justifyContent='flex-end'>
        <ThemeButton size='small' onClick={onCancel}>
          Cancel
        </ThemeButton>
        <Box ml={1}>
          <ThemeButton theme='primary' size='small' onClick={formik.handleSubmit}>
            {loading && <CircularProgress size={24} />}
            <Box ml={1}> Add Gateway</Box>
          </ThemeButton>
        </Box>
      </Box>
    </>
  )
}

export default UIForm
