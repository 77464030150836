const { createContext } = require('react')
import { useUser } from 'Core/Hooks/useUser'
import { randomStringGenerator } from 'Utils/PureHelperFuctions'
import { useAWSForm, useAZUREForm, useGCPForm } from 'features/clouds'
import {
  getAccountIDFromARN,
  isPrivateAccount,
  isValidAccountCredentials
} from 'features/clouds/utils'
import { reduxApiClient } from 'infra'
import _ from 'lodash'
import React, { useContext, useState } from 'react'

/**
 * @typedef {{
 * arn?: string
 * externalID?: string
 * orgAccount?: boolean
 * ouID?: string
 * }} AWSAccountSpec
 * @type {AWSAccountSpec}
 */
const AWS_SPEC = {
  arn: '',
  externalID: '',
  orgAccount: true,
  ouID: ''
}

/**
 * @typedef {{
 * accountType?: 'ORG' | 'FOLDER' | 'PROJECT'
 * jsonCredentials?: string
 * cloudIDType?: 'intermediate' | 'workforce-fed'
 * primaryDomain?: string
 * }} GcpAccountSpec
 *
 * @typedef {((gcpSpec:GcpAccountSpec) => void)} GcpAccountSpecUpdateFunction
 * @type {GcpAccountSpec}
 */
const GCP_SPEC = {
  accountType: 'ORG',
  jsonCredentials: '',
  cloudIDType: 'intermediate',
  primaryDomain: ''
}

/**
 * @typedef {{
 * isSAMLConfigurationAllowed?: boolean
 * isGuestUserDeleteAllowed?: boolean
 * tenantID?:string
 * appID?:string
 * appKey?:string
 * }} AzureAccountSpec
 *
 * @typedef {((gcpSpec:AzureAccountSpec) => void)} AzureAccountSpecUpdateFunction
 * @type {AzureAccountSpec}
 */
const AZURE_SPEC = {
  isSAMLConfigurationAllowed: true,
  isGuestUserDeleteAllowed: true,
  appID: '',
  appKey: '',
  tenantID: ''
}

/**
 * @typedef {{
 * link?: string
 * }} PrivateSpec
 * @type {PrivateSpec}
 */
const PRIVATE_SPEC = {
  link: ''
}

const AddAccountWizardContext = createContext({
  isEditMode: false,
  org: '',
  name: '',
  description: '',
  awsSpec: AWS_SPEC,
  gcpSpec: GCP_SPEC,
  azureSpec: AZURE_SPEC,
  privateSpec: PRIVATE_SPEC,
  setOrg(e) {},
  setName(e) {},
  setDescription(e) {},
  /**
   *
   * @param {AWSAccountSpec} e
   */
  setAwsSpec(e) {},
  /**
   *
   * @param {GcpAccountSpec} e
   */
  setGcpSpec(e) {},
  /**
   *
   * @param {AzureAccountSpec} e
   */
  setAzureSpec(e) {},
  /**
   * @type {((e:any)=> Promise.<boolean>)}
   *
   */
  /**
   *
   * @param {PrivateSpec} e
   */
  setPrivateSpec(e) {},
  /**
   * @type {((e:any)=> Promise.<boolean>)}
   *
   */
  // @ts-ignore
  async checkAccountCredentials() {},
  /**
   * @type {((e:any)=> Promise.<boolean>)}
   *
   */
  // @ts-ignore
  async createAccount() {},
  /**
   * @type {((e:any)=> Promise.<boolean>)}
   *
   */
  // @ts-ignore
  async updateAccount() {}
})

const getAwsFormValues = (account) => {
  if (!account || isPrivateAccount(account))
    return { ...AWS_SPEC, externalID: randomStringGenerator(8) }
  const { AwsSpec, AccountID } = account.Spec
  return {
    arn: AwsSpec.AssumeRoleARN,
    accountID: AccountID,
    externalID: AwsSpec.ExternalID,
    orgAccount: AwsSpec.OrgAccount,
    ouID: AwsSpec.OuID
  }
}

const getAzureFormValues = (account) => {
  if (!account || isPrivateAccount(account)) return AZURE_SPEC
  return {
    ...AZURE_SPEC,
    tenantID: account?.Spec.AzureSpec.TenantID,
    appID: account?.Spec.AzureSpec.ProcyonAppID
  }
}

/**
 *
 * @param {any} account
 * @returns {GcpAccountSpec}
 */
const getGcpFormValues = (account) => {
  if (!account || isPrivateAccount(account)) return GCP_SPEC
  return {
    ...GCP_SPEC,
    cloudIDType: account.Spec.GcpSpec.CloudIDType,
    primaryDomain: account.Spec.GcpSpec.PrimaryDomain
  }
}

export const AddAccountWizardProvider = ({ children, account }) => {
  const [name, setName] = useState(account?.ObjectMeta.Name || '')
  const [description, setDescription] = useState(account?.Spec.Description || '')

  const { org: o } = useUser()
  const [org, setOrg] = useState(o)

  const isEditMode = !!account

  const [awsSpec, setAwsSpec] = useState(getAwsFormValues(account))
  const [gcpSpec, setGcpSpec] = useState(getGcpFormValues(account))
  const [azureSpec, setAzureSpec] = useState(getAzureFormValues(account))
  const [privateSpec, setPrivateSpec] = useState({ link: _.get(account, 'Spec.Link', '') })

  const { checkAWSAccountCredentials, createAWSAccount, updateAWSAccount } = useAWSForm()
  const { checkGCPAccountCredentials, handleGCPAccountCreate, handleGCPAccountUpdate } =
    useGCPForm()
  const { checkAZUREAccountCredentials, handleAZUREAccountCreate, handleAZUREAccountUpdate } =
    useAZUREForm()

  /**
   *
   * @param {AWSAccountSpec} spec
   */
  const updateAWSSpec = (spec) => {
    setAwsSpec((s) => ({ ...s, ...spec }))
  }

  /**
   *
   * @param {GcpAccountSpec} spec
   */
  const updateGcpSpec = (spec) => {
    setGcpSpec((s) => ({ ...s, ...spec }))
  }
  /**
   *
   * @param {AzureAccountSpec} spec
   */
  const updateAzureSpec = (spec) => {
    setAzureSpec((s) => ({ ...s, ...spec }))
  }

  /**
   *
   * @param {PrivateSpec} spec
   */
  const updatePrivateSpec = (spec) => {
    setPrivateSpec((s) => ({ ...s, ...spec }))
  }

  const createAccount = async (cloudType) => {
    switch (cloudType) {
      case 'AWS':
        // @ts-ignore
        return await createAWSAccount({ ...awsSpec, name, description, org })
      case 'GCP':
        // @ts-ignore
        return await handleGCPAccountCreate({
          ...gcpSpec,
          name,
          description,
          org
        })
      case 'AZURE':
        //@ts-ignore
        return await handleAZUREAccountCreate({ ...azureSpec, name, description, org })
      case 'PRIVATE':
        return await reduxApiClient('privateaccounts').create({
          ObjectMeta: {
            Name: name
          },
          Spec: {
            Description: description,
            Link: privateSpec.link
          }
        })
      default:
        break
    }
  }

  const checkAccountCredentials = async (cloudType) => {
    if (cloudType === 'AWS')
      return await checkAWSAccountCredentials({
        arn: awsSpec.arn,
        externalID: awsSpec.externalID,
        orgAccount: awsSpec.orgAccount,
        ouID: awsSpec.ouID
      })
    if (cloudType === 'GCP')
      return await checkGCPAccountCredentials({ jsonCredentials: gcpSpec.jsonCredentials })
    if (cloudType === 'AZURE')
      return await checkAZUREAccountCredentials({
        appID: azureSpec.appID,
        appKey: azureSpec.appKey,
        tenantID: azureSpec.tenantID
      })
  }

  const updateAccount = async (cloudType) => {
    switch (cloudType) {
      case 'AWS':
        // @ts-ignore
        return await updateAWSAccount({ ...awsSpec, description, account })
      case 'GCP':
        // @ts-ignore
        return await handleGCPAccountUpdate({
          ...gcpSpec,
          description,
          account
        })
      case 'AZURE':
        //@ts-ignore
        return await handleAZUREAccountUpdate({ ...azureSpec, description, account })
      case 'PRIVATE':
        const clone = structuredClone(account)
        clone.Spec.Link = privateSpec.link
        clone.Spec.Description = description
        return await reduxApiClient('privateaccounts').update(clone)
      default:
        break
    }
  }

  return (
    <AddAccountWizardContext.Provider
      value={{
        isEditMode,
        name,
        description,
        awsSpec,
        gcpSpec,
        azureSpec,
        setName,
        setDescription,
        org,
        setOrg,
        setAwsSpec: updateAWSSpec,
        setAzureSpec: updateAzureSpec,
        setGcpSpec: updateGcpSpec,
        privateSpec,
        setPrivateSpec: updatePrivateSpec,
        // @ts-ignore
        checkAccountCredentials,
        // @ts-ignore
        createAccount,
        // @ts-ignore
        updateAccount
      }}
    >
      {children}
    </AddAccountWizardContext.Provider>
  )
}

export const useAddAccountWizardContext = () => useContext(AddAccountWizardContext)
