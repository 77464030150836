const getImageInitials = (name = '') => {
  let initial = ''
  const split = name.split('-')

  split.forEach((e) => {
    initial += e[0]?.toUpperCase()
  })

  return initial
}

export const getMedusaNodeStateImages = (medusaNodeState) => {
  const images = []
  if (!medusaNodeState) return []
  for (const imageName in medusaNodeState.Spec.StatusMap) {
    const data = medusaNodeState.Spec.StatusMap[imageName]
    images.push({
      Name: imageName,
      initial: getImageInitials(imageName),
      ...data
    })
  }

  return images
}
