import { useRouteParams } from 'Core/Hooks/useRouteParams'
import React, { createContext, useContext, useState } from 'react'

/**
 * @type { import('react').Context<{ cloudType: 'AWS' | 'GCP' | 'AZURE' | 'PRIVATE' }>}
 */
const CloudTypeRouteContext = createContext({
  cloudType: 'AWS'
})

function CloudTypeRouteProvider({ children, routeKey }) {
  const [cloudType, setCloudType] = useState('')

  useRouteParams({
    syncUsingParamValue: true,
    key: routeKey,
    defaultValue: 'aws',
    controlValue: cloudType.toLowerCase(),
    possibleValues: ['aws', 'gcp', 'azure', 'private'],
    onControlValueChange(value) {
      //@ts-ignore
      setCloudType(value.toUpperCase())
    }
  })

  return (
    // @ts-ignore
    <CloudTypeRouteContext.Provider value={{ cloudType }}>
      {children}
    </CloudTypeRouteContext.Provider>
  )
}

export const useCloudTypeRouteProvider = () => useContext(CloudTypeRouteContext)

export { CloudTypeRouteProvider }
