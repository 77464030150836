import { Box, IconButton, makeStyles } from '@material-ui/core'
import { FileCopy } from '@material-ui/icons'
import React from 'react'
import { enqueueNotification } from 'Utils/Helpers'

const useStyles = makeStyles((theme) => ({
  link: {
    wordBreak: 'break-all'
  }
}))

const ProxyTokenHelp = () => {
  const classes = useStyles()
  const link = 'https://procyon-cf-templates.s3.us-east-2.amazonaws.com/AwsRemoteProxyWithToken.json'
  const copyLink = () => {
    try {
      navigator.clipboard.writeText(link)
      enqueueNotification('Command copied to your clipboard!', 'info')
    } catch (error) {
      console.error(error)
    }
  }
  return (
        <Box mt={4}>
          Use this cloud formation template with the generated token.
          <br></br>
          <Box
            mt={1}
            mb={1}
            className={classes.link}
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            p={0.5}
            border='1px solid grey'
            borderRadius='8px'
          >
            <span>{link}</span>
            <IconButton onClick={copyLink}><FileCopy /></IconButton>
          </Box>
        </Box>
    )
}

export default ProxyTokenHelp
