import React from 'react'
import JSONPretty from 'react-json-pretty'

function JSONPrettyView({ data = {}, autoHeight = false, height = '' }) {
  const ss = autoHeight ? { ...jsonTheme, main: autoHeightMainStyle } : jsonTheme
  const HEIGHT_TEMPLATE =
    'line-height:1.3;color:#66d9ef;background:#272822;overflow:auto;padding:1rem;height:$HEIGHT;border-radius:4px;width:100%'
  
  if (height) {
    const heightStyle = HEIGHT_TEMPLATE.replace('$HEIGHT', height)
    ss.main = heightStyle
  }

  return (
    <div className='mb-4'>
      <JSONPretty theme={ss} data={data} />
    </div>
  )
}

const jsonTheme = {
  main: 'line-height:1.3;color:#66d9ef;background:#272822;overflow:auto;padding:1rem;height:280px;border-radius:4px;',
  error: 'line-height:1.3;color:#66d9ef;background:#272822;overflow:auto;',
  key: 'color:#f92672;',
  string: 'color:#fd971f;',
  value: 'color:#a6e22e;',
  boolean: 'color:#ac81fe;'
}

const autoHeightMainStyle =
  'line-height:1.3;color:#66d9ef;background:#272822;overflow:auto;padding:1rem;height:auto;border-radius:4px;width:100%'

export { JSONPrettyView }
