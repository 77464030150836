import { getResourceName } from 'features/resources'
import { ApproveUserCredentials } from 'features/settings'
import { getUserCredentialsPublicKeys } from 'features/settings/utils'
import { createDataSelectorHook } from 'infra/redux'
import _ from 'lodash'
import { Button, Label, ResponsiveTable, Typography } from 'procyon-ui'
import React, { useState } from 'react'

const useSlices = createDataSelectorHook(['userCredentials', 'userList'])

export const DevicePassKeys = () => {
  const { slices } = useSlices()
  const [showApproveUserCreds, setShowApproveUserCreds] = useState(false)

  const [selectedUsrCreds, setSelectedUsrCreds] = useState(null)
  const [selectedCredKeyForApproval, setSelectedCredKeyForApproval] = useState('')

  const getTableData = () => {
    const data = []
    slices.userCredentials.map((usrCreds) => {
      const creds = getUserCredentialsPublicKeys(usrCreds)

      creds.forEach((cred) => {
        const user = _.find(slices.userList, { ObjectMeta: { Name: usrCreds.ObjectMeta.Name } })
        data.push({
          id: cred.ID,
          user: user ? getResourceName(user) : getResourceName(usrCreds),
          device: cred.DeviceName,
          status: cred.Status,
          approve: {
            allowApproval: cred.Status !== 'APPROVED',
            onApprove() {
              setShowApproveUserCreds(true)
              setSelectedUsrCreds(usrCreds)
              /**
               ** getUserCredentialsPublicKeys adds the object key,
               ** of the map object usrCreds.PublicKeyCredentialMap.PublicKeyCredentialMap
               ** We use this key to directly map into credential in the Approval process
               */
              setSelectedCredKeyForApproval(cred.key)
            }
          }
        })
      })
    })

    return data
  }

  console.log('[pass](s):', slices.userCredentials)
  return (
    <div>
      <ResponsiveTable scrollX='1200px' data={getTableData()} columns={getColumns()} />
      {showApproveUserCreds && selectedUsrCreds && (
        <ApproveUserCredentials
          credKeyToApprove={selectedCredKeyForApproval}
          userCredential={selectedUsrCreds}
          onCancel={() => {
            setShowApproveUserCreds(false)
            setSelectedUsrCreds(null)
          }}
        />
      )}
    </div>
  )
}

/**
 *
 * @returns {import('procyon-ui').ResponsiveTableProps['columns']}
 */
const getColumns = () => [
  {
    dataIndex: 'id',
    title: 'ID',
    width: 250,
    fixed: 'left',
    align: 'left',
    sorter: (v1, v2) => v1.id.localeCompare(v2.id)
  },
  ,
  {
    dataIndex: 'user',
    title: 'User',
    width: 250,
    align: 'left',
    sorter: (v1, v2) => v1.user.localeCompare(v2.user)
  },
  {
    dataIndex: 'device',
    title: 'Device',
    width: 250,
    align: 'left',
    sorter: (v1, v2) => v1.device.localeCompare(v2.device),
    render(value) {
      if (!value) return <Label variant='gray' text='None' />
      return <Typography variant='body-regular'>{value}</Typography>
    }
  },
  {
    dataIndex: 'status',
    title: 'Status',
    align: 'left',
    width: 250,
    sorter: (v1, v2) => v1.status.localeCompare(v2.status),
    render(value) {
      if (value === 'APPROVED') return <Label variant='success' text='Approved' />
      return <Label variant='gray' text={value} />
    }
  },
  {
    dataIndex: 'approve',
    title: '',
    align: 'center',
    width: 80,
    fixed: 'right',
    render(value) {
      if (value.allowApproval)
        return (
          <Button className='mx-auto' onClick={value.onApprove} variant='grayBlue'>
            Approve
          </Button>
        )
      return <></>
    }
  }
]
