//* ====================================/ AWS Specific Utils /====================================//

import { isValidAccountCredentials } from './utils'

/**
 * Validate AWS Secret Key
 * @param {string} key AWS Secret kEY
 * @returns {boolean} `true` if `key` is a valids AWS Secret Key
 */
export const isValidAWSSecretKey = (key = '') => /([a-zA-Z0-9+/]{40})/g.test(key)

/**
 * Validate AWS Access Key
 * @param {string} key AWS Access Key
 * @returns {boolean} `true` if `key` is a valids AWS Access Key
 */
export const isValidAWSAccessKey = (key = '') =>
  /((?:ASIA|AKIA|AROA|AIDA)([A-Z0-7]{16}))/g.test(key)

/**
 * Check if an ARN is as valid AWS ARN
 * @param {string} arn
 * @returns {boolean} `true` if the `arn` is a valid AWS arn
 */
export const isValidAWSARN = (arn = '') =>
  /arn:aws:(?<service>[a-z]*):(?<region>[a-zA-Z0-9-]*?):(?<accountid>[0-9]{12}):(?<resource>[a-zA-Z0-9_\-.]*)/g.test(
    arn
  )

/**
 * Get AccountID from ARN
 * @param {string} arn
 * @returns {string} Account ID
 */
export const getAccountIDFromARN = (arn) => {
  try {
    return arn.split('::')[1].split(':')[0]
  } catch (error) {
    return ''
  }
}

export const isPrivateAccount = (acc) => acc.ObjectMeta.Kind === 'PrivateAccount'

/**
 * Check if an account is org account or not
 * @param {*} account
 * @returns
 */
export const isOrgAccount = (account) => {
  if (isPrivateAccount(account)) return false
  if (account?.Spec.AwsSpec.OrgAccount) return true
  return false
}

/**
 *
 * @param {{
 * arn?: string
 * externalID?: string
 * orgAccount?: boolean
 * ouID?: string
 * }} awsSpec
 * @returns
 */
export const checkAWSAccountCredential = async (awsSpec) => {
  const AccountID = getAccountIDFromARN(awsSpec.arn)
  const AwsSpec = {
    AssumeRoleARN: awsSpec.arn.trim(),
    ExternalID: awsSpec.externalID.trim(),
    OuID: awsSpec.ouID.trim(),
    OrgAccount: awsSpec.orgAccount
  }

  const payload = {
    ObjectMeta: {
      Name: 'ACCOUNT_CHECK'
    },
    Spec: {
      Automate: false,
      AwsSpec,
      Description: 'aws',
      Discovery: false,
      RemoteProxy: false,
      AccountType: 'AWS_CROSS',
      AccountID
    }
  }

  return await isValidAccountCredentials(payload)
}
//prettier-ignore
export const AWS_CLOUD_REGIONS = [
  "us-east-1",      // US East (N. Virginia)
  "us-east-2",      // US East (Ohio)
  "us-west-1",      // US West (N. California)
  "us-west-2",      // US West (Oregon)
  "af-south-1",     // Africa (Cape Town)
  "ap-east-1",      // Asia Pacific (Hong Kong)
  "ap-south-1",     // Asia Pacific (Mumbai)
  "ap-south-2",     // Asia Pacific (Hyderabad)
  "ap-southeast-1", // Asia Pacific (Singapore)
  "ap-southeast-2", // Asia Pacific (Sydney)
  "ap-southeast-3", // Asia Pacific (Jakarta)
  "ap-southeast-4", // Asia Pacific (Melbourne)
  "ap-northeast-1", // Asia Pacific (Tokyo)
  "ap-northeast-2", // Asia Pacific (Seoul)
  "ap-northeast-3", // Asia Pacific (Osaka)
  "ca-central-1",   // Canada (Central)
  "eu-central-1",   // Europe (Frankfurt)
  "eu-central-2",   // Europe (Zurich)
  "eu-west-1",      // Europe (Ireland)
  "eu-west-2",      // Europe (London)
  "eu-west-3",      // Europe (Paris)
  "eu-north-1",     // Europe (Stockholm)
  "eu-south-1",     // Europe (Milan)
  "eu-south-2",     // Europe (Spain)
  "me-central-1",   // Middle East (UAE)
  "me-south-1",     // Middle East (Bahrain)
  "sa-east-1"       // South America (São Paulo)
]
