import { createRsrcKey, getResourceName, getRsrcIcon, reverseRsrcKey } from 'features/resources'
import { useServerControllerWizardContext } from 'features/serverControllers'
import { getUserServerName } from 'features/users'
import { createDataSelectorHook, useObjectRef } from 'infra/redux'
import _ from 'lodash'
import { Label, LabelCheckbox, TextInput, Typography } from 'procyon-ui'
import React from 'react'
import { LabelContent } from 'V2Components'

const SummaryStep = () => {
  const {
    users,
    groups,
    groupsMap,
    sudoers,
    controllerName,
    selectedAccountKeys,
    selectorType,
    label,
    ctag,
    disableAnonymousLogin,
    disableOSLogin,
    updateConfig
  } = useServerControllerWizardContext()

  const { getObjectRef } = useObjectRef(['accountList'])

  const getUser = (userName) => {
    return _.find(users, { ObjectMeta: { Name: userName } })
  }

  const getSelectedAccounts = () => {
    const acc = []

    selectedAccountKeys.forEach((key) => {
      const ref = reverseRsrcKey(key)
      if (getObjectRef(ref)) acc.push(getObjectRef(ref))
    })

    return acc
  }

  const accounts = getSelectedAccounts()

  return (
    <div>
      <LabelContent
        fullWidth
        title='Controller Name'
        content={<Typography variant='body-regular'>{controllerName}</Typography>}
      />
      {selectorType === 'Accounts' && (
        <LabelContent
          title='Accounts'
          fullWidth
          content={
            <div className='flex gap-1 flex-wrap'>
              {accounts.map((acc) => {
                const Icon = getRsrcIcon(acc)
                return (
                  <Label
                    text={
                      <>
                        <Icon />
                        {getResourceName(acc)}
                      </>
                    }
                  />
                )
              })}
            </div>
          }
        />
      )}
      {selectorType === 'Labels' && (
        <LabelContent
          title='Labels'
          fullWidth
          content={
            <div className='flex gap-1 flex-wrap'>
              {label.map((l, i) => {
                if (i === 0) return null
                return <Label text={l} variant='grayBlue' />
              })}
            </div>
          }
        />
      )}
      {selectorType === 'CTags' && (
        <LabelContent
          title='CTags'
          fullWidth
          content={
            <div className='flex gap-1 flex-wrap'>
              <Typography variant='body-regular'>{ctag}</Typography>
            </div>
          }
        />
      )}
      <LabelContent
        fullWidth
        title='Users'
        content={
          <div className='flex gap-1 flex-wrap'>
            {users.map((u) => {
              const userName = getUserServerName(u)
              return (
                <Typography variant='body-regular'>
                  {getResourceName(u)} {userName && `(${userName})`},
                </Typography>
              )
            })}
          </div>
        }
      />
      <LabelContent
        fullWidth
        title='Sudoer Policies'
        content={
          <div className='flex w-[100%] gap-1 flex-wrap'>
            <TextInput readOnly value={sudoers} sx={{ width: '100%' }} multiline rows={5} />
          </div>
        }
      />
      <Typography className='mt-4' variant='h4-regular'>
        Groups
      </Typography>
      {groups.map((grpName) => {
        const users = groupsMap[grpName] || []
        return (
          <LabelContent
            fullWidth
            title={grpName}
            content={
              <div className='flex gap-1 flex-wrap'>
                {users.map((u) => {
                  const split = u.split('+')
                  const user = getUser(split[0])
                  return <Typography variant='body-regular'>{getResourceName(user)},</Typography>
                })}
              </div>
            }
          />
        )
      })}
      <LabelCheckbox
        checked={disableAnonymousLogin}
        name='Disable Anonymous Login'
        onClick={() => updateConfig({ disableAnonymousLogin: !disableAnonymousLogin })}
      />
      <LabelCheckbox
        checked={disableOSLogin}
        name='Disable Os Login'
        onClick={() => updateConfig({ disableOSLogin: !disableOSLogin })}
      />
    </div>
  )
}

export { SummaryStep }
