import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { useSearchQuery } from 'Core/Hooks/useSearchQuery'
import { useGCPCloud } from 'features/clouds'
import { createRsrcKey, getResourceName, getRsrcError } from 'features/resources'
import { CloudCard, TargetIcon, Typography } from 'procyon-ui'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useClouds } from '../../Clouds.utils'
import { ErrorModal } from 'features/policy'

function GCPAccounts({ searchKey }) {
  const [errorMessage, setErrorMessage] = useState({ message: '', url: '' })

  const history = useHistory()

  const { selectDispatch } = useMultiSlice(['accountList', 'projects', 'gcpResources'])

  const { accounts: accs, cloudType } = useClouds()
  const { getGCPAccountResources } = useGCPCloud()

  const { applySearchQuery } = useSearchQuery({
    queryKey: searchKey,
    defaultQueryFunction: (a, queryKey) => {
      const rName = getResourceName(a)
      return `${rName}`.toLowerCase().includes(queryKey)
    }
  })

  const accounts = applySearchQuery(accs)

  const renderAccounts = () => {
    return accounts.map((a) => {
      const rsrcsKeys = getGCPAccountResources(a)
      const namespace = a.ObjectMeta.Namespace

      const error = getRsrcError(a)
      const url = `/admin/clouds/${cloudType.toLowerCase()}/${encodeURIComponent(
        a.ObjectMeta.Name
      )}`

      return (
        <CloudCard
          topRightLabel={namespace}
          topRightLabelVariant={namespace === 'default' ? 'grayBlue' : 'gray'}
          key={createRsrcKey(a)}
          width='100%'
          menuItems={[]}
          icon={<TargetIcon height='100%' type={'GOOGLE_APPLICATION'} width='100%' />}
          name={getResourceName(a)}
          onClickMoreInfo={() => history.push(url)}
          onClickResources={function noRefCheck() {}}
          resourcesAmount={rsrcsKeys.length}
          showErrorButton={!!error}
          onErrorClick={() => setErrorMessage({ message: error, url })}
        />
      )
    })
  }

  useEffect(() => {
    selectDispatch(['gcpResources'])
  }, [])

  return (
    <div>
      <div className='flex justify-between'>
        <Typography variant='body-regular'>{accounts.length} Accounts</Typography>
      </div>
      <div className='grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 gap-8 mt-4'>
        {renderAccounts()}
      </div>
      {errorMessage.message && (
        <ErrorModal
          message={errorMessage.message}
          onClose={() => setErrorMessage({ message: '', url: '' })}
          showModal
          title='Account Error'
          onViewDetailsClick={() => history.push(errorMessage.url)}
        />
      )}
    </div>
  )
}

export { GCPAccounts }
