import { useCloudDetailsProvider } from 'features/clouds'
import React from 'react'
import { LabelContent } from 'V2Components'

const PrivateAccount = () => {
  const { cloud: account } = useCloudDetailsProvider()

  return (
    <div>
      <LabelContent title='Description' content={account.Spec.Description} />
      <LabelContent title='Config Link' content={account.Spec.Link} />
    </div>
  )
}

export { PrivateAccount }
