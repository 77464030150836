import { faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { createDataSelectorHook } from 'infra/redux'
import moment from 'moment'
import { DataTable, RiskIndicator, TargetIcon, Tooltip } from 'procyon-ui'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { ShimmerTableRow } from 'Utils/ShimmerEffect'
import _ from 'lodash'

const useSlices = createDataSelectorHook(['iamGroups', 'accountList'])
const GroupsTable = ({ searchQuery }) => {
  const { slices } = useSlices()
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(true)
  const [tableRows, setTableRows] = useState([])
  const [accountType, setAccountType] = useState('')
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 30,
    page: 0
  })

  const getAccountType = (id) => {
    const account = slices.accountList.find((account) => account.ObjectMeta.ID === id)
    return account?.Spec?.Type
  }

  useEffect(() => {
    if (!slices || slices.iamGroups.length === 0) {
      setIsLoading(true)
    }

    const getAccountObj = (accountRefID) => {
      const account = slices?.accountList?.find((account) => account.ObjectMeta.ID === accountRefID)
      return account?.Spec?.DisplayName || account?.Spec?.Description
    }

    if (slices?.iamGroups?.length > 0 && tableRows.length === 0) {
      const rows = slices.iamGroups
        .slice()
        .sort((a, b) => {
          const dateA = moment(a.ObjectMeta.CreatedAt)
          const dateB = moment(b.ObjectMeta.CreatedAt)
          return dateA.isBefore(dateB) ? 1 : -1
        })
        .map((obj, index) => ({
          id: index + 1,
          platform: getAccountType(obj.Spec.Account.RefID),
          name: obj.Spec.Name,
          createdate:
            obj.Spec.AwsGroupSpec.CreatedAt !== '' &&
            moment(obj.Spec.AwsGroupSpec.CreatedAt).format('MM-DD-YY HH:mm:ss'),
          lastused: '',
          account: getAccountObj(obj.Spec.Account.RefID),
          riskResources: obj.Spec.RiskScore,
          accountRef: obj.ObjectMeta.ID
        }))

      setTableRows(rows)
      setIsLoading(false)
    }
  }, [slices])

  const handleGraphRedirection = (accountRef) => {
    history.push(`/admin/idaanalyzer/idagraph/IamGroup/${encodeURIComponent(accountRef)}`)
  }

  const getRiskLevel = (riskValue) => {
    if (riskValue >= 0 && riskValue <= 3.9) {
      return 'low'
    } else if (riskValue >= 4 && riskValue <= 6.9) {
      return 'medium'
    } else if (riskValue >= 7 && riskValue <= 8.9) {
      return 'high'
    } else if (riskValue >= 9 && riskValue <= 10) {
      return 'critical'
    } else {
      return 'none'
    }
  }

  const filteredRows = tableRows.filter((row) => {
    const search = searchQuery?.toLowerCase()
    if (search) {
      return row.name.toLowerCase().includes(search)
    } else {
      return tableRows
    }
  })

  return (
    <div className='relative'>
      <DataTable
        autoHeight={true}
        pageSizeOptions={[5, 10, 25, 50, 100]}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        columns={[
          {
            field: 'name',
            headerName: 'Name',
            renderCell: (param) =>
              isLoading ? (
                <ShimmerTableRow />
              ) : (
                <>
                  <span className='mr-2'>
                    <FontAwesomeIcon icon={faUser} />
                  </span>
                  <p className='font-medium'>{param.value}</p>
                </>
              ),
            width: 300
          },
          {
            field: 'createdate',
            headerName: 'Creation Date',
            renderCell: (param) =>
              isLoading ? (
                <ShimmerTableRow />
              ) : (
                <Tooltip title='Date and Time Format Is MM-DD-YY HH-MM-SS'>
                  <p className='font-medium'>{param.value}</p>
                </Tooltip>
              ),
            width: 220
          },
          {
            field: 'account',
            headerName: 'Account',
            renderCell: (param) =>
              isLoading ? <ShimmerTableRow /> : <p className='font-medium'>{param.value}</p>,
            width: 220
          },
          {
            field: 'platform',
            headerName: 'Platforms',
            renderCell: (param) =>
              isLoading ? <ShimmerTableRow /> : <TargetIcon width='30px' type={param.value} />,
            width: 250
          },
          {
            field: 'riskResources',
            headerName: 'Risk Resources',
            renderCell: (params) =>
              isLoading ? (
                <ShimmerTableRow />
              ) : (
                <RiskIndicator variant={getRiskLevel(params.value)} />
              ),
            width: 259
          },
          {
            field: 'graph',
            headerName: 'Graph',
            renderCell: (param) =>
              isLoading ? (
                <ShimmerTableRow />
              ) : (
                <div className='bg-white shadow-md rounded-full w-[35px] h-[35px] flex items-center justify-center'>
                  <img
                    onClick={() => handleGraphRedirection(param.row.accountRef)}
                    src='img/icons/organization-chart.png'
                    alt=''
                    className='w-5 cursor-pointer'
                  />
                </div>
              ),
            width: 130
          }
        ]}
        rows={
          isLoading
            ? Array(1).fill({
                id: '',
                name: '',
                createdate: '',
                platforms: '',
                riskResources: ''
              })
            : filteredRows
        }
      />
    </div>
  )
}

export { GroupsTable }
