import { useUser } from 'Core/Hooks/useUser'
import { reduxApiClient } from 'infra'
import { createDataSelectorHook } from 'infra/redux'
import JSONbig from 'json-bigint'
import _ from 'lodash'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { ReactFlowGraph } from 'V2Components'
import {
  getNodeName,
  getNodeType,
  generateUniqueKey,
  getNodeKey,
  getNodeTypeName
} from 'features/IdentityAnalyzer/utils/helper'

const useSlices = createDataSelectorHook(['iamUsers'])

const IdaGraph = () => {
  const { accountRefID, kind } = useParams()
  const { slices } = useSlices()
  const { user } = useUser()
  const [responseGraphData, setResponseGraphData] = useState([])
  const [nodesMeta, setNodesMeta] = useState({})
  const [nodes, setNodes] = useState([])
  const [edges, setEdges] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const nodeSet = useRef(new Map())
  const accountRefIDMap = useRef(new Map())
  let userObj = _.find(slices.iamUsers, { ObjectMeta: { ID: accountRefID } })

  useEffect(() => {
    if (userObj) {
      userObj = _.find(slices.iamUsers, { ObjectMeta: { ID: accountRefID } })
    }
  }, accountRefID)

  useEffect(() => {
    if (accountRefID) {
      getGraphData()
    }
  }, [accountRefID])

  useEffect(() => {
    buildGraphData()
  }, [responseGraphData, nodesMeta])

  useEffect(() => {
    const errorHandler = (e) => {
      if (
        e.message.includes(
          // @ts-ignore
          'ResizeObserver loop completed with undelivered notifications' ||
            'ResizeObserver loop limit exceeded'
        )
      ) {
        const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay')
        if (resizeObserverErr) {
          resizeObserverErr.style.display = 'none'
        }
      }
    }
    window.addEventListener('error', errorHandler)
    return () => {
      window.removeEventListener('error', errorHandler)
    }
  }, [])

  const getGraphData = async () => {
    const payload = {
      ObjectMeta: {
        Tenant: user.tenant,
        Namespace: user.org,
        Name: userObj?.Spec?.Name
      },
      Spec: {
        Type: 'GET_ACCOUNT_GRAPH',
        IdentityGraphSpec: {
          RefID: accountRefID,
          RefKind: kind,
          Level: 4
        }
      }
    }
    try {
      const response = await reduxApiClient('analyze-identity').create(payload)
      const parsedData = JSONbig.parse(response?.Spec?.IdentityGraphSpec.Graph)
      setResponseGraphData(parsedData)

      const iamUserNode = parsedData.find(
        (item) => item.FromNode.RefKind === kind || item.ToNode.RefKind === kind
      )

      if (iamUserNode) {
        const nodeId = (
          iamUserNode.FromNode.RefKind === kind
            ? iamUserNode.FromNode.RefID
            : iamUserNode.ToNode.RefID
        ).toString()

        setNodesMeta((prevMeta) => ({
          ...prevMeta,
          [nodeId]: {
            ...prevMeta[nodeId],
            highlight: false
          }
        }))
      }
    } catch (error) {
      console.error('Error fetching graph data:', error)
    }
  }

  const getIamUserNextGraphData = async (refID) => {
    const payload = {
      ObjectMeta: {
        Tenant: user.tenant,
        Namespace: user.org,
        Name: userObj?.Spec?.Name
      },
      Spec: {
        Type: 'GET_IDENTITY_GRAPH',
        IdentityGraphSpec: {
          RefID: refID,
          RefKind: kind === 'IamFederatedUser' ? 'IamRole' : kind,
          Level: 4
        }
      }
    }

    try {
      const response = await reduxApiClient('analyze-identity').create(payload)
      return JSONbig.parse(response?.Spec?.IdentityGraphSpec?.Graph)
    } catch (error) {
      console.error('Error fetching IamUser graph data:', error)
      return null
    }
  }

  const getExpandableRsrc = async (accountRef, rsrcName, properties) => {
    const payload = {
      ObjectMeta: {
        Tenant: user.tenant,
        Namespace: user.org,
        Name: userObj?.Spec?.Name
      },
      Spec: {
        Type: 'GET_RESOURCES',
        ResourceSpec: {
          Account: {
            RefID: accountRef.Id,
            RefKind: 'Account'
          },
          limit: 50,
          offset: 0,
          ResourceType: rsrcName
        }
      }
    }

    try {
      const response = await reduxApiClient('analyze-identity').create(payload)
      const nodes = JSONbig.parse(response?.Spec?.ResourceSpec.Graph)
      return nodes
    } catch (error) {
      console.error('Error fetching resource data:', error)
      return null
    }
  }

  const fetchNextResourcesPath = async (id, accountRef, rsrcName, kind, properties) => {
    const resourceData = await getExpandableRsrc(accountRef, rsrcName, properties)
    if (resourceData) {
      const newEdges = resourceData.map((node) => ({
        FromNode: {
          RefID: id,
          RefKind: kind,
          Type: rsrcName,
          Name: nodeSet.current.get(id)?.label || `Node ${id}`
        },
        ToNode: {
          ...node,
          Type: rsrcName
        }
      }))

      setResponseGraphData((prevData) => [...prevData, ...newEdges])
      requestAnimationFrame(() => {
        setNodesMeta((prevMeta) => ({
          ...prevMeta,
          [id]: {
            ...prevMeta[id],
            isLoading: false,
            isExpanded: true,
            isCollapsed: false
          }
        }))
      })
    }
  }

  const fetchNextIamUserPath = async (iamUserId) => {
    const iamUserData = await getIamUserNextGraphData(iamUserId)
    if (iamUserData) {
      setResponseGraphData((prevData) => [...prevData, ...iamUserData])
      requestAnimationFrame(() => {
        setNodesMeta((prevMeta) => ({
          ...prevMeta,
          [getNodeKey(iamUserId, kind)]: {
            ...prevMeta[getNodeKey(iamUserId, kind)],
            isLoading: false,
            isExpanded: true,
            isCollapsed: false
          }
        }))
      })
    }
  }

  const handleExpandNodeCall = async (node) => {
    const { RefID, RefKind, Name, Properties } = node
    const nodeMeta = nodesMeta[getNodeKey(RefID, RefKind)] || {}
    const isExpanded = nodeMeta.isExpanded || false

    let newMeta = {
      ...nodeMeta,
      isLoading: true
    }

    setNodesMeta((prevMeta) => ({
      ...prevMeta,
      [getNodeKey(RefID, RefKind)]: newMeta
    }))

    try {
      if (isExpanded) {
        newMeta = {
          ...nodeMeta,
          isCollapsed: true,
          isExpanded: false,
          isLoading: false
        }
        setNodesMeta((prevMeta) => ({
          ...prevMeta,
          [getNodeKey(RefID, RefKind)]: newMeta
        }))
      } else {
        if (
          RefKind === 'IamUser' ||
          RefKind === 'IamGroup' ||
          RefKind === 'IamRole' ||
          RefKind === 'IamFederatedUser'
        ) {
          await fetchNextIamUserPath(RefID.toString())
        } else if (RefKind === 'ExpandableNode') {
          const nodeData = nodeSet.current.get(getNodeKey(RefID, RefKind))
          const accountRef = nodeData?.accountRef
          await fetchNextResourcesPath(RefID, accountRef, Name, RefKind, Properties)
        }

        newMeta = {
          ...nodeMeta,
          isLoading: false,
          isExpanded: true,
          isCollapsed: false
        }

        setNodesMeta((prevMeta) => ({
          ...prevMeta,
          [getNodeKey(RefID, kind)]: newMeta
        }))
      }
    } catch (error) {
      console.error('Error during expand/collapse:', error)
    }
  }

  const getIsCollapsibleState = (refkind) => {
    return (
      refkind === 'IamUser' ||
      refkind === 'ExpandableNode' ||
      (refkind === 'IamGroup' && kind === 'IamGroup') ||
      (refkind === 'IamRole' && kind === 'IamRole') ||
      (refkind === 'IamRole' && kind === 'IamFederatedUser')
    )
  }

  const buildGraphData = () => {
    setIsLoading(true)
    const graphData = []
    const arrows = []
    nodeSet.current = new Map()
    accountRefIDMap.current = new Map()

    const nodeLayers = {
      DummyUser: [],
      DummyCloud: [],
      Account: [],
      IamUser: [],
      IamGroup: [],
      IamFederatedUser: [],
      IamRole: [],
      IamServiceAccount: [],
      IamAction: [],
      IamPolicy: [],
      AccessKey: [],
      Resources: [],
      Organization: [],
      'Folder|0': [],
      'Folder|1': [],
      'Folder|2': [],
      'Folder|3': [],
      'Folder|4': [],
      'Folder|5': [],
      'Folder|6': [],
      Project: [],
      'Compute Instance': [],
      ExpandableNode: [],
      EmptyNode: [],
      GcpResource: [],
      AwsResource: []
    }

    const addToLayer = (refKind, nodeData) => {
      nodeLayers[refKind]?.push(nodeData)
    }

    if (kind === 'IamUser' && !nodeSet.current.has(getNodeKey('dummy-user1', kind))) {
      const dummyUserNode = {
        id: getNodeKey('dummy-user1', kind),
        label: userObj?.Spec?.Name?.toString() || userObj?.Spec?.UserName?.toString(),
        key: generateUniqueKey('dummy-user1', kind, '1', '1'),
        type: 'USER',
        collapsible: false,
        description: '',
        data: { meta: nodesMeta[getNodeKey('dummy-user1', kind)] || {} }
      }

      addToLayer('DummyUser', dummyUserNode)
      nodeSet.current.set(getNodeKey('dummy-user1', kind), dummyUserNode)
    }

    const cloudAccounts = responseGraphData.filter(
      (item) =>
        item.FromNode.RefKind === 'Account' &&
        (item.FromNode.Properties?.ACCOUNT_TYPE === 'AWS' ||
          item.FromNode.Properties?.ACCOUNT_TYPE === 'GCP')
    )

    // Group accounts by type
    const groupedAccounts = cloudAccounts.reduce((acc, item) => {
      const accountType = item.FromNode.Properties?.ACCOUNT_TYPE
      if (!acc[accountType]) acc[accountType] = []
      acc[accountType].push(item)
      return acc
    }, {})

    // Process each account type
    Object.keys(groupedAccounts).forEach((accountType) => {
      const accounts = groupedAccounts[accountType]
      if (accounts.length === 1) {
        // Single account, connect directly to dummy-user node
        const { FromNode, ToNode } = accounts[0]
        const fromNodeKey = getNodeKey(FromNode.RefID, FromNode.RefKind)

        if (!nodeSet.current.has(fromNodeKey)) {
          const fromNodeData = {
            id: fromNodeKey,
            key: generateUniqueKey(FromNode.RefID, FromNode.RefKind, ToNode.RefID, ToNode.RefKind),
            label: 'Account',
            type: accountType,
            collapsible: getIsCollapsibleState(FromNode.RefKind),
            description: FromNode.Name?.toString(),
            data: { meta: nodesMeta[fromNodeKey] || {} }
          }

          addToLayer(FromNode.RefKind, fromNodeData)
          nodeSet.current.set(fromNodeKey, fromNodeData)
          arrows.push({
            startID: getNodeKey('dummy-user1', kind),
            endID: fromNodeKey
          })
        }
      } else if (accounts.length > 1) {
        // Multiple accounts, create a dummy node
        const dummyNodeKey = getNodeKey(`dummy-${accountType.toLowerCase()}`, accountType)

        if (!nodeSet.current.has(dummyNodeKey)) {
          const dummyNode = {
            id: dummyNodeKey,
            label: '',
            type: accountType,
            key: generateUniqueKey(`dummy-${accountType.toLowerCase()}`, accountType, '2', '2'),
            collapsible: false,
            description: `${accountType} accounts`,
            data: {
              meta: nodesMeta[dummyNodeKey] || {}
            }
          }
          addToLayer('DummyCloud', dummyNode)
          nodeSet.current.set(dummyNodeKey, dummyNode)
          arrows.push({
            startID: getNodeKey('dummy-user1', kind),
            endID: dummyNodeKey
          })
        }

        // Connect individual accounts to the dummy node
        accounts.forEach((item) => {
          const { FromNode, ToNode } = item
          const fromNodeKey = getNodeKey(FromNode.RefID, FromNode.RefKind)

          if (!nodeSet.current.has(fromNodeKey)) {
            const fromNodeData = {
              id: fromNodeKey,
              key: generateUniqueKey(
                FromNode.RefID,
                FromNode.RefKind,
                ToNode.RefID,
                ToNode.RefKind
              ),
              label: 'Account',
              type: accountType,
              collapsible: getIsCollapsibleState(FromNode.RefKind),
              description: FromNode.Name?.toString() || `Description for ${FromNode.RefID}`,
              data: { meta: nodesMeta[fromNodeKey] || {} }
            }

            addToLayer(FromNode.RefKind, fromNodeData)
            nodeSet.current.set(fromNodeKey, fromNodeData)
            arrows.push({
              startID: dummyNodeKey,
              endID: fromNodeKey
            })
          }
        })
      }
    })

    responseGraphData.forEach((item) => {
      const { FromNode, ToNode } = item
      const fromNodeKey = getNodeKey(FromNode.RefID, FromNode.RefKind)
      const toNodeKey = getNodeKey(ToNode.RefID, ToNode.RefKind)

      // Get or set AccountRefID for the parent node
      let fromNodeAwsAccountRefID = accountRefIDMap.current.get(JSONbig.stringify(FromNode.RefID))
      if (!fromNodeAwsAccountRefID && FromNode.RefKind === 'Account') {
        fromNodeAwsAccountRefID = {
          Id: JSONbig.stringify(FromNode.RefID),
          Type: FromNode?.Properties?.ACCOUNT_TYPE
        }
        accountRefIDMap.current.set(JSONbig.stringify(FromNode.RefID), fromNodeAwsAccountRefID)
      }

      // Set the same AccountRefID for the child node
      let toNodeAwsAccountRefID = accountRefIDMap.current.get(JSONbig.stringify(ToNode.RefID))
      if (!toNodeAwsAccountRefID) {
        toNodeAwsAccountRefID = fromNodeAwsAccountRefID // Inherit from parent
        accountRefIDMap.current.set(JSONbig.stringify(ToNode.RefID), toNodeAwsAccountRefID)
      }

      // From node creation
      if (!nodeSet.current.has(fromNodeKey)) {
        const fromNodeData = {
          id: fromNodeKey,
          key: generateUniqueKey(FromNode.RefID, FromNode.RefKind, ToNode.RefID, ToNode.RefKind),
          label: getNodeTypeName(FromNode, kind),
          type: getNodeType(FromNode, FromNode?.Properties?.ACCOUNT_TYPE),
          collapsible: getIsCollapsibleState(FromNode.RefKind),
          accountRef: fromNodeAwsAccountRefID,
          onExpand: getIsCollapsibleState(FromNode.RefKind)
            ? () => handleExpandNodeCall(FromNode)
            : undefined,
          description: getNodeName(FromNode),
          data: { meta: nodesMeta[getNodeKey(FromNode.RefID, FromNode.RefKind)] || {} }
        }

        // handle GCP resource project node creation
        if (FromNode.RefKind === 'GcpResource' && FromNode.Properties.Label) {
          addToLayer(FromNode.Properties.Label, fromNodeData)
        } else {
          addToLayer(FromNode.RefKind, fromNodeData)
        }
        nodeSet.current.set(fromNodeKey, fromNodeData)
      }

      // To node creation
      if (!nodeSet.current.has(toNodeKey)) {
        const toNodeData = {
          id: toNodeKey,
          key: generateUniqueKey(ToNode.RefID, ToNode.RefKind, FromNode.RefID, FromNode.RefKind),
          label: getNodeTypeName(ToNode, kind),
          type: getNodeType(ToNode, ToNode?.Properties?.ACCOUNT_TYPE),
          collapsible: getIsCollapsibleState(ToNode.RefKind),
          accountRef: toNodeAwsAccountRefID,
          description: getNodeName(ToNode),
          onExpand: getIsCollapsibleState(ToNode.RefKind)
            ? () => handleExpandNodeCall(ToNode)
            : undefined,
          data: { meta: nodesMeta[toNodeKey] || {} }
        }

        // handle GCP resource project node creation
        if (ToNode?.RefKind === 'GcpResource' && ToNode?.Properties?.Label) {
          addToLayer(ToNode?.Properties?.Label, toNodeData)
        } else {
          addToLayer(ToNode.RefKind, toNodeData)
        }
        nodeSet.current.set(toNodeKey, toNodeData)
      }
      arrows.push({ startID: fromNodeKey, endID: toNodeKey })
    })

    graphData.push(...Object.values(nodeLayers))
    const cleanGraphData = graphData.filter((node) => node.length > 0)
    requestAnimationFrame(() => {
      if (cleanGraphData.length > 0) {
        setNodes(cleanGraphData)
      }
      if (arrows.length > 0) {
        setEdges(arrows)
      }
    })
    setIsLoading(false)
  }

  const graphComponents = useMemo(
    () => (
      <ReactFlowGraph
        nodes={nodes.map((layer) =>
          layer.map((node) => ({
            ...node,
            data: {
              ...node.data,
              meta: nodesMeta[node.id] || {}
            }
          }))
        )}
        edges={edges}
        onNodeExpandClick={(node) => {
          node.onExpand && node.onExpand()
        }}
        nodesMeta={nodesMeta}
      />
    ),
    [nodes, edges, nodesMeta, handleExpandNodeCall]
  )

  return <div>{!isLoading ? graphComponents : 'Loading....'}</div>
}

export { IdaGraph }
